<style scoped>
    
    i~div:first-of-type>img{
        box-shadow: 0px 3px 2px 0px #000;
    }
    .btn-outline-red {
        color: #fb2401 !important;
        border-color: #fb2401 !important;
    }
    .btn-check:checked+.btn-outline-red,.btn-outline-red:hover {
        color: #fff !important;
        background-color: #fb2401;
        border-color: #fb2401;
    }
    .btn-outline-blue {
        color: #065aa4 !important;
        border-color: #065aa4 !important;
    }
    .btn-check:checked+.btn-outline-blue,.btn-outline-blue:hover {
        color: #fff !important;
        background-color: #065aa4;
        border-color: #065aa4;
    }
    .coruscate{
        animation: coruscate .5s ease infinite alternate;
    }
	@keyframes coruscate {
        0% {
            background: transparent;
        }
        100% {
            background: rgba(13,202,240);
        }
    }
    @media (min-width:576px) {
        .tel-video-h{
            /* 26.917rem */
            height: 23.211rem;
        }
    }
    @media (min-width:768px) {
        .tel-video-h{
            height: 25.523rem;
        }
    }
    @media (min-width: 992px){
        .tel-video-h{
            height: 27.072rem;
        }
    }
    @media (min-width: 1200px){
        .tel-video-h{
            height: 27.194rem;
        }
    }
    @media (min-width: 1400px){
        .tel-video-h{
            height: 28.656rem;
        }
    }
</style>
<template>
    <div :class="{'position-sm-absolute start-0 end-0 top-0':gameModel==GAME_MODEL.normal}" class="d-flex flex-column h-100 position-xs-relative">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column d-sm-none" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <!-- 视频区 -->
        <div :class="{'flex-grow-0 flex-sm-grow-1 h-sm-0':gameModel==GAME_MODEL.normal}" class="d-flex flex-column flex-sm-row position-relative">
            <!-- 纵向导航 -->
            <navbar-vertical class="d-none d-sm-flex" :is-fullscreen="isFullscreen"></navbar-vertical>
            <!-- 电话模式视频 -->
            <div class="d-flex flex-column flex-sm-row flex-grow-1 position-relative tel-video-h" v-if="gameModel==GAME_MODEL.tel">
                <media-video class="border-end border-secondary" :video-index="0" id="firstvideo" :video-list="videoList" :countdown="roomInfo.countdown" :room-class="(roomInfo.isar==1&&gameModel==GAME_MODEL.normal)?['top-xs-0 top-sm-5rem mt-2 mt-sm-1 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(roomInfo.isar==1&&gameModel==GAME_MODEL.normal)?['top-xs-0','top-sm-5rem top-xl-6rem','ms-2']:['top-0','m-2']" :img-class="roomInfo.isar==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                <media-video class="border-start border-secondary" :video-index="1" id="secondvideo" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="roomInfo.isar==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
            </div>
            <!-- 视频区 -->
            <div v-else class="d-flex flex-column flex-grow-1 position-relative">
                <!-- 公告 -->
                <notice></notice>
                <!-- 视频 -->
                <div class="d-flex h-0 pt-60 position-relative flex-grow-1 pt-sm-0">
                    <media-video class="position-xs-absolute top-0 start-0 position-sm-relative" :class="{'scale-video':isScale}" :video-list="videoList" :countdown="roomInfo.countdown" :room-class="roomInfo.isar==1?['top-sm-0 mt-2 mt-sm-5 ms-1 ms-sm-2']:['m-2']" :time-class="roomInfo.isar==1?['top-0 mt-2 mt-sm-5 ms-2']:['top-0','m-2']" :img-class="roomInfo.isar==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                </div>
                <!-- 提交区 -->
                <div class="position-xs-relative position-sm-absolute start-0 end-0 bottom-0 top-0 bg-xs-success">
                    <!-- 操作区 -->
                    <div v-if="gameModel==GAME_MODEL.normal" class="virtual-container perspective-600 position-xs-relative position-sm-absolute start-50 bottom-0 bg-xs-success index-1" :class="{'scale-submit':isScale==true}">
                        <!-- 牌区 -->
                        <!-- <div v-if="showPokerArr.length>0" class="d-sm-none position-absolute w-100 h-100 top-0 d-flex flex-column index-1054 justify-content-center bg-dark bg-opacity-75">
                            <div v-for="(item,i) in showPokerArr" :key="(item,i)" class="d-flex rounded" style="height: 5.5rem;">
                                <div class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{i==0?$t("common.z"):$t("common.x")}}{{i>0?i:''}}</div>
                                <div class="d-flex align-items-center justify-content-center border rounded-3 px-1" v-for="val in item" :key="val" :style="{'background':i==0?color.red:color.blue}">
                                    <div v-if="val" :class="[$root.getPokerByNum(val)['color']]" class="d-flex bg-light rounded p-1 flex-column">
                                        <span class="fw-bold lh-1 fs-5">{{ $root.getPokerByNum(val)["name"] }}</span>
                                        <div class="d-flex flex-grow-1 px-3 pt-2 pb-3">
                                            <i :class="[$root.getPokerByNum(val)['shape']]" class="bi d-flex align-items-center justify-content-center fs-1 flex-grow-1"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showPokerTxtArr[i]" class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{showPokerTxtArr[i]}}</div>
                            </div>
                        </div> -->
                        <div class="rotate3d-x-sm-50 mb-sm-1 p-1 p-sm-0">
                            <!-- 切换区 -->
                            <div class="d-flex justify-content-between justify-content-sm-end justify-content-lg-between mb-1 mb-sm-2">
                                <div class="ms-sm-5 ps-sm-5 d-flex text-light lh-1 fs-sm-5 align-items-center align-items-sm-end d-sm-none d-lg-flex text-nowrap">
                                    <span>{{$t("room.ingBet")}}:{{getAmountCount("submiting")}}</span>
                                    <span class="ms-2">{{$t("room.edBet")}}:{{getAmountCount("submited")}}</span>
                                    <span class="ms-2">{{$t("room.allBet")}}:{{submitedCount+getAmountCount("submiting")}}</span>
                                    <!-- <span class="ms-4">预扣：100</span> -->
                                </div>
                                <div class="d-flex">
                                    <button type="button" class="btn btn-info me-2 text-nowrap" data-bs-toggle="modal" data-bs-target="#oddsModal" @click="isShowTip=true">{{$t("room.oddsExplain")}}<i class="bi bi-question-circle ms-1"></i></button>
                                    <!-- <div class="d-flex justify-content-center align-items-center justify-content-sm-end me-2 text-light" @mouseenter="isShowTip=true" @mouseleave="isShowTip=false" role="button">
                                        赔率说明<i class="bi bi-question-circle ms-1"></i>
                                    </div> -->
                                    <div class="btn-group btn-shadow-right" role="group">
                                        <input type="radio" class="btn-check" name="btnradio" @change="changeModel(0,$event)" value="0" v-model="submitModel" id="submitModelZ" >
                                        <label class="btn btn-outline-red fw-bold" for="submitModelZ">{{$t("common.z")}}</label>
                                        <input type="radio" class="btn-check" name="btnradio" @change="changeModel(1,$event)" value="1" v-model="submitModel" id="submitModelX" >
                                        <label class="btn btn-outline-blue fw-bold" for="submitModelX">{{$t("common.x")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row">
                                <!-- 选筹区 -->
                                <div class="d-flex justify-content-between justify-content-sm-around align-items-center text-black order-2 order-sm-1 mb-2 mb-sm-0 flex-grow-1" :class="{'flex-sm-column':roomInfo.isar==1,'col':roomInfo.isar!=1}">
                                    <div v-for="(item,i) in currAmountList" :key="i" class="d-flex justify-content-around align-items-center col" :class="{'col-sm-auto justify-content-sm-start':roomInfo.isar==1}">
                                        <div class="position-relative me-1 chips rounded-circle" :class="{active:amount==item[0]}" @click="amount=item[0]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+item[0]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(item[0])}}</span>
                                        </div>
                                        <button v-if="i==2" @click.stop="isShowMoreAmount=!isShowMoreAmount" class="btn btn-secondary btn-shadow-left text-light">
                                            <i class="bi bi-grid-fill"></i>
                                        </button>
                                        <div v-else class="position-relative me-1 chips rounded-circle" :class="{active:amount==item[1]}" @click="amount=item[1]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+item[1]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(item[1])}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex flex-sm-column justify-content-between justify-content-sm-around align-items-center text-black order-2 order-sm-1 mb-2 mb-sm-0">
                                    <div class="d-flex justify-content-around justify-content-sm-start col col-sm-auto" v-for="i in 3" :key="i">
                                        <div class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-2]}" @click="amount=currAmountListSort[2*i-2]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-2]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-2])}}</span>
                                        </div>
                                        <button v-if="i==3" @click.stop="isShowMoreAmount=!isShowMoreAmount" class="btn btn-secondary btn-shadow-left text-light">
                                            <i class="bi bi-grid-fill"></i>
                                        </button>
                                        <div v-else class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-1]}" @click="amount=currAmountListSort[2*i-1]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-1]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-1])}}</span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- 提交区 -->
                                <div class="d-flex flex-sm-row fw-bold text-light fs-sm-4 rounded-10 border-solid border-xs-3 border-sm-4 me-sm-1 mb-2 mb-sm-0 position-relative order-1 order-sm-2" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce',background:(roomInfo.isar==1&&roomInfo.rid==73)?'#067360':''}">
                                    <div v-for="i of 3" :key="i" class="flex-fill d-flex flex-column" :class="{'flex-fill d-flex flex-column border-solid border-4 border-top-0 border-bottom-0 border-start-0 border-end-xs-0':i==1,'border-solid border-xs-2 border-sm-4 border-top-0 border-bottom-0 border-start-sm-0 border-top-xs border-end-xs-0 border-start-xs-0':i==2}" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce'}">

                                        <div class="border-xs-3 border-sm-3 border-solid border-top-0 border-start-0 border-end-0 text-center px-2 px-sm-1 px-md-2 text-truncate fs-6" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce'}">
                                            <span>{{ submitModel==0?$t("common.z"):$t("common.x") }} {{ i }}</span>
                                        </div>

                                        <div class="flex-grow-1 d-flex flex-column flex-sm-row text-center position-relative" role="button">
                                            <div v-for="(item,idx) in [[$t('room.singleCount'),'p'],[$t('room.doubleCount'),'f'],[$t('room.multiplesCount'),'d']]" :key="idx" class="d-flex flex-column justify-content-sm-center flex-fill py-4 p-sm-3 p-md-3 p-lg-4 position-relative" :class="[i==3?'border-end-xs-0':'border-end-xs-style-solid',idx==2?'border-xs-2 border-sm-0 border-end-style-dashed':'border-2 border-bottom-xs-2 border-bottom-xs-style-dashed border-end-style-dashed',(coruscateArr[i-1]==1&&submitModel==1)||(coruscateArr[i-1]==0&&submitModel==0)?'coruscate':'']" @click="addAmount(item[1],i-1)" :style="{'border-color':submitModel==0?'#ca5a47 !important':'#78a5ce !important'}">
                                                <label class="text-sm-vertical small mx-md-1 mx-lg-0 mx-xl-1" :class="[idx==1?'text-warning':idx==2?'text-danger':'']">{{ item[0] }}</label>
                                                <div v-for="j,index in chipsConvert(submited[item[1]][i-1]+submiting[item[1]][i-1])" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black">
                                                    <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                    <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                </div>
                                                <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                    <span class="small">
                                                        <small>{{submited[item[1]][i-1]+submiting[item[1]][i-1]>0?submited[item[1]][i-1]+submiting[item[1]][i-1]:''}}</small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 更多筹区 -->
                                    <change-amount class="rounded-sm-10" :is-show="isShowMoreAmount"></change-amount>
                                </div>
                                <!-- 按钮区 -->
                                <div class="d-flex flex-sm-column justify-content-around text-nowrap order-3 mb-1 mb-sm-0">
                                    <button class="btn btn-warning bg-gradient text-light btn-shadow-right mb-sm-1" :class="{'disabled':!canClear}" :disabled="!canClear" @click="submitEvent('clear')">{{$t("room.clear")}}</button>
                                    <button class="btn btn-danger bg-gradient btn-shadow-right mb-sm-1" :class="{'disabled':!canCancel}" :disabled="!canCancel" @click="submitEvent('cancel')">{{$t("room.cancel")}}</button>
                                    <button class="btn btn-success bg-gradient btn-shadow-right" :class="{'disabled':!canConfirm}" :disabled="!canConfirm" @click="submitEvent('confirm')">{{$t("room.confirm")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 牌区 -->
                    <div v-if="showPokerArr.length>0" class="d-flex position-absolute top-xs-0 top-sm-26 d-flex flex-column ms-sm-2 index-11 w-xs-100 h-xs-100 align-items-center align-items-sm-start justify-content-center justify-content-sm-start bg-xs-dark bg-opacity-75">
                        <div class="d-flex flex-column">
                            <div v-for="(item,i) in showPokerArr" :key="(item,i)" class="d-flex justify-content-between rounded h-4_5rem">
                                <div class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?COLOR.red:COLOR.blue}">{{i==0?$t("common.z"):$t("common.x")}}{{i>0?i:''}}</div>
                                <div class="img-thumbnail" v-for="(val,j) in item" :key="val" :style="{'background':i==0?COLOR.red:COLOR.blue}">
                                    <div :class="[$root.getPokerByNum(val)['color'],((i+1)==currPokerXY[0]&&(j+1)==currPokerXY[1])?'rotate':'',val?'show':'',isShowPoker?'animation-duration-0':'',i==0?'danger':'']" class="d-flex rounded overflow-hidden h-100 poker-bg">
                                        <div class="d-flex flex-column bg-light p-1">
                                            <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(val)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 px-2 pb-2">
                                                <i :class="[$root.getPokerByNum(val)['shape']]" class="bi d-flex align-items-center justify-content-center fs-4 flex-grow-1"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showPokerTxtArr[i]" class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?COLOR.red:COLOR.blue}">{{showPokerTxtArr[i]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 池额 -->
                <div class="position-absolute top-0 end-0 d-flex flex-column bg-dark text-light bg-opacity-50 px-3 py-1 rounded me-2 mt-2 mt-sm-5 fw-bold">
                    <div class="text-center border-bottom">{{$t("video.pool")}}</div>
                    <div class="d-flex text-end small justify-content-between">
                        <div class="d-flex flex-column justify-content-end ms-2">
                            <i class="bi bi-1-circle"></i>
                            <i class="bi bi-2-circle"></i>
                            <i class="bi bi-3-circle"></i>
                        </div>
                        <div class="d-flex flex-column mx-3" :style="'color:'+COLOR.red">
                            <span>{{$t("common.z")}}</span>
                            <span>{{pool.z[0]}}</span>
                            <span>{{pool.z[1]}}</span>
                            <span>{{pool.z[2]}}</span>
                        </div>
                        <div class="d-flex flex-column me-2" :style="'color:'+COLOR.blue">
                            <span>{{$t("common.x")}}</span>
                            <span>{{pool.x[0]}}</span>
                            <span>{{pool.x[1]}}</span>
                            <span>{{pool.x[2]}}</span>
                        </div>
                    </div>
                    <div v-if="gameModel==GAME_MODEL.normal" class="border-top d-none d-sm-flex d-lg-none justify-content-between">
                        <div class="d-flex flex-column mx-2">
                            <span>{{$t("room.ingBet")}}</span>
                            <span>{{$t("room.edBet")}}</span>
                            <span>{{$t("room.allBet")}}</span>
                        </div>
                        <div class="d-flex flex-column me-2 text-end">
                            <span>{{getAmountCount("submiting")}}</span>
                            <span>{{getAmountCount("submited")}}</span>
                            <span>{{submitedCount+getAmountCount("submiting")}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- 路单区 -->
        <div class="flex-grow-1 flex-sm-grow-0 h-sm-8rem position-relative">
            <canvas id="pearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="4" width="0" height="0" @mousemove.stop="showPoker($event,1)"></canvas>
            <div class="bg-dark bg-opacity-25 position-absolute" :style="pearlLayerStyle" @mouseout.stop="showPoker($event,0)"></div>
        </div>
        <!-- 换台区 -->
        <change-room :class="{'me-sm-0':roomInfo.isar==1}" :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj"></change-room>
        <!-- 赔率说明模态框 -->
        <div class="modal fade" id="oddsModal">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content bg-cyan">

                    <!-- 模态框头部 -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("room.oddsExplain")}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-striped table-borderless table-hover mb-0">
                                <thead class="text-light bg-primary bg-opacity-50">
                                    <tr>
                                        <th>{{$t("room.oddsName")}}</th>
                                        <th>{{$t("room.oddsWin")}}</th>
                                        <th>{{$t("room.oddsLose")}}</th>
                                    </tr>
                                </thead>
                                <tbody class=" bg-primary bg-opacity-25">
                                    <tr>
                                        <td class="text-light fw-bold">{{ $t('room.singleCount') }}</td>
                                        <td class="text-light">1：{{odds.p}}</td>
                                        <td class="text-light">1：{{Math.ceil(odds.p)}}</td>
                                    </tr>
                                    <tr v-for="item,i of odds.f" :key="i" class="text-nowrap">
                                        <td class="text-light fw-bold">{{ $t('room.doubleCount') }}{{oddsFName[i]}}</td>
                                        <td class="text-light">1：{{ item }}</td>
                                        <td class="text-light">1：{{ Math.ceil(item) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-light fw-bold">{{ $t('room.multiplesCount') }}</td>
                                        <td class="text-light" colspan="2">{{ $t("room.multiplesExplain") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>

                    <!-- 模态框底部 -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">{{$t("common.close")}}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {COMMAND,GAME_ROW,COLOR,LAYER_TYPE,GAME_ID,RESULT,SUBMIT_RESULT,CANCEL_RESULT,RATIO,AMOUTN_ARR,MUTATION_TYPE,GAME_CODE,GAME_MODEL} from "@/assets/js/config.js"
import mediaVideo from "@/components/public/video.vue";
import navbarVertical from '@/components/public/navbarVertical.vue';
import navbar from '@/components/public/navbar.vue';
import notice from '@/components/public/notice.vue';
import changeRoom from '@/components/public/changeRoom.vue'
import changeAmount from '@/components/public/changeAmount.vue'
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,formatDate } from '@/assets/js/common.js'
export default {
    components:{
        mediaVideo,
        changeRoom,
        navbarVertical,
        navbar,
        notice,
        changeAmount
    },
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                //设置画布大小
                _this.$root.setCanvasSize(elem);
                //绘制画布表格
                // _this.canvasObj = _this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(_this.roomInfo.game))!=-1),true,1.5);
                let canvasObj = _this.$root.drawCanvasTable(elem,binding.value,true,1.5);
                _this.$store.commit({
                    type:MUTATION_TYPE.setTableColumn,
                    tableColumn:canvasObj.column
                })
                //请求数据
                canvasObj&&_this.$root.getRoomPearl(_this.roomInfo.game,_this.roomInfo.id);
                //获取台面扑克
                canvasObj&&_this.$emit("ws-send",COMMAND.cor_getroompoker, _this.roomInfo.id,_this.roomInfo.boot,canvasObj.column,_this.userInfo.id, _this.userInfo.guid);
            }
        },
    },
    props:{
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            GAME_MODEL,
            COLOR,//获取颜色配置
            videoList:[],//视频线路地址，当前支持最多两个
            pearlArr:[],//台面路单列表
            pool:{z:[0,0,0],x:[0,0,0]},//池
            limitred:{p:[0,0],d:[0,0],f:[0,0]},//限红
            countList:[],//路单统计列表
            pearlObj: {
                id:0,//台面id
                pearl:[]//路单数据
            },//要重绘路单的对象
            submitModel:1,//提交模式(0:z 1:x)
            currAmountListSort:[10,100,1000,10000,100000],//排序后的选中额度列表
            amount:10,//选中额度
            submited:{
                p:[0,0,0],//平
                f:[0,0,0],//翻
                d:[0,0,0],//多
            },//已提交的额度列表
            submiting:{
                p:[0,0,0],//平
                f:[0,0,0],//翻
                d:[0,0,0],//多
            },//将要提交的额度列表
            submitedCount:0,//全部模式已提交的总额
            canClear:false,//是否可以点击清除
            canCancel:false,//是否可以点击取消
            canConfirm:false,//是否可以点击确定
            coruscateArr:[],//x闪烁位集合，z相反，存放将要闪烁的位置，值为1时闪
            pokerArr:[],//当前台面牌组
            showPokerArr:[],//要显示的牌组
            showPokerTxtArr:[],//要显示的牌组文本
            currPokerXY:[0,0],//当前要翻转的扑克
            isShowMoreAmount:false,//是否显示更多筹码
            pearlLayerStyle:{
                width:0,
                height:0,
                left:0,
                top:0,
                display:'none'
            },//鼠标移上路单的遮罩层
            point:[0,0],//鼠标在路单上的坐标点
            isScale:false,//是否放大提交区和视频区
            isShowPoker:true,//鼠标移入画布是否显示牌
            showOdds:"",//要显示的赔率
            odds:{p:"0",f:[0,0,0,0,0]},//赔率
            isShowTip:false,//是否显示赔率说明
            oddsFName:{
                6:this.$t("room.cardNormal"),
                7:"("+this.$t("app.great")+this.$t("common.seven")+")",
                8:"("+this.$t("app.great")+this.$t("common.eight")+")",
                9:"("+this.$t("app.great")+this.$t("common.nine")+")",
                10:"("+this.$t("room.resultGreat")+")",
                "silver":"("+this.$t("room.resultSilver")+")",
                "gold":"("+this.$t("room.resultGold")+")",
                "boom":"("+this.$t("room.resultBomb")+")"
            },//赔率翻名称
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        tabGame(){
            return this.$store.state.tabGame;
        },
        currVideoLine(){
			return this.$store.state.currVideoLine;
		},
        audioSwitch(){
            return this.$store.state.audioSwitch;
        },
        gameModel(){
            return this.$store.state.gameModel;
        },
        roomList(){
            return this.$store.state.roomList;
        },
        currAmountList(){
            return this.$store.state.currAmountList;
        },
        tableColumnList(){
            return this.$store.state.tableColumnList;
        },
        tableColumn(){
            return this.$store.state.tableColumn;
        },
    },
    mounted() {
        this.init();
        this.setWsMessage();
        addEventListener("resize",this.setResize);
    },
    unmounted() {
        removeEventListener("resize",this.setResize);
        delete this.$root.wsMsgObj["cor"];
    },
    methods: {
        init(){
            //获取台面视频
            if(this.currVideoLine==""){
                useLayer({str:this.$t("video.noVideoLine")});
            }else{
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.id,this.userInfo.guid);
            }
            //获取赔率
            this.$emit("ws-send",COMMAND.cor_getodds,this.roomInfo.id,this.userInfo.id,this.userInfo.guid);
            //获取限红
            this.$emit("ws-send",COMMAND.cor_getlimitred,this.userInfo.id, this.userInfo.guid);
            //获取各位置已提交额度
            this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
        },
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$root.wsMsgObj["cor"]=(data)=>{
                switch (data.command) {
                    case COMMAND.all_getroompearl:
                        if(data.id==this.roomInfo.id){//获取的是当前台面的数据
                            this.pearlArr=data.pearl;
                        }
                        if(this.roomList.find(v=>v.id==data.id)){//用于切换台面功能获取数据
                            this.pearlObj={
                                id:data.id,
                                pearl:data.pearl
                            }
                            this.countList[data.id]=data.count;
                        }
                        break;
                    case COMMAND.all_getvideo:
                        let videoArr=data.video;
                        let list=[];//拼装好的视频地址数组
                        for (let i = 0; i < videoArr.length; i++) {
                            list.push(data.flvline+'/'+videoArr[i]+'.flv');
                        }
                        this.videoList=list;
                        break;
                    case COMMAND.cor_getodds:
                        this.odds=data.odds;
                        break;
                    case COMMAND.cor_getlimitred:
                        this.limitred=data.limitred;
                        this.autoAmount();
                        // //设置额度，优先取与限红一致的额度，个数超出则去除，不够则补足
                        // let limitredArr=this.limitred.p.concat(this.limitred.f).concat(this.limitred.d);
                        // let noRepeatArr=[];//去掉重复项后的限红数组
                        // let chooseArr=[];//筛选出的额度数组
                        // for (let i = 0; i < limitredArr.length; i++) {//限红去重
                        //     noRepeatArr.indexOf(limitredArr[i])==-1&&noRepeatArr.push(limitredArr[i]);
                        // }
                        // for (let i = 0; i < AMOUTN_ARR.length; i++) {//挑选与限红一样的额度
                        //     noRepeatArr.indexOf(AMOUTN_ARR[i])!=-1&&chooseArr.push(AMOUTN_ARR[i]);
                        // }
                        // if(chooseArr.length>5){//多于5个
                        //     this.currAmountList=chooseArr.slice(0,5);//取前5个
                        // }else if(chooseArr.length<5){//少于5个，等于5个不考虑
                        //     for (let i = 0; i < AMOUTN_ARR.length; i++) {//用额度列表中的额度补足
                        //         chooseArr.indexOf(AMOUTN_ARR[i])==-1&&chooseArr.push(AMOUTN_ARR[i]);
                        //         if(chooseArr.length==5){
                        //             break;
                        //         }
                        //     }
                        //     this.currAmountList=chooseArr;
                        // }
                        // this.currAmountListSort=this.currAmountList.slice();
                        // this.currAmountListSort.sort((a,b)=>a-b);
                        break;
                    case COMMAND.all_endgame:
                        data.id==this.roomInfo.id&&this.resetAmount("submiting");
                        break;
                    case COMMAND.cor_gameresult:
                        if(data.id==this.roomInfo.id){
                            this.currPokerXY=[0,0];
                            this.pearlArr.push(data.pearl);
                            this.setCoruscate(data.pearl);
                            if(data.poker.length>0){//有牌
                                this.showPokerArr=data.poker;
                                this.showPokerTxtArr=this.resultToTxt(data.pearl);
                                this.pokerArr.push(data.poker);
                                setTimeout(() => {
                                    this.showPokerArr=[];
                                    this.showPokerTxtArr=[];
                                }, 8000);
                            }
                        }
                        break;
                    case COMMAND.all_getcurrpoker:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=false;
                            data.poker.forEach(arr=>arr.length=5);
                            this.showPokerArr=data.poker;
                            this.currPokerXY=data.index;
                        }
                        break;
                    case COMMAND.all_settlement:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=true;
                            if(this.submitedCount>0){
                                this.submitedCount>0&&this.$emit("ws-send",COMMAND.all_getgain,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);//有提交过，则获取输赢
                                this.resetAmount("all");
                                this.submitedCount=0;
                            }
                        }
                        break;
                    case COMMAND.all_getgain:
                        (data.win!=0||data.lose!=0)&&useLayer({str:(data.win > Math.abs(data.lose)? this.$t("common.win"):this.$t("common.lose"))+Math.abs(data.win - data.lose)});
                        break;
                    case COMMAND.cor_receivepool:
                        data.id==this.roomInfo.id&&(this.pool=data.pool);
                        break;
                    case COMMAND.cor_submitbet:
                        switch (data.result) {
                            case SUBMIT_RESULT.success:
                                useLayer({str:this.$t("common.subSuccess")});
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        realCoin:data.realmoney
                                    }
                                })
                                for(let i in this.submited){
                                    for (let j = 0; j < this.submited[i].length; j++) {
                                        this.submited[i][j]+=this.submiting[i][j];
                                        this.submitedCount+=this.submiting[i][j];
                                    }
                                }
                                this.resetAmount("submiting");//清空将提交额度列表
                                break;
                            case SUBMIT_RESULT.ban:
                                useLayer({str:this.$t("common.subBan")});
                                break;
                            case SUBMIT_RESULT.unmoney:
                                useLayer({str:this.$t("common.unMoney")});
                                break;
                            case SUBMIT_RESULT.countdown:
                                useLayer({str:this.$t("common.subCountdown")});
                                break;
                            case SUBMIT_RESULT.service:
                                useLayer({str:this.$t("common.service")});
                                break;
                            default:
                                useLayer({str:this.$t("common.subErr")});
                                break;
                        }
                        break;
                    case COMMAND.all_cancelbet:
                        if(data.result==CANCEL_RESULT.success){
                            this.resetAmount("all");
                            this.submitedCount=0;
                            this.$store.commit({
                                type:MUTATION_TYPE.setUserInfo,
                                userInfo:{
                                    realCoin:data.realmoney
                                }
                            })
                            useLayer({str:this.$t("common.cancelSuccess")});
                        }else{
                            useLayer({str:this.$t("common.cancelErr")});
                        }
                        break;
                    case COMMAND.all_changeboot:
                        if(data.id==this.roomInfo.id){
                            this.pearlArr=[];
                            this.pokerArr=[];
                            this.$store.commit({
                                type:MUTATION_TYPE.setRoomInfo,
                                roomInfo:{
                                    boot:data.boot,
                                    innings:data.innings
                                }
                            })
                        }
                        break;
                    case COMMAND.cor_getbetdetail:
                        let model;
                        if(this.submitModel==0){
                            model="z";
                        }else if(this.submitModel==1){
                            model="x";
                        }
                        this.submited={
                            p:[data.detail[model][0],data.detail[model][3],data.detail[model][6]],//平
                            f:[data.detail[model][1],data.detail[model][4],data.detail[model][7]],//翻
                            d:[data.detail[model][2],data.detail[model][5],data.detail[model][8]],//多
                        }
                        this.submitedCount= data.detail.z.concat(data.detail.x).reduce((prev,val)=>prev+val);
                        break;
                    case COMMAND.all_entertable:
                        this.$root.$refs.startAudioPlayer.pause();
                        this.$root.$refs.endAudioPlayer.pause();
                        this.$root.$refs.tenAudioPlayer.pause();
                        this.showPokerArr=[];
                        this.showPokerTxtArr=[];
                        this.coruscateArr=[];
                        this.pokerArr=[];
                        this.$root.getRoomPearl(this.roomInfo.game,this.roomInfo.id);
                        this.init();
                        break;
                    case COMMAND.cor_getroompoker:
                        let arr=data.list;
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].xp.unshift(arr[i].zp);
                            this.pokerArr.push(arr[i].xp);
                        }
                        break;
                }
            }
        },
        /**
         * 为位置追加将要提交的额度
         * @param {String} type 位置类型(z、x、h、zd、xd)
         * @param {Int32Array} i 下标
         */
        addAmount(type,i){
            let limitred=this.limitred;//获取限红
            let amount=this.amount;
            if(amount==Infinity){//如果选择allin
                amount=this.userInfo.realCoin-this.getAmountCount("submiting");
            }
            if(this.roomInfo.countdown<=0){
                useLayer({str:this.$t("room.noStartGame")});
                return;
            }else if(this.userInfo.realCoin<this.getAmountCount("submiting")+amount){//余额不足
                useLayer({str:this.$t("common.unMoney")});
                return;
            }
            if(amount!=Infinity){//非满额度
                if(amount>limitred[type][1]){//单次高于上限
                    useLayer({str:this.$t("room.higherLimitred")+limitred[type][1]});
                    return;
                }else if(this.submiting[type][i]+this.submited[type][i]+amount>limitred[type][1]){//多次并高于上限
                    useLayer({str:this.$t("room.laveLimitred")+(limitred[type][1]-this.submiting[type][i]-this.submited[type][i])});
                    return;
                }
            }else{//满额度
                amount=limitred[type][1]-this.submiting[type][i]-this.submited[type][i];
                if(amount==0){
                    useLayer({str:this.$t("room.fullAmount")});
                    return;
                }
            }
            this.submiting[type][i]=this.submiting[type][i]+amount;
        },
        /**
         * 额度换算
         */
        chipsConvert(amount){
            let arr=[];//最终转换成的额度
            let amountArr = AMOUTN_ARR.slice(0,AMOUTN_ARR.length-1);//取除了梭哈之外的额度
            for (let i = amountArr.length-1; i >= 0; i--) {
                if(amount>=amountArr[i]){
                    let num=Math.floor(amount/amountArr[i]);//获取要换算的额度最多能换到多少个当前遍历到的额度
                    for (let j = 0; j < num; j++) {
                        arr.push(amountArr[i]);
                    }
                    amount-=num*amountArr[i];
                }
            }
            return arr;
        },
        /**
         * 提交事件
         */
        submitEvent(event){
            switch (event) {
                case "clear"://点击清除
                    this.canClear&&(this.submiting={
                        p:[0,0,0],//平
                        f:[0,0,0],//翻
                        d:[0,0,0],//多
                    });//将要提交的额度列表;
                    break;
                case "cancel"://点击取消
                    if(this.canCancel){
                        useLayer({
                            type:LAYER_TYPE.confirm,
                            str:this.$t("common.isCancel"),
                            btn:[this.$t("common.yes"),this.$t("common.no")],
                            yesFn:(i)=>{
                                layer.close(i);
                                this.$emit("ws-send",COMMAND.all_cancelbet,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
                            }
                        })
                    }
                    break;
                case "confirm"://点击确定
                    if(this.canConfirm){
                        this.$emit("ws-send",COMMAND.cor_submitbet,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,
                            this.getAmountCount("submiting"),this.getAmountCount("submiting")+this.submitedCount,this.submitModel==0?'z':'x',
                            String([this.submiting.p[0],this.submiting.f[0],this.submiting.d[0],this.submiting.p[1],this.submiting.f[1],this.submiting.d[1],this.submiting.p[2],this.submiting.f[2],this.submiting.d[2],]),
                            this.userInfo.id,this.$root.ip.cip,getDeviceType(),this.userInfo.guid);
                    }
                    break;
            }
        },
        /**
         * 重置额度
         */
        resetAmount(type){
            if(type=="submiting"||type=="all"){//预投或总投
                for (let i in this.submiting) {
                    for(let j in this.submiting[i]){
                        this.submiting[i][j]=0;
                    }
                }
            }
            if(type=="submited"||type=="all"){//已投或总投
                for (let i in this.submited) {
                    for(let j in this.submited[i]){
                        this.submited[i][j]=0;
                    }
                }
            }
        },
        /**
         * 获取额度
         * @param {String} type 额度类型(submited、submiting、all)
         */
        getAmountCount(type){
            let count=0;
            if(type=="submiting"||type=="all"){//预投或总投
                count+=Object.values(this.submiting).flat().reduce((prev,val)=>prev+val);
            }
            if(type=="submited"||type=="all"){//已投或总投
                count+=Object.values(this.submited).flat().reduce((prev,val)=>prev+val);
            }
            return count;
        },
        /**
         * 设置闪烁位
         */
        setCoruscate(pearl,fn){
            let arr=[0,0,0];
            pearl[1]>0&&(arr[0]=1);
            pearl[2]>0&&(arr[1]=1);
            pearl[3]>0&&(arr[2]=1);
            this.coruscateArr=arr;
            setTimeout(() => {
                this.coruscateArr=[];
                fn&&fn();
            }, 3000);
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            this.drawPearl(this.pearlArr);
        },
        /**
         * 切换提交模式
         */
        changeModel(model,e){
            let fn=()=>{
                this.submitModel=model;
                this.resetAmount("submiting");
                //获取各位置已提交额度
                this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
            }
            if(this.getAmountCount("submiting")!=0){
                this.submitModel=model==1?0:1;
                useLayer({
                    type:LAYER_TYPE.confirm,
                    str:this.$t("room.hasUnSubmit"),
                    btn:[this.$t("common.yes"),this.$t("common.no")],
                    yesFn:(i)=>{
                        fn();
                        layer.close(i);
                    }
                })
            }else{
                fn();
            }
        },
        /**
         * 将结果转换为文本
         */
        resultToTxt(pearlArr){
            let arr=[];
            let numArr=[this.$t("common.one"),this.$t("common.two"),this.$t("common.three"),this.$t("common.four"),this.$t("common.five"),this.$t("common.six"),this.$t("common.seven"),this.$t("common.eight"),this.$t("common.nine")];
            for (let i = 0; i < pearlArr.length; i++) {
                var text = Math.abs(pearlArr[i]);
                if (text == '10') {
                    text = this.$t("room.resultGreat");
                } else if (text == '20') {
                    text = this.$t("room.resultSilver");
                } else if (text == '30') {
                    text = this.$t("room.resultGold");
                } else if (text == '40') {
                    text = this.$t("room.resultBomb");
                } else if (text == '11'||text=='0') {
                    text = this.$t("room.resultNothing");
                }else{
                    text=this.$t("app.great")+numArr[text-1];
                }
                arr.push(text);
            }
            return arr;
        },
        /**
         * 显示牌
         * @param {Number} type 移入或移出
         */
        showPoker(e,type){
            if(type==1){//移入
                if(this.pearlLayerStyle.width!=0){
                    let cellWidth=this.pearlLayerStyle.width.replace('px','');
                    let cellHeight=this.pearlLayerStyle.height.replace('px','');
                    let column=(getComputedStyle(e.target).width.replace('px','')/cellWidth).toFixed()-1;//获取总列数减去一列标题列
                    let x = Math.ceil(e.offsetX / cellWidth)-1;//列
                    let y = Math.ceil(e.offsetY / cellHeight);//行
                    let pokerArr=this.pokerArr.slice(-column);
                    let poker=pokerArr[x-1];//获取对应位置的扑克
                    if((x!=this.point[0])&&y!=0&&x>0&&x<=column&&poker&&this.isShowPoker){//坐标点相比于上次有不同并且大于0行0列并且对应位置有扑克数据
                        this.point=[x,y];
                        this.$nextTick(()=>{
                            this.pearlLayerStyle.display='block';
                            this.pearlLayerStyle.left=x*cellWidth+'px';
                            this.pearlLayerStyle.top=(y-1)*cellHeight+'px';
                            this.showPokerArr=poker;
                            this.showPokerTxtArr=this.resultToTxt(this.pearlArr.slice(-column)[x-1]);
                        })
                    }
                }
            }else{//移出
                this.showPokerArr=[];
                this.showPokerTxtArr=[];
                this.pearlLayerStyle.display='none';
                this.point=[0,0];
            }
        },
        /**
         * 绘制路单
         */
        drawPearl(pearlArr){
            let elem=document.getElementById("pearl");
            //设置画布大小
            this.$root.setCanvasSize(elem);
            //绘制画布表格
            let obj = this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(this.roomInfo.game))!=-1),true,1.5);
            if(obj){//判断对象是否存在，防止视口被拉小到没有的时候找不到而报错
                this.pearlLayerStyle.width=obj.cellWidth/RATIO+'px';
                this.pearlLayerStyle.height=(obj.cellHeight/RATIO)*4+'px';
                this.pearlLayerStyle.left=obj.cellWidth/RATIO+'px';
                this.$root.drawPearl(obj,pearlArr.slice(-obj.column),null,this.roomInfo.game);
            }
        },
        /**
         * 自动额度选择
         */
        autoAmount(){
            if(this.currAmountList.length==0){//没有选择过额度
                let max=this.limitred.f[1];
                let arr=[];
                if (max<=1000) {
                    arr= [[10,20],[50,100],[200]];
                }else if ((max>1000&&max<=2000)||(max>2000&&max<=5000)) {
                    arr= [[10,50],[100,500],[1000]];
                }else if(max>5000&&max<=10000){
                    arr= [[10,100],[500,1000],[2000]];
                }else if(max>10000&&max<=20000){
                    arr= [[10,100],[500,1000],[5000]];
                }else if(max>20000&&max<=100000){
                    arr= [[10,100],[1000,5000],[10000]];
                }else {
                    arr= [[10,50],[100,500],[1000]];
                }
                this.$store.commit({
                    type:MUTATION_TYPE.setCurrAmountList,
                    currAmountList:arr
                })
            }
        },
    },
    watch: {
        /**
         * 监听路单数据变化
         */
        pearlArr:{
            handler(newVal){
                this.drawPearl(newVal);
            },
            deep:true//深度监听
        },
        /**
         * 监听视频线路id改变
         */
        currVideoLine(newVal){
            if(newVal!=""){
                //获取台面视频
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,newVal,this.userInfo.id,this.userInfo.guid);
            }else{
                this.videoList=null;
            }
        },
        /**
         * 监听将要提交的额度变化
         */
        submiting:{
            handler(){
                if(this.getAmountCount("submiting")<=0){
                    this.canClear=false;
                    this.canConfirm=false;
                }else{
                    this.canClear=true;
                    this.canConfirm=true;
                }
            },
            deep:true
        },
        /**
         * 监听已提交过的额度变化
         */
        submited:{
            handler(){
                if(this.getAmountCount("submited")<=0){
                    this.canCancel=false;
                }else{
                    this.canCancel=true;
                }
            },
            deep:true
        },
        /**
         * 监听guid改变（断线重连）
         */
        "userInfo.guid"(newVal,oldVal){
            if(newVal!=oldVal&&newVal!=""){
                this.init();
            }
        },
    },
};
</script>