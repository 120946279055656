<!-- 台面列表 -->
<style scoped>
    .sibbon{
        left: 16%;
        bottom: -38%;
        transform: translate(-50%,0) rotateZ(-45deg);
    }
    .ray-hover::before{
        position: absolute;
        content: '';
        display: inline-block;
        background-color: #fff;
        z-index: 1;
        left: 0;
        width: 20%;
        height: 200%;
        transform: scale(0);
    }
    .ray-hover:hover::before{
        animation: ray-hover 2.5s linear;
        animation-delay: -1.6s;
    }
    @keyframes ray-hover{
        0% { transform: scale(0) rotate(-45deg); opacity: 0; }
        80% { transform: scale(0) rotate(-45deg); opacity: 0; }
        81% { transform: scale(4) rotate(-45deg); opacity: .8; }
        100% { transform: scale(40) rotate(-45deg); opacity: 0; }
    }
    .scroll-shadow{
        box-shadow: inset 0 0 .2rem #ededed;
    }
    .text-3d>div{
        background-clip: text;
        grid-area: overlap;
        -webkit-text-stroke: .2rem transparent;
    }
    .text-3d-bg{
        /* background-image: repeating-linear-gradient( 105deg, #ffb338 0% , #3e2904 5%, rgb(233, 233, 0) 12%); */
        background-image: repeating-linear-gradient( 105deg, #ffb338 0% , #3e2904 20%, rgb(233, 233, 0) 40%);
        color: transparent;
        transform: scale(1.05);
        transform-origin: top left;
    }
    .text-3d-fg{
        /* color: transparent; */
        background-image: repeating-linear-gradient( 5deg, #ffb338 0% , #77571d 23%, yellow 31%);
        transform: scale(1);
    }
    .img-cover-top{
        object-fit: cover;
        object-position: top;
    }
    .filter-drop-hover{
        transition: filter .15s linear;
    }
    .bg-ticket-left-outer{
        background: 
            radial-gradient(circle at left top, transparent .8rem, var(--outer-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .8rem, var(--outer-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--outer-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--outer-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-outer.lh{
        background: 
            radial-gradient(circle at left top, transparent .8rem, var(--outer-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .8rem, var(--outer-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--outer-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--outer-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-inside{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--inside-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--inside-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .4rem, var(--inside-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .4rem, var(--inside-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-inside.lh{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--inside-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--inside-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .4rem, var(--inside-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .4rem, var(--inside-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-outer{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--outer-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--outer-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .8rem, var(--outer-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .8rem, var(--outer-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-outer.lh{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--outer-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--outer-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .8rem, var(--outer-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .8rem, var(--outer-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-inside{
        background: 
            radial-gradient(circle at left top, transparent .4rem, var(--inside-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .4rem, var(--inside-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--inside-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--inside-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-inside.lh{
        background: 
            radial-gradient(circle at left top, transparent .4rem, var(--inside-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .4rem, var(--inside-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--inside-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--inside-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-outer::before{
        content: '';
        width: .1rem;
        background: linear-gradient(to top,var(--inside-blue) 0%,var(--inside-blue) 50%,transparent 50%) top right/ 1rem 1rem repeat-y;
        position: absolute;
        right: 0;
        top:.5rem;
        bottom: 1rem;
    }
    .bg-ticket-left-outer.lh::before{
        background: linear-gradient(to top,var(--inside-red) 0%,var(--inside-red) 50%,transparent 50%) top right/ 1rem 1rem repeat-y;
    }
    .text-ticket{
        color: var(--outer-blue);
    }
    .lh .text-ticket{
        color: var(--outer-red);
    }
    /* 光柱特效 */
    .light {
        position: absolute;
        width: 0px;
        opacity: .75;
        background-color: white;
        box-shadow: #e9f1f1 0px 0px 5px 1px;
        opacity: 0;
        top: 100vh;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
    }
    .x1{
        animation: floatUp 4s infinite linear;
        transform: scale(1.0);
    }
    .x2{
        animation: floatUp 7s infinite linear;
        transform: scale(1.6);
        left: 15%;
    }
    .x3{
        animation: floatUp 2.5s infinite linear;
        transform: scale(.5);
        left: -15%;
    }
    .x4{
        animation: floatUp 4.5s infinite linear;
        transform: scale(1.2);
        left: -34%;
    }
    .x5{
        animation: floatUp 8s infinite linear;
        transform: scale(2.2);
        left: -57%;
    }
    .x6{
        animation: floatUp 3s infinite linear;
        transform: scale(.8);
        left: -81%;
    }
    .x7{
        animation: floatUp 5.3s infinite linear;
        transform: scale(3.2);
        left: 37%;
    }
    .x8{
        animation: floatUp 4.7s infinite linear;
        transform: scale(1.7);
        left: 62%;
    }
    .x9{
        animation: floatUp 4.1s infinite linear;
        transform: scale(0.9);
        left: 85%;
    }
    @keyframes floatUp{
        0%{top: 100vh; opacity: 0;}
        25%{opacity: 1;}
        50%{top: 0vh; opacity: .8;}
        75%{opacity: 1;}
        100%{top: -100vh; opacity: 0;}
    }
    /* 散景特效 */
    .anim-danger{
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }
    .anim-danger span{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 20vmin;
        backface-visibility: hidden;
        position: absolute;
        animation-name: move;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: 2;
        box-shadow: -2rem 0 .5rem currentColor !important;
        opacity: .5;
    }
    .anim-danger span:nth-child(1) {
        color: #583C87;
        top: 47%;
        left: 10%;
        animation-duration: 10.5s;
        animation-delay: -12.6s;
        transform-origin: 0 -4.5rem;
        box-shadow: -1rem 0 .5rem currentColor;
    }
.anim-danger span:nth-child(2) {
  color: #E45A84;
  top: 33%;
  left: 45%;
  animation-duration: 13.2s;
  animation-delay: -15.6s;
  transform-origin: 7rem 9rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(3) {
  color: #E45A84;
  top: 90%;
  left: 55%;
  animation-duration: 14.8s;
  animation-delay: -3.5s;
  transform-origin: -9rem 4.5rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(4) {
  color: #E45A84;
  top: 7%;
  left: 88%;
  animation-duration: 15.4s;
  animation-delay: -2.6s;
  transform-origin: 10rem 7rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(5) {
  color: #583C87;
  top: 65%;
  left: 26%;
  animation-duration: 15.3s;
  animation-delay: -6s;
  transform-origin: 4.5rem -11rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(6) {
  color: #E45A84;
  top: 45%;
  left: 30%;
  animation-duration: 11.2s;
  animation-delay: -6.6s;
  transform-origin: -1.5rem 11rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(7) {
  color: #583C87;
  top: 40%;
  left: 95%;
  animation-duration: 10.3s;
  animation-delay: -6.6s;
  transform-origin: 3rem .5rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(8) {
  color: #583C87;
  top: 84%;
  left: 91%;
  animation-duration: 15.6s;
  animation-delay: -15.3s;
  transform-origin: 7rem 10rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(9) {
  color: #FFACAC;
  top: 88%;
  left: 49%;
  animation-duration: 12.5s;
  animation-delay: -6.3s;
  transform-origin: -11rem 9rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(10) {
  color: #FFACAC;
  top: 4%;
  left: 16%;
  animation-duration: 11.9s;
  animation-delay: -10.2s;
  transform-origin: -2rem 6rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(11) {
  color: #583C87;
  top: 16%;
  left: 99%;
  animation-duration: 10.4s;
  animation-delay: -9s;
  transform-origin: -1rem -2rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(12) {
  color: #FFACAC;
  top: 46%;
  left: 81%;
  animation-duration: 11.4s;
  animation-delay: -9.2s;
  transform-origin: 1rem -1rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(13) {
  color: #E45A84;
  top: 69%;
  left: 75%;
  animation-duration: 12.3s;
  animation-delay: -7.7s;
  transform-origin: 6rem -2rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(14) {
  color: #FFACAC;
  top: 67%;
  left: 52%;
  animation-duration: 12.3s;
  animation-delay: -1.2s;
  transform-origin: 11rem -2rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(15) {
  color: #E45A84;
  top: 38%;
  left: 57%;
  animation-duration: 12.4s;
  animation-delay: -8.2s;
  transform-origin: 5rem 10rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(16) {
  color: #FFACAC;
  top: 78%;
  left: 94%;
  animation-duration: 13.4s;
  animation-delay: -13.2s;
  transform-origin: -10rem 4rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(17) {
  color: #E45A84;
  top: 57%;
  left: 38%;
  animation-duration: 15.5s;
  animation-delay: -12.1s;
  transform-origin: -10rem -10rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(18) {
  color: #FFACAC;
  top: 65%;
  left: 45%;
  animation-duration: 11.2s;
  animation-delay: -2.6s;
  transform-origin: 0rem -12rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(19) {
  color: #FFACAC;
  top: 18%;
  left: 39%;
  animation-duration: 11.7s;
  animation-delay: -15.9s;
  transform-origin: -12rem 5rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
.anim-danger span:nth-child(20) {
  color: #FFACAC;
  top: 87%;
  left: 15%;
  animation-duration: 11.1s;
  animation-delay: -6.2s;
  transform-origin: -4rem -12rem;
  box-shadow: -1rem 0 .5rem currentColor;
}
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
    /* 滑动对角线 */
    .anim-primary{
        animation:slide 3s ease-in-out infinite alternate;
        background-image: linear-gradient(-60deg, rgba(255,255,255,.15) 50%, rgba(255,255,255,.05) 50%);
        bottom:0;
        left:-50%;
        opacity:.5;
        position:absolute;
        right:-50%;
        top:0;
    }
    .anim-primary-1{
        animation-direction:alternate-reverse;
        animation-duration:4s;
    }
    .anim-primary-2{
        animation-duration:5s;
    }
    @keyframes slide {
        0% {
            transform:translateX(-25%);
        }
        100% {
            transform:translateX(25%);
        }
    }
    /* 漂浮方块 */
    .anim-warning{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .anim-warning li {
        position: absolute;
        list-style: none;
        display: block;
        width: 3rem;
        height: 3rem;
        background-color: rgba(255, 255, 255, 0.15);
        bottom: -160px;
        animation: square 25s infinite;
        transition-timing-function: linear;
    }
.anim-warning li:nth-child(1) {
  left: 10%;
}
.anim-warning li:nth-child(2) {
  left: 20%;
  width: 2rem;
  height: 2rem;
          animation-delay: 2s;
          animation-duration: 17s;
}
.anim-warning li:nth-child(3) {
  left: 25%;
          animation-delay: 4s;
}
.anim-warning li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
          animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.anim-warning li:nth-child(5) {
  left: 70%;
}
.anim-warning li:nth-child(6) {
  left: 80%;
  width: 4rem;
  height: 4rem;
          animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.anim-warning li:nth-child(7) {
  left: 32%;
  width: 3rem;
  height: 3rem;
          animation-delay: 7s;
}
.anim-warning li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
          animation-delay: 15s;
          animation-duration: 40s;
}
.anim-warning li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
          animation-delay: 2s;
          animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.anim-warning li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
          animation-delay: 11s;
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-700px) rotate(600deg);
  }
}

    .bg-anim-danger {
        font-weight: 100;
        background: radial-gradient(#a23982,#1f1013);
        overflow-y: hidden;
        animation: fadeIn 1 1s ease-out;
    }
    .bg-anim-primary {
        font-weight: 100;
        background: radial-gradient(#1c35a3,#395be7);
        overflow-y: hidden;
        animation: fadeIn 1 1s ease-out;
    }
    .bg-anim-success {
        font-weight: 100;
        background: radial-gradient(#055700, #0aab00);
        overflow-y: hidden;
        animation: fadeIn 1 1s ease-out;
    }
    .bg-anim-warning {
        font-weight: 100;
        background: radial-gradient(#665c00, #d1bc00);
        overflow-y: hidden;
        animation: fadeIn 1 1s ease-out;
    }
    .m-1px{
        margin: 1px;
    }
    .item-shadow{
        box-shadow: 0 0.1rem .2rem #000;
    }
    .room-name-text-shadow{
        text-shadow: 0 .06rem 0 #a9a9a9, 0 .15rem 1px #000;
    }
    .room-carry-text-shadow{
        text-shadow: 0 0 0 rgb(255 255 255 / 25%), 2px 2px 4px rgb(0 0 0 / 35%);
    }
    .box-success{
        background: linear-gradient(45deg,#0fff00 30%,#ffffff99 80%,#0fff00);
    }
    .box-primary{
        background: linear-gradient(45deg,#4c6dfe 30%,#ffffff99 80%,#4c6dfe);
    }
    .box-danger{
        background: linear-gradient(45deg,#70295b 30%,#ffffff99 80%,#70295b);
    }
    .box-warning{
        background: linear-gradient(45deg,#fbfe4c 30%,#ffffff99 80%,#fbfe4c);
    }
    .inner-shadow-success{
        box-shadow: rgb(199, 210, 255) 0px 0px 0.2rem inset;
    }
    .inner-shadow-primary{
        box-shadow: rgb(199, 210, 255) 0px 0px 0.2rem inset;
    }
    .inner-shadow-danger{
        box-shadow: rgb(199, 210, 255) 0px 0px 0.2rem inset;
    }
    .inner-shadow-warning{
        box-shadow: rgb(199, 210, 255) 0px 0px 0.2rem inset;
    }
    .img-success{
        background:linear-gradient(140deg, transparent, #ffffff12 40%, transparent 40%), linear-gradient(0deg, transparent 70%, #ffffff30),url('@/assets/images/home/texasauto_7.png') no-repeat 15%/auto 80%;
    }
    .img-primary{
        background:linear-gradient(140deg, transparent, #ffffff12 40%, transparent 40%), linear-gradient(0deg, transparent 70%, #ffffff30),url('@/assets/images/home/texasauto_5.png') no-repeat 15%/auto 80%;
    }
    .img-danger{
        background:linear-gradient(140deg, transparent, #ffffff12 40%, transparent 40%), linear-gradient(0deg, transparent 70%, #ffffff30),url('@/assets/images/home/texasauto_2.png') no-repeat 15%/auto 80%;
    }
    .img-warning{
        background:linear-gradient(140deg, transparent, #ffffff12 40%, transparent 40%), linear-gradient(0deg, transparent 70%, #ffffff30),url('@/assets/images/home/texasauto_6.png') no-repeat 15%/auto 80%;
    }
    .carry-success{
        background: linear-gradient(to bottom, #fff 20%, #0de500 85%, #fff);
    }
    .carry-primary{
        background: linear-gradient(to bottom, #ffffff 20%, #0035ff 85%, #fff);
    }
    .carry-danger{
        background: linear-gradient(to bottom, #ffffff 20%, #ff0000 85%, #fff);
    }
    .carry-warning{
        background: linear-gradient(to bottom, #ffffff 20%, #ffe600 85%, #fff);
    }
    .room-text-success{
        color: #e2ffcc;
    }
    .room-text-primary{
        color: #d4ccff;
    }
    .room-text-danger{
        color: #ffcce0;
    }
    .room-text-warning{
        color: #feffcc;
    }
    .player-bg-success{
        background: #091c0082;
    }
    .player-bg-primary{
        background: #02003982;
    }
    .player-bg-danger{
        background: #ff416b82;
    }
    .player-bg-warning{
        background: #35390082;
    }
    .antes-bg-success{
        background: linear-gradient(90deg,transparent,#091c0082,transparent);
    }
    .antes-bg-primary{
        background: linear-gradient(90deg,transparent,#02003982,transparent);
    }
    .antes-bg-danger{
        background: linear-gradient(90deg,transparent,#ff416b82,transparent);
    }
    .antes-bg-warning{
        background: linear-gradient(90deg,transparent,#35390082,transparent);
    }
</style>
<template>
    <div class="w-100 h-100 position-relative d-flex">

        <!-- 列表 -->
        <div class="flex-grow-1 overflow-y-scroll scroll-none">
            <div v-if="roomList" id="scrollContent" class="d-flex flex-wrap align-content-start pe-2">

                <div v-for="(item,i) in roomList" class="ps-sm-0 mb-1" v-load="i" :class="$attrs.class" :key="item.id">
                    <div class="rounded-5 position-relative filter-drop-hover scale-hover overflow-hidden ms-2">

                        <!-- 无路布局 -->
                        <div v-if="!$root.hasPearl(item['game'])" @click="goRoom(item.id,item.game)" class="d-flex justify-content-center align-items-center ray-hover position-relative m-2 " role="button">
                            <template v-if="item.game==GAME_ID.texasauto">
                                <!-- 初级场 -->
                                <div class="rounded position-relative w-13rem h-6rem item-shadow" :class="{'box-success':item.carry==100,'box-primary':item.carry==1000,'box-danger':item.carry==5000,'box-warning':item.carry==10000}">
                                    <!-- 动态效果 -->
                                    <div class="rounded position-absolute end-0 start-0 top-0 bottom-0 m-1px overflow-hidden" :class="{'inner-shadow-success bg-anim-success':item.carry==100,'inner-shadow-primary bg-anim-primary':item.carry==1000,'inner-shadow-danger bg-anim-danger':item.carry==5000,'inner-shadow-warning bg-anim-warning':item.carry==10000}">
                                        <template v-if="item.carry==100">
                                            <div v-for="i in 9" :key="i" :class="['x'+i]" class="light"></div>
                                        </template>
                                        <template v-if="item.carry==1000">
                                            <div class="anim-primary"></div>
                                            <div class="anim-primary anim-primary-1"></div>
                                            <div class="anim-primary anim-primary-2"></div>
                                        </template>
                                        <template v-if="item.carry==5000">
                                            <div class="anim-danger">
                                                <span v-for="i in 20" :key="i"></span>
                                            </div>
                                        </template>
                                        <template v-if="item.carry==10000">
                                            <ul class="anim-warning">
                                                <li v-for="i in 10"></li>
                                            </ul>
                                        </template>
                                    </div>
                                    <div class="d-flex flex-column w-100 h-100 position-relative px-2 overflow-hidden rounded" :class="{'img-success':item.carry==100,'img-primary':item.carry==1000,'img-danger':item.carry==5000,'img-warning':item.carry==10000}">
                                        <!-- 带入金 -->
                                        <div class="d-flex flex-column justify-content-center align-items-end flex-grow-1 text-light">
                                            <i class="pe-2 room-name-text-shadow">{{item.carry==100?"初级场":item.carry==1000?"中级场":item.carry==5000?"高级场":"至尊场"}}</i>
                                            <i class="fw-bold fs-2 lh-1 pe-2 text-transparent bg-clip-text room-carry-text-shadow" :class="{'carry-success':item.carry==100,'carry-primary':item.carry==1000,'carry-danger':item.carry==5000,'carry-warning':item.carry==10000}">{{item.carry}}</i>
                                        </div>
                                        <div class="d-flex justify-content-between py-1 small">
                                            <!-- 人数 -->
                                            <div class="d-flex align-items-center rounded-pill px-2 small lh-1" :class="{'room-text-success player-bg-success':item.carry==100,'room-text-primary player-bg-primary':item.carry==1000,'room-text-danger player-bg-danger':item.carry==5000,'room-text-warning player-bg-warning':item.carry==10000}">
                                                <i class="bi bi-person-fill me-1"></i>
                                                <span>{{ item.currnum+"︱7" }}</span>
                                            </div>
                                            <!-- 盲注 -->
                                            <div class="d-flex small px-3" :class="{'room-text-success antes-bg-success':item.carry==100,'room-text-primary antes-bg-primary':item.carry==1000,'room-text-danger antes-bg-danger':item.carry==5000,'room-text-warning antes-bg-warning':item.carry==10000}">
                                                <span class="small">盲注：</span>
                                                <span class="small">{{item.antes/2}}/{{item.antes}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <img class="img-fluid w-9rem" :src="getTableImgSrc(item)" alt="">
                                <div class="position-absolute w-100 h-100 rounded-10 overflow-hidden d-flex justify-content-end flex-column">
                                    <span v-if="getTableShow(item)" class="bg-dark bg-opacity-50 text-center fw-bold text-light d-flex justify-content-center" v-html="getTableShow(item)"></span>
                                </div>
                            </template>
                        </div>

                        

                        <!-- 有路布局 -->
                        <div v-else @click="goRoom(item.id,item.game)" class="flex-grow-1 d-flex ray-hover" role="button">
                            <!-- 头像 -->
                            <div class="d-flex flex-column text-center position-relative overflow-hidden p-2 bg-ticket-left-outer" :class="{'lh':item.game==GAME_ID.lh}">
                                <div class="d-flex flex-column bg-ticket-left-inside p-2 min-h-100 position-relative" :class="{'lh':item.game==GAME_ID.lh}">
                                    <div class="position-relative" :class="imgClass">
                                        <img class="rounded w-100 h-100 img-cover-top position-absolute top-0 start-0" :src="item.workerimg||require('@/assets/images/home/model.jpg')" alt="">
                                        <div v-if="item.worker" class="bg-dark bg-opacity-50 position-absolute bottom-0 start-0 end-0 text-light rounded-bottom small">{{ decodeURI(item.worker)}}</div>
                                    </div>
                                    <div class="position-absolute h-100 text-light text-vertical fw-bold sibbon small bg-gradient text-spacing-_1" :class="item.isar==1?'bg-success':'bg-secondary'">{{item.isar==1?$t("roomlist.new"):$t("roomlist.old")}}</div>
                                </div>
                            </div>
                            <!-- 信息栏 -->
                            <div class="flex-grow-1 w-0 p-2 bg-ticket-right-outer" :class="{'lh':item.game==GAME_ID.lh}">
                                <div class="h-100 d-flex flex-column bg-ticket-right-inside p-2" :class="{'lh':item.game==GAME_ID.lh}">
                                    <!-- 信息 -->
                                    <div class="d-flex lh-1 align-items-center">
                                        <div class="d-none d-sm-flex flex-column fw-bold text-warning">
                                            <span>{{item.room}}</span>
                                            <span class="text-center">{{item.table}}</span>
                                        </div>
                                        <div class="flex-grow-1 d-flex flex-column me-2 ms-sm-2 small">
                                            <div class="d-flex border-bottom justify-content-between" style="border-color:#48769d !important;border-bottom-style:dashed !important">
                                                <b class="d-sm-none text-warning">{{item.room}}{{item.table}}</b>
                                                <div class="text-light pb-1">
                                                    <span>{{item.boot}}{{$t("common.boot")}}</span>
                                                    <span>{{item.innings}}{{$t("common.bout")}}</span>
                                                </div>
                                                <span v-show="item.pokermode==POKER_TYPE.show" class="rounded-pill bg-yellow small px-1 mb-1 fw-bold lh-sm text-dark">明牌</span>
                                            </div>
                                            <div class="d-flex pt-1">
                                                <div class="d-flex w-0 flex-grow-1 overflow-x-scroll scroll-none" style="-webkit-overflow-scrolling: touch;">
                                                    <div v-if="!countList[item.id]" class="fw-bold d-flex align-items-center small rounded-pill border border-secondary">
                                                        <span class="text-light rounded-circle p-1 small bg-secondary">{{$t("common.none")}}</span>
                                                        <span class="flex-grow-1 text-end px-1 px-sm-2">0</span>
                                                    </div>
                                                    <div v-for="(val,j) in countList[item.id]" :key="j" :style="{'border':'1px solid '+getNameAndColor(item.game,j)['color']}" :class="{'me-1 me-sm-2 ':j<countList[item.id].length-1}" class="flex-fill fw-bold d-flex justify-content-around align-items-center small rounded-pill border-start-0">
                                                        <span class="text-light rounded-circle p-1 small" :style="{'background':getNameAndColor(item.game,j)['color']}">{{getNameAndColor(item.game,j)['name']}}</span>
                                                        <span class="flex-grow-1 text-end pe-1 pe-sm-2" :style="{'color':getNameAndColor(item.game,j)['color']}">{{val}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="text-3d fs-3 fw-bold d-grid justify-content-center lh-1 text-dark">
                                            <div class="text-3d-bg">{{item.countdown}}</div>
                                            <div class="text-3d-fg">{{item.countdown}}</div>
                                        </div> -->
                                        <div class="fw-bold fs-2 text-shadow-white lh-1 text-ticket">{{item.countdown}}</div>
                                    </div>
                                    <!-- 路子 -->
                                    <div class="flex-grow-1 position-relative h-0 mt-1">
                                        <div v-if="item.rstate&&item.rstate!=0" class="text-spacing-_1 d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-dark bg-opacity-50 rounded fw-bold fs-4 text-light text-center">
                                            <span>{{$t("roomlist."+ROOM_STATE[item.rstate]) }}</span>
                                        </div>
                                        <div v-if="[GAME_ID.bac,GAME_ID.lh].find(v=>v==item.game)" class="d-flex flex-wrap flex-column h-100">
                                            <div class="col-4 h-100">
                                                <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.big_text" class="bg-light d-block mx-auto min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="6" width="0" height="0"></canvas>
                                            </div>
                                            <div class="col-8 h-50">
                                                <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.big_hollow" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="6" width="0" height="0"></canvas>
                                            </div>
                                            <div class="col-8 h-50 d-flex flex-column">
                                                <div class="d-flex h-50">
                                                    <div class="col-6">
                                                        <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.small_hollow" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="3" width="0" height="0"></canvas>
                                                    </div>
                                                    <div class="col-6">
                                                        <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.small_solid" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="3" width="0" height="0"></canvas>
                                                    </div>
                                                </div>
                                                <div class="d-flex h-50">
                                                    <div class="col-6">
                                                        <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.bevel" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="3" width="0" height="0"></canvas>
                                                    </div>
                                                    <div class="col-6">
                                                        <canvas :data-id="'home_'+item.id" :data-type="PEARL_TYPE.big_three" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="item" data-canvas-home="3" width="0" height="0"></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <canvas v-else :data-id="'home_'+item.id" class="bg-light d-block rounded-3" v-set-size="item" data-canvas-home width="0" height="0"></canvas>
                                        <div v-if="roomInfo.id==item.id" class="position-absolute rounded text-light bg-dark opacity-50 start-50 top-50 translate-middle py-1 px-2">{{$t("roomlist.hear")}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 单项加载 -->
                        <div v-if="isReloadData&&!pearlList.find(v=>v.id==item.id)" class="bg-dark bg-opacity-50 position-absolute top-0 bottom-0 start-0 end-0 rounded-5 d-flex flex-column justify-content-center align-items-center">
                            <div class="spinner-border text-info" role="status"></div>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>

        <!-- 滚动条 -->
        <div class="w-05rem flex-shrink-0 d-flex">
            <div v-if="!isReloadData" v-scroll-load:scrollContent class="w-100 h-100 d-flex rounded-pill scroll-shadow ">
                <div class="w-100 bg-info bg-opacity-75 rounded-pill position-relative progress-bar-striped"></div>
            </div>
        </div>

        <!-- 列表加载层 -->
        <div v-if="isShowLoad" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
            <div class="spinner-border text-info mx-auto" role="status"></div>
            <div class="text-light text-center mt-2">{{$t("common.load")}}</div>
        </div>

    </div>
</template>
<script>
import {GAME_ID,PEARL_TYPE,GAME_ROW, COLOR,ROOM_STATE,POKER_TYPE,MUTATION_TYPE} from "@/assets/js/config.js"
export default {
    inheritAttrs: false,
    emits: ['click-room','on-ready'],
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                let room=binding.value;
                _this.$root.setCanvasSize(elem);
                //画表格
                let hasHead=false;
                if(room.game==GAME_ID.cor||room.game==GAME_ID.dice){
                    hasHead=true;
                }
                let row=elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                let obj = _this.$root.drawCanvasTable(elem,row,hasHead,room.game==GAME_ID.cor?1.5:1);
                if(obj){
                    _this.$store.commit({
                        type:MUTATION_TYPE.setTableColumnList,
                        tableColumnList:{
                            tableId:room.id,
                            column:obj.column
                        }
                    });
                    //台面列数列表中有对应数据说明相同台已经获取过数据，则避免再次获取，否则获取数据
                    if(!(elem.dataset.type!=undefined&&elem.dataset.type!=null&&[PEARL_TYPE.big_hollow,PEARL_TYPE.small_hollow,PEARL_TYPE.small_solid,PEARL_TYPE.bevel,PEARL_TYPE.big_three].indexOf(Number(elem.dataset.type))!=-1)){//如果当前不是bac另外五种路则获取路子
                        _this.$root.getRoomPearl(room.game,room.id);
                    }
                }
            }
        },
        'load':{
            mounted(elem,binding){
                if(binding.value==binding.instance.roomList.length-1){//最后一项载入完毕
                    binding.instance.isShowLoad=false;//隐藏列表加载层
                }
            }
        },
        'scroll-load':{
            mounted(elem,binding) {
                let fn=()=>{
                    let parent=elem.parentNode.parentNode;//被滚动内容的父容器
                    let parentStyle=getComputedStyle(parent);
                    let parentHeight=parentStyle.height.replace("px","");//父容器的高度（可见高度）
                    let child=binding.arg&&document.getElementById(binding.arg);//获取传递过来的被滚动内容的id
                    let childStyle=child&&getComputedStyle(child);
                    let childHeight=childStyle&&childStyle.height.replace("px","");//被滚动内容的高度（实际高度）
                    let height=parentHeight/childHeight*100;
                    let scroll=elem.firstElementChild;//滚动条dom元素
                    scroll.style.height=height>100?100:height+"%";//设置滚动条可滚动高度
                    parent.addEventListener("scroll",(e)=>{
                        let scrollTop=e.target.scrollTop;
                        let top=scrollTop/childHeight*100+"%";
                        scroll.style.top=top;
                    },true);//加参数true表示捕获
                    scroll.onmousedown=(e1)=>{
                        let clickY=e1.pageY;
                        e1.preventDefault();
                        let lastY=clickY;
                        document.onmousemove=(e2)=>{
                            let distance=e2.pageY-lastY;//移动距离=移动到的鼠标位置-点击时的鼠标位置
                            child.parentNode.scrollTop+=distance*2;
                            lastY=e2.pageY;
                        }
                    }
                }
                fn();
                window.onresize=fn;
            },
        }
    },
    props:{
        //台面列表
        roomList:{
            type:Array,
            default(){
                return []
            }
        },
        //路单统计列表
        countList:{
            type:Array,
            default(){
                return []
            }
        },
        //要重绘路单的对象
        pearlObj:{
            id:0,//台面id
            pearl:[]//路单数据
        },
        //头像图片样式
        imgClass:{
            type:Array,
            default(){
                return ['w-7rem','pt-9rem']
            }
        }
    },
    data() {
        return {
            PEARL_TYPE,
            GAME_ID,//游戏ID配置
            ROOM_STATE,//台面状态类型配置
            POKER_TYPE,//开牌模式
            pearlList: [],//路单数据缓存，用于改变视口大小时重绘使用
            isReloadData:true,//当前是否在重新加载台面列表状态
            isShowLoad:true,//是否显示列表加载层
        };
    },
    computed: {
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        userInfo() {
            return this.$store.state.userInfo;
        },
        tableColumnList() {
            return this.$store.state.tableColumnList;
        },
    },
    mounted() {
        this.roomList&&(this.isReloadData=false,this.$emit("on-ready"));//当前已有列表（即不需重新加载列表）则直接设为加载完成
    },
    unmounted() {
        // removeEventListener("resize",this.setResize);
    },
    methods: {
        /**
         * 根据游戏获取应该显示的对应统计列表名称和颜色
         */
        getNameAndColor(game,i){
            let obj={
                name:"",
                color:""
            }
            switch (Number(game)) {
                case GAME_ID.bac:
                    i==0&&(obj.name=this.$t("common.z"),obj.color=COLOR.redLight);
                    i==1&&(obj.name=this.$t("common.x"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.lh:
                    i==0&&(obj.name=this.$t("common.l"),obj.color=COLOR.redLight);
                    i==1&&(obj.name=this.$t("common.hu"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.cor:
                    i==0&&(obj.name=this.$t("common.sum"),obj.color=COLOR.redLight);
                    i!=0&&(obj.name=["Ⅰ","Ⅱ","Ⅲ"][i-1],obj.color=COLOR.redLight);
                    break;
                case GAME_ID.dice:
                    i==0&&(obj.name=this.$t("common.big"),obj.color=COLOR.redLight);
                    i==1&&(obj.name=this.$t("common.small"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.odd"),obj.color=COLOR.blue);
                    i==3&&(obj.name=this.$t("common.even"),obj.color=COLOR.redLight);
                    break;
                case GAME_ID.rou:
                    i==0&&(obj.name=this.$t("common.red"),obj.color=COLOR.redLight);
                    i==1&&(obj.name=this.$t("common.black"),obj.color=COLOR.black);
                    i==2&&(obj.name=this.$t("common.green"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.longfong:
                    i==0&&(obj.name=this.$t("common.l"),obj.color=COLOR.blue);
                    i==1&&(obj.name=this.$t("common.fong"),obj.color=COLOR.redLight);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.coin:
                    i==0&&(obj.name=this.$t("common.even"),obj.color=COLOR.redLight);
                    i==1&&(obj.name=this.$t("common.odd"),obj.color=COLOR.blue);
                    break;
                default:
                    break;
            }
            return obj;
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            for (let i = 0; i < this.pearlList.length; i++) {
                let elem=document.querySelectorAll("[data-id=home_"+this.pearlList[i].id+"]");
                if(elem){//判断元素存在
                    elem.forEach(el=>{
                        this.$root.setCanvasSize(el);
                        (el.dataset.type==PEARL_TYPE.big_text||typeof el.dataset.type=="undefined")&&this.draw(this.pearlList[i].id,el,this.pearlList[i]);
                    })
                }
            }
        },
        /**
         * 绘制画布及内容
         */
        draw(tableId,elem,pearlObj){
            let hasHead=false;//是否有列头
            let room=this.roomList.find(v=>v.id==tableId);
            if(room){
                let gameId=room.game;
                let arr=[];
                if(gameId==GAME_ID.cor||gameId==GAME_ID.dice){
                    hasHead=true;
                }
                let obj = this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(gameId))!=-1),hasHead,gameId==GAME_ID.cor?1.5:1);
                this.$store.commit({
                    type:MUTATION_TYPE.setTableColumnList,
                    tableColumnList:{
                        tableId:tableId,
                        column:obj.column
                    }
                })
                if([GAME_ID.bac,GAME_ID.lh,GAME_ID.rou,GAME_ID.longfong,GAME_ID.coin].indexOf(Number(gameId))!=-1){
                    arr = this.$root.setPearlData(gameId,pearlObj.pearl,obj.column,false);
                }else if(gameId==GAME_ID.cor||gameId==GAME_ID.dice){
                    arr=pearlObj.pearl.slice(-obj.column);
                }
                if(elem.dataset.type==PEARL_TYPE.big_text){//绘制一个的时候把其他也一并绘制
                    let row;
                    this.$root.drawOrderPearl(obj,pearlObj.pearl,PEARL_TYPE.big_text);
                    this.$nextTick(()=>{//防止处理第一个画布时，其他画布还没加载完成就被处理，会导致空白
                        elem = document.querySelector("[data-id='home_"+tableId+"'][data-type='"+PEARL_TYPE.big_three+"']");
                        row = elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                        obj = this.$root.drawCanvasTable(elem,row,hasHead,gameId==GAME_ID.cor?1.5:1);
                        this.$root.drawOrderPearl(obj,pearlObj.pearl,PEARL_TYPE.big_three);

                        elem = document.querySelector("[data-id='home_"+tableId+"'][data-type='"+PEARL_TYPE.big_hollow+"']");
                        row = elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                        obj = this.$root.drawCanvasTable(elem,row,hasHead,gameId==GAME_ID.cor?1.5:1);
                        this.$root.drawPearl(obj,this.$root.setPearlData(this.roomInfo.game,pearlObj.pearl,obj.column-1,false),PEARL_TYPE.big_hollow,GAME_ID.bac);
                        
                        elem = document.querySelector("[data-id='home_"+tableId+"'][data-type='"+PEARL_TYPE.small_hollow+"']");
                        row = elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                        obj = this.$root.drawCanvasTable(elem,row,hasHead,gameId==GAME_ID.cor?1.5:1);
                        this.$root.drawPearl(obj,
                            this.$root.setPearlData(this.roomInfo.game,this.$root.convertToSmallPearl(arr,PEARL_TYPE.small_hollow),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                            PEARL_TYPE.small_hollow,GAME_ID.bac);
    
                        elem = document.querySelector("[data-id='home_"+tableId+"'][data-type='"+PEARL_TYPE.small_solid+"']");
                        row = elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                        obj = this.$root.drawCanvasTable(elem,row,hasHead,gameId==GAME_ID.cor?1.5:1);
                        this.$root.drawPearl(obj,
                            this.$root.setPearlData(this.roomInfo.game,this.$root.convertToSmallPearl(arr,PEARL_TYPE.small_solid),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                            PEARL_TYPE.small_solid,GAME_ID.bac);
    
                        elem = document.querySelector("[data-id='home_"+tableId+"'][data-type='"+PEARL_TYPE.bevel+"']");
                        row = elem.dataset.canvasHome||Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(room.game))!=-1);
                        obj = this.$root.drawCanvasTable(elem,row,hasHead,gameId==GAME_ID.cor?1.5:1);
                        this.$root.drawPearl(obj,
                            this.$root.setPearlData(this.roomInfo.game,this.$root.convertToSmallPearl(arr,PEARL_TYPE.bevel),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                            PEARL_TYPE.bevel,GAME_ID.bac);
                    })
                }else if(typeof elem.dataset.type=="undefined"){
                    this.$root.drawPearl(obj,arr,PEARL_TYPE.big_hollow,gameId);
                }
            }
        },
        /**
         * 进入台面
         */
        goRoom(id,gameId){
            if(!this.isReloadData){//当前非重新加载台面状态时可进入台面
                this.$root.goRoom(id,gameId);
                this.$emit('click-room');
            }
        },
        /**
         * 根据台面获取台面应显示内容
         */
        getTableShow(room){
            if(room.game==GAME_ID.slot){
                return room.room;
            }else if(room.game==GAME_ID.texasauto){
                return "<div class='d-flex flex-column lh-1 py-1 small'>"+
                        "   <div class='mb-1 d-flex justify-content-between'>"+
                        "       <i class='bi bi-people-fill me-1 text-info'></i>"+
                        "       <span class='"+(room.currnum<7?"":"text-warning")+"'>"+room.currnum+"︱"+7+"</span>"+
                        "   </div>"+
                        "   <div class='mb-1 d-flex justify-content-between'>"+
                        "       <i class='bi bi-controller me-1 text-yellow'></i>"+room.antes/2+" / "+room.antes+
                        "   </div>"+
                        "   <div class='d-flex justify-content-between'>"+
                        "       <i class='bi bi-currency-exchange me-1 text-yellow'></i>"+
                        "       <span class='"+(this.userInfo.esportsCoin>Number(room.carry)?"":"text-warning")+"'>"+room.carry+"</span>"+
                        "   </div>"+
                        "</div>"
            }
            return "";
        },
        /**
         * 获取电子台面图片src
         */
        getTableImgSrc(room){
            if(room.room==this.$t("common.treasureSlot")){
                return require("@/assets/images/home/slot_scroll.png");
            }else if(room.room==this.$t("common.fruitSlot")){
                return require("@/assets/images/home/slot_fruit.png");
            }else if(room.game==GAME_ID.texasauto){
                return require("@/assets/images/home/texasauto.png");
            }
        }
    },
    watch: {
        /**
         * 监听到要重绘路单台面id的改变
         */
        pearlObj:{
            handler(newVal){
                //重新绘制指定台面的路单
                if(newVal.id!=0){
                    let elem=document.querySelector("[data-id=home_"+newVal.id+"]");
                    if(elem){//如果找得到元素。避免非当前激活的游戏时报错
                        let obj=this.pearlList.find(v=>v.id==newVal.id);
                        if(obj){//列表中已存在
                            obj.pearl=newVal.pearl;
                        }else{
                            this.pearlList.push(newVal);
                        }
                        this.draw(newVal.id,elem,newVal);
                        if(this.pearlList.length==this.roomList.length&&this.isReloadData){//路单数据与台面数据条数一致，并且是重新加载台面数据，则说明组件已就绪
                            this.isReloadData=false;
                            removeEventListener("resize",this.setResize);
                            addEventListener("resize",this.setResize);
                            this.$emit("on-ready");
                        }
                    }
                }
            },
            deep:true
        },
        /**
         * 监听列表加载完成时注册窗口改变事件
         */
        roomList(val){
            if(!val){//为null时，为了使传null时立即清除列表
                this.isShowLoad=true;
            }else{
                if(val.length>0&&this.$root.hasPearl(val[0].game)){//有列表并且列表为有路列表
                    this.isReloadData=true;//设置台面列表当前状态为加载状态
                }else{
                    this.$nextTick(()=>{
                        this.isShowLoad=false;
                        this.isReloadData=false;
                        this.$emit("on-ready");
                    });
                }
                this.pearlList=[];//清除旧列表的路单数据
            }
        }
    },
};
</script>