<!-- 登录页 -->
<style>
    body{
        background:none;
    }
    .login-bg{
        background: url(@/assets/images/login/login_bg.jpg) no-repeat 30% top;
        background-size: cover;
    }
    .verify-wrap{
        height: 2.373rem;
    }
    .verify-msg{
        display:none;
    }
    .suc-drag-btn>.bi-suit-spade-fill{
        display: none;
    }
    .bi-check-circle-fill{
        display: none;
        color: #7ac23c;
    }
    .suc-drag-btn>.bi-check-circle-fill{
        display: initial;
    }
    .drag-progress,.verify-msg{
        background-color: #7ac23c;
    }
    .language-more{
        -webkit-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -moz-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -o-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -ms-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        opacity: 0;
        transform: translateY(-20px);
    }
    .language-more.active{
        opacity: 1;
        transform: translateY(0px);
    }
    .cursor-move{
        cursor: move;
    }
    .cursor-move:hover .bi-suit-spade-fill{
        color:#fff;
        text-shadow: 0 0 .5rem #fff;
    }
    .text-discolor{
        color: green;
        position: relative;
        /* background: linear-gradient(90deg, #fff 0%, green 100%);
        background-clip: text; */
    }
    .text-discolor::before{
        content: attr(text);
        position: absolute;
        color: #fff;
        mask-image: linear-gradient(90deg, #fff 0%, transparent 100%);
        animation:text-discolor 2s infinite;
    }
    .bi[class^="bi-fullscreen"]::before{
        font-weight: bold !important;
    }
    .border-gradient::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg,transparent 0, #fff 30%,transparent 40%, transparent 60% ,#fff,transparent);
        padding: 0.06rem;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        border-radius: 50rem;
    }
    .btn-login{
        box-shadow: #fff 0 0 .3rem 0 inset;
    }
    .btn-login:hover .bi-suit-spade-fill{
        color: #000 !important;
        text-shadow: 0 0 .15rem #000 !important;
    }
    .btn-login:hover .bi-suit-heart-fill{
        color: #ff0000 !important;
        text-shadow: 0 0 .15rem #ff0000 !important;
    }
    .btn-login:hover .btn-login-inside{
        animation: bound .15s cubic-bezier(0.85,-0.52, 0.12, 1.5) alternate backwards;
        transform: scale(1, 1);
    }
    .btn-login-inside{
        background: linear-gradient(0deg,orange 50%,#fff); box-shadow: inset 0 0 .15rem .1rem #fff;
    }
    .btn-login .bi-suit-spade-fill,.btn-login .bi-suit-heart-fill{
        text-shadow: -0.05rem 0.05rem 0.15rem #ff9b00, .05rem .05rem .15rem #ff9b00;
    }
    .btn-login-inside>span{
        text-shadow: #ff8400 .1rem 0 .1rem, #ff8400 0 .1rem .1rem, #ff8400 -.1rem 0 .1rem, #ff8400 0 -.1rem .1rem;
    }
    @keyframes bound {
        from{
            transform: scale(1.05, .7);
        }to{
            transform: scale(.95, 1.2);
        }
    }
    /* 字体逐一变色特效 */
    @keyframes text-discolor{
        0%{
            mask-image: linear-gradient(90deg, #fff 0%, transparent 100%);
        }
        10%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 10%, transparent 100%);
        }
        20%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 20%, transparent 100%);
        }
        30%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 30%, transparent 100%);
        }
        40%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 40%, transparent 100%);
        }
        50%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 50%, transparent 100%);
        }
        50%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 60%, transparent 100%);
        }
        70%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 70%, transparent 100%);
        }
        80%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 80%, transparent 100%);
        }
        90%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 90%, transparent 100%);
        }
        100%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 100%);
        }
    }
    .cross-light {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        background-color: transparent;
        animation: rotate-scale 2.5s linear both infinite;
    }
    .cross-light::before,
    .cross-light::after {
        content: '';
        position: absolute;
        /* box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8); */
        border-radius: 100%;
    }
    .cross-light::before {
        width: 100%;
        height: .15rem;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: linear-gradient(to right,transparent,#fff,transparent);
    }
    .cross-light::after {
        width: .15rem;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(to bottom,transparent,#fff,transparent);
    }
    @keyframes rotate-scale {
        0%{
            transform: rotate(0deg) scale(0);
        }25%{
            transform: rotate(180deg) scale(1.6);
        }50%{
            transform: rotate(360deg) scale(0);
            display: none;
        }100%{
            transform: rotate(360deg) scale(0);
            display: none;
        }
    }
    .triangle-container{
        overflow: hidden;
    }
    .triangle-container:hover,.triangle-title:hover~.triangle-container{
        overflow: visible;
    }
    .triangle-container:hover .triangle-content>i,.triangle-title:hover~.triangle-container .triangle-content>i{
        --bs-text-opacity:1;
    }
    .triangle-container>.triangle{
        border-left: 2rem solid #0000005e;
        border-top:2rem solid transparent;
        border-bottom: 2rem solid transparent;
    }
    .hover-scale:hover{
        transform: scale(1.05);
        transition: transform .5s cubic-bezier(.25, 1.15, .35, 3.08);
    }
    .triangle-title:hover{
        color: var(--bs-info) !important;
    }
</style>
<template>
    <div class="login-bg d-flex flex-column h-100 justify-content-center position-relative">
        <!-- 功能框 -->
        <div class="position-absolute top-2rem start-2rem index-1">
            <!-- 全屏 -->
            <button class="btn bg-dark bg-opacity-75 text-light mb-2 d-flex align-items-center" type="button" @click="$root.setFullscreen">
                <i :class="[isFullscreen?'bi-fullscreen-exit':'bi-fullscreen']" class="bi d-flex" role="button"></i>
                <span class="ms-1 fw-bold">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
            </button>
            <!-- 语言选择 -->
            <choose-lang></choose-lang>
        </div>
        <!-- 登录框 -->
        <div class="d-flex justify-content-center">
            <div class="w-27rem bg-dark bg-opacity-75 rounded-5 position-relative transform-3d" :class="{'rotate-y-180':isShowBackface}" style="box-shadow: 0 0 .5rem .2rem #000;transition: transform 1s cubic-bezier(0.8, 0, 0.27, 1.31);">
                <!-- 背面 -->
                <div class="position-absolute top-0 end-0 start-0 bottom-0 text-light backface-hidden rotate-y-180 p-3 d-flex index-2 bg-light bg-opacity-75 rounded-5">
                    <div class="rounded-5 flex-grow-1 d-flex flex-column bg-danger bg-opacity-75" style="background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .2) 2rem, transparent 2rem, transparent 4rem), repeating-linear-gradient(135deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .2) .5%, transparent .5%, transparent 5%);">
                        <div @click="isShowBackface=false" class="position-absolute top-0 end-0 text-light border px-1 bg-dark rounded-3 me-4rem mt-4 triangle-title">{{$t('login.webpageLogin')}}</div>
                        <div @click="isShowBackface=false" class="position-absolute end-0 top-0 w-2rem h-4rem triangle-container rotate-z-_45 transform-left-center mt-2 me-2">
                            <div class="w-0 h-0 triangle"></div>
                            <div class="position-absolute top-0 end-0 w-4rem h-4rem d-flex justify-content-center align-items-center triangle-content rotate-z-45">
                                <i class="bi bi-browser-chrome text-light text-opacity-75 fs-3 d-flex"></i>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-grow-1 justify-content-evenly">

                            <!-- 桌面应用下载 -->
                            <div class="d-flex justify-content-center">
                                <div class="d-flex flex-column align-items-center">
                                    <a class="decoration-none" :href="winformUrl">
                                        <div class="bg-dark bg-opacity-50 rounded-5 p-3 mb-1 hover-scale">
                                            <i class="bi bi-windows d-flex fs-3"></i>
                                        </div>
                                    </a>
                                    <span>{{$t('login.winformDownload')}}</span>
                                </div>
                            </div>
                            <!-- app下载 -->
                            <div class="d-flex justify-content-evenly">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <a :href="androidUrl">
                                        <vue-qr class="hover-scale img-thumbnail bg-transparent rounded w-10rem" :logoSrc="require('@/assets/images/login/android.png')" :margin="10" colorDark="#0dcaf0" :text="androidUrl"></vue-qr>
                                    </a>
                                    <div class="fs-5 mt-2">
                                        <a class="btn btn-success rounded-pill" :href="androidUrl">
                                            <i class="bi bi-android2 me-2"></i>
                                            <span>{{$t('login.androidDownload')}}</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <a :href="iosUrl">
                                        <vue-qr class="hover-scale img-thumbnail bg-transparent rounded w-10rem" :logoSrc="require('@/assets/images/login/iphone.png')" :margin="10" colorDark="#0dcaf0" :text="iosUrl"></vue-qr>
                                    </a>
                                    <div class="fs-5 mt-2">
                                        <a class="btn btn-dark rounded-pill" :href="iosUrl">
                                            <i class="bi bi-apple me-2"></i>
                                            <span>{{$t('login.iosDownload')}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center pb-2">
                            <a :href="oldPageUrl">旧版网页端</a>（不推荐）
                        </div>
                    </div>
                </div>
                <!-- 正面 -->
                <form class="p-3 backface-hidden">
                    <div @click="isShowBackface=true" class="position-absolute top-0 end-0 text-light border px-1 bg-dark rounded-3 me-4rem mt-3 triangle-title">{{$t('login.clientDownload')}}</div>
                    <div @click="isShowBackface=true" class="position-absolute end-0 top-0 w-2rem h-4rem triangle-container rotate-z-_45 transform-left-center">
                        <div class="w-0 h-0 triangle"></div>
                        <div class="position-absolute top-0 end-0 w-4rem h-4rem d-flex justify-content-center align-items-center triangle-content rotate-z-45">
                            <i class="bi bi-qr-code text-light text-opacity-75 fs-3 d-flex"></i>
                        </div>
                    </div>
                    <div class="d-flex flex-column text-light">
                        <span class="fw-bold lh-1 fs-1 small">A</span>
                    </div>
                    <div class="d-flex">
                        <div class="position-absolute top-50 start-50 translate-middle index-_1">
                            <i class="bi bi-suit-spade-fill text-light text-opacity-50 fs-8rem"></i>
                        </div>
                        <i class="bi bi-suit-spade-fill fs-3 text-light"></i>
                        <div class="d-flex flex-column flex-grow-1 px-1 py-4 py-sm-3 my-sm-4">
                            <!-- 用户名 -->
                            <div class="mb-3">
                                <div class="input-group input-group-lg bg-dark bg-opacity-50 rounded" style="box-shadow: inset 0 0 .5rem 0 #000;">
                                    <span class="input-group-text border-0 bg-black bg-opacity-50">
                                        <i class="bi bi-person-fill fs-4 lh-1 text-warning"></i>
                                    </span>
                                    <input type="text" class="form-control bg-transparent text-light border-0 shadow-none" :placeholder="$t('login.userName')" v-model="userName">
                                </div>
                            </div>
                            <!-- 密码 -->
                            <div class="mb-3">
                                <div class="input-group input-group-lg bg-dark bg-opacity-50 rounded" style="box-shadow: inset 0 0 .5rem 0 #000;">
                                    <span class="input-group-text border-0 bg-black bg-opacity-50">
                                        <i class="bi bi-lock-fill fs-4 lh-1 text-warning"></i>
                                    </span>
                                    <input type="password" class="form-control bg-transparent text-light border-0 shadow-none" :placeholder="$t('login.pwd')" v-model="pwd" autocomplete="false">
                                </div>
                            </div>
                            <!-- 验证码 -->
                            <div v-if="verify==verifyType.code" class="mb-3 row">
                                <div class="col-8">
                                    <input type="text" class="form-control" id="pwd" :placeholder="$t('login.code')" v-model="txtCode">
                                </div>
                                <div class="col-4 d-flex flex-column">
                                    <canvas id="code" class="d-block bg-light rounded-2 w-100 h-0 flex-grow-1" width="0" height="0" @click="code=drawCode($event.target)"></canvas>
                                </div>
                            </div>
                            <!-- 滑动验证 -->
                            <div v-if="verify==verifyType.slide" class="mb-3 rounded-2">
                                <div id="slideVerify" class="verify-wrap rounded border border-success position-relative"></div>
                            </div>
                            <!-- 游戏模式 -->
                            <div class="mb-2 text-light">
                                <label>{{$t("login.gameModel")}}</label>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="gameModel" :checked="gameModel==GAME_MODEL.normal" @change="changeGameModel(GAME_MODEL.normal)" id="normGame">
                                    <label class="form-check-label" for="normGame">{{$t("login.classics")}}</label>
                                </div>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="gameModel" :checked="gameModel==GAME_MODEL.tel" @change="changeGameModel(GAME_MODEL.tel)" id="telGame">
                                    <label class="form-check-label" for="telGame">{{$t("login.tel")}}</label>
                                </div>
                            </div>
                            <!-- 色调选择 -->
                            <div class="mb-2 text-light">
                                <label>{{$t("login.colorType")}}</label>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="colorType" :checked="skin==SKIN_TYPE.normal" id="normColor" @change="changeSkin(SKIN_TYPE.normal)">
                                    <label class="form-check-label" for="normColor">{{$t("login.light")}}</label>
                                </div>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="colorType" :checked="skin==SKIN_TYPE.darkness" id="darkColor" @change="changeSkin(SKIN_TYPE.darkness)">
                                    <label class="form-check-label" for="darkColor">{{$t("login.dark")}}</label>
                                </div>
                            </div>
                            <!-- 验证方式 -->
                            <div class="mb-2 text-light">
                                <label>{{$t("login.verifyType")}}</label>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="checkType" :value="verifyType.slide" id="checkSlide" v-model="verify" @change="changeVerify">
                                    <label class="form-check-label" for="checkSlide">{{$t("login.slider")}}</label>
                                </div>
                                <div class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" name="checkType" :value="verifyType.code" id="checkCode" v-model="verify" @change="changeVerify">
                                    <label class="form-check-label" for="checkCode">{{$t("login.code")}}</label>
                                </div>
                            </div>
                            <!-- 线路选择 -->
                            <div class="mb-3 text-light">
                                <label>{{$t("login.chooseNetwork")}}</label>
                                <div v-for="(item,key,i) in wsList" :key="(item,key,i)" class="form-check-inline">
                                    <input class="form-check-input me-1" type="radio" :value="item" v-model="ws" name="wsline" :id="'wsLine_'+i">
                                    <label class="form-check-label" :for="'wsLine_'+i">{{key}}</label>
                                </div>
                            </div>
                            <!-- 登录按钮 -->
                            <div class="d-flex mb-3">
                                <div @click="login(LOGIN_MODE.normal)" class="btn-login position-relative me-3 flex-grow-1 p-_375rem d-flex rounded-pill border-gradient">
                                    <div class="btn-login-inside flex-grow-1 rounded-pill d-flex justify-content-evenly align-items-center fs-5">
                                        <i class="bi bi-suit-spade-fill text-light text-opacity-75"></i>
                                        <span class="px-1 fw-bold text-light">{{$t("login.login")}}</span>
                                        <i class="bi bi-suit-heart-fill text-light text-opacity-75"></i>
                                    </div>
                                    <div class="cross-light position-absolute top-0 end-0"></div>
                                </div>
                                <!-- <div class="me-3 flex-grow-1 fw-bold d-flex justify-content-center align-items-center" :style="{background: 'url('+require('@/assets/images/common/btn2.png')+') no-repeat center/100% 100%'}" @click="login(LOGIN_MODE.normal)">{{$t("login.login")}}</div> -->
                                <!-- <button type="button" class="btn btn-warning btn-block btn-lg fw-bold me-3 flex-grow-1" @click="login(LOGIN_MODE.normal)">{{$t("login.login")}}</button> -->
                                <button v-if="isShowTry" type="button" class="btn btn-outline-info btn-block btn-lg fw-bold rounded-pill" @click="login(LOGIN_MODE.try)"><i class="bi bi-controller me-2"></i>{{$t('login.tryGame')}}</button>
                            </div>
                        </div>
                        <div class="d-flex align-items-end">
                            <i class="bi bi-suit-spade-fill fs-3 text-light rotate-x-180"></i>
                        </div>
                    </div>
                    <div class="d-flex flex-column text-light text-end">
                        <span class="fw-bold lh-1 fs-1 small rotate-x-180">A</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import slideVerifyPlug from '@/assets/js/jq-slideVerify.js'
import {COMMAND,RESULT,LAYER_TYPE,IS_DEBUG,USER,LOGIN_MODE,MUTATION_TYPE,GAME_MODEL,SKIN_TYPE,UPDATE_DATE} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,drawCode,getSystem } from '@/assets/js/common.js'
import chooseLang from "@/components/public/ChooseLang.vue";
//使用二维码插件，安装：npm install vue-qr --save，官网：https://github.com/Binaryify/vue-qr#readme
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
    components: { chooseLang,vueQr },
    props:{
        quickUserInfo:{
            default:[]
        },//快捷登录用户信息
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            USER,
            LOGIN_MODE,
            GAME_MODEL,
            SKIN_TYPE,
            verifyType:{//验证类型
                "slide":0,//滑块验证
                "code":1,//验证码验证
            },
            userName: this.quickUserInfo[0]||"",//用户名
            pwd:this.quickUserInfo[1]||"",//密码
            verify:0,//验证方式(默认为滑动验证方式)
            slideVerify:null,//滑动验证实例对象
            txtCode:"",//用户输入的验证码
            code:"",//生成的验证码
            wsList:window[USER].ws,//config中配置的ws列表
            ws:"",//用户选中的ws线路地址
            isFullScreen:false,//是否全屏
            isShowTry:window[USER].isShowTry,//是否显示免费试用按钮
            lastLoginTime:0,//最后登录时间
            isShowBackface:false,//是否显示背面
            androidUrl:window[USER].androidUrl,//安卓下载地址
            iosUrl:window[USER].iosUrl,//ios下载地址
            winformUrl:window[USER].winformUrl,//桌面程序下载地址
            oldPageUrl:window[USER].oldPageUrl,//旧版网页入口地址
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        gameModel(){
            return this.$store.state.gameModel;
        },
        skin(){
            return this.$store.state.skin;
        },
        loginModel(){
            return this.$store.state.loginModel;
        },
        isReConnWs(){
            return this.$store.state.isReConnWs;
        }
    },
    created() {
        //设置默认选中第一个ws协议
        for (let key in this.wsList) {
            this.ws=this.wsList[key];
            break;
        }
    },
    mounted() {
        //如果访问登录页面的时候有guid，说明可能是点击浏览器的返回,需要先注销登录，否则可能被推送账号多方登录
        (this.userInfo.guid!="")&&this.$emit('ws-send', COMMAND.all_logout, this.userInfo.id, this.userInfo.guid);
        //绑定回车事件
        document.onkeyup=e=>(e.keyCode==13&&this.login(LOGIN_MODE.normal));
        //初始化滑动验证
        this.initSlideVerify();
        //填充测试账号
        (IS_DEBUG&&!this.userName&&!this.pwd)&&this.fillDebugUser();
        console.log("update date:"+UPDATE_DATE);//更新时间
        let lineLay;//网络状态提示layer对象
        addEventListener("offline",()=>{
            if(this.$root.ws){//已有ws对象，直接关闭ws触发ws关闭事件
                this.$root.ws.close();
            }else{//没有ws对象，还没登录就断网，只需要提示
                lineLay = useLayer({str:this.$t("common.networkErr"),type:LAYER_TYPE.confirm,btn:[this.$t("common.ok")],yesFn:(i)=>{
                    layer.close(i);
                }});
            }
        })
        addEventListener("online",()=>{
            if(!this.$root.ws){//没有ws对象，还没登录网络恢复了，关闭断网的提示并提示网络好了
                layer.close(lineLay);
                useLayer({str:this.$t("common.networkConn")});
            }
        })
    },
    unmounted() {
        document.onkeyup=null;//清空回车事件，释放内存并防止在别的页面仍能触发
    },
    methods:{
        drawCode,
        /**
         * 初始化滑动验证
         */
        initSlideVerify(){
            this.slideVerify = new slideVerifyPlug('slideVerify', {
                initText:this.$t("login.slideTip"),
                sucessText:this.$t("login.slideSuccessTip")
            });
        },
        /**
         * 登录
         * @param {LOGIN_MODE} type 登录方式
         */
        login(type){
            //高频验证
            let nowTime=new Date().getTime();
            if(nowTime-this.lastLoginTime<2000){
                useLayer({str:this.$t("login.loginFast")});
                return;
            }
            this.lastLoginTime=nowTime;
            //登录验证
            if(type==LOGIN_MODE.normal){//正常登录
                let reg = /[^a-z|A-Z|0-9|_]/g;//数字字母下划线
                if(this.userName==""){
                    useLayer({str:this.$t("login.userEmpty")});
                    return;
                }else if(reg.test(this.userName)){
                    useLayer({str:this.$t("login.userErr")});
                    return;
                }else if(this.pwd==""){
                    useLayer({str:this.$t("login.pwdEmpty")});
                    return;
                }
            }
            if (this.verify==this.verifyType.slide){//滑动验证
                if(this.slideVerify.slideFinishState == false){//验证没有通过
                    useLayer({str:this.$t("login.slideVerify")});
                    return;
                }
            }else if(this.verify==this.verifyType.code){//验证码验证
                if(this.txtCode!=this.code){
                    useLayer({str:this.$t("login.codeVerify")});
                    return;
                }
            }
            //登录
            let fn=()=>{
                useLayer({
                    type:LAYER_TYPE.load,
                    str:this.$t("login.logining")
                })
                let ws=new WebSocket(this.ws);
                this.$root.ws=ws;
                ws.onopen=()=> {
                    IS_DEBUG&&console.log("连接建立");
                    if(type==LOGIN_MODE.try){//试玩登录要先获取试玩账号
                        this.$emit("ws-send",COMMAND.all_gettryuser,window[USER].tryBelong);
                    }else{//非试玩
                        let command=this.gameModel==GAME_MODEL.normal?COMMAND.all_login:(this.gameModel==GAME_MODEL.tel?COMMAND.tel_login:null);//接口名称
                        this.$store.commit({
                            type:MUTATION_TYPE.setLoginModel,
                            loginModel:type,
                        })
                        this.$emit("ws-send",command, this.userName, this.pwd, getDeviceType(), this.$root.ip.cip,window[USER].tryBelong,type);
                    }
                }
                ws.onclose=(e)=>{
                    console.log(e)
                    // if (e.code == 1006) {//用于期望收到状态码时连接非正常关闭 (也就是说, 没有发送关闭帧).
                    // }
                    if (this.$route.name=="login") {//在登录页面
                        layer.closeAll();
                        useLayer({str:this.$t("login.wsError")});
                        this.$root.ws=null;
                    }else{
                        let count=1;
                        this.reConnSocket(count);
                        this.$store.commit({
                            type:MUTATION_TYPE.setCurrVideoLine,
                            currVideoLine:""
                        })
                    }
                }
                this.$root.onMessage();
                this.$emit("ws-msg",(data)=>{
                    switch (data.command) {
                        case COMMAND.all_login:
                        case COMMAND.tel_login:
                            let result=data.result;//登录结果
                            if (result==RESULT.login.success) {
                                let oldGuid=this.userInfo.guid;
                                this.$root.wsSend(COMMAND.all_online, this.userName, data.guid);
                                // this.$emit("ws-send",COMMAND.all_online, this.userName, data.guid);
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        id:this.userName,
                                        guid:data.guid
                                    }
                                })
                                !oldGuid&&this.$root.goPage("home");
                            }else{
                                if(result!=""&&(Number(result)==RESULT.login.unUser||Number(result)==RESULT.login.unPwd)){
                                    useLayer({str:this.$t("login.loginErr")});
                                }else{
                                    useLayer({str:this.$t("login.loginFail")});
                                }
                                this.$store.commit({
                                    type:MUTATION_TYPE.setLoginModel,
                                    loginModel:null,
                                })
                            } 
                            break;
                        case COMMAND.all_gettryuser:
                            if(data.result==RESULT.getTryUser.success){
                                this.userName=data.userid;
                                this.pwd=data.pwd;
                                this.$store.commit({
                                    type:MUTATION_TYPE.setLoginModel,
                                    loginModel:LOGIN_MODE.try
                                })
                                this.$emit("ws-send",COMMAND.all_login, data.userid, data.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",LOGIN_MODE.try);
                            }else if(data.result==RESULT.getTryUser.empty){
                                useLayer({str:this.$t("login.unTryUser")});
                            }
                            break;
                    }
                })
            }
            if(this.gameModel==GAME_MODEL.tel){//有游戏模式，即为电话
                if(this.userName&&(this.userName.length<6||this.userName.length>8||!/^\d+$/.test(this.userName))){//用户名小于6位或者大于8位
                    useLayer({type:LAYER_TYPE.confirm,str:this.$t("login.noTelUser")+this.$t("login.isTelUser"),btn:[this.$t("common.yes"),this.$t("common.no")], yesFn(){fn();}});
                    return;
                }
            }
            fn();
        },
        /**
         * socket重连
         */
        reConnSocket(count){
            if(this.$store.state.isReConnWs){
                useLayer({str:this.$t("common.networkReConn")+"("+count+"/30)",type:LAYER_TYPE.load});
                let ws=new WebSocket(this.ws);
                this.$root.ws=ws;
                ws.onopen=()=>{
                    count=0;
                    useLayer({str:this.$t("common.serverConn")});
                    if(this.loginModel==LOGIN_MODE.try){//试玩登录要先获取试玩账号
                        this.$emit("ws-send",COMMAND.all_gettryuser,window[USER].tryBelong);
                    }else{//非试玩
                        let command=this.gameModel==GAME_MODEL.normal?COMMAND.all_login:(this.gameModel==GAME_MODEL.tel?COMMAND.tel_login:null);//接口名称
                        this.$root.wsSend(command, this.userName, this.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",this.loginModel);
                        // this.$emit("ws-send",command, this.userName, this.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",this.loginModel);
                    }
                }
                ws.onclose=(e)=>{
                    if(count>30){
                        useLayer({str:this.$t("common.serverReConnErr"),type:LAYER_TYPE.confirm,btn:this.$t("common.ok"),yesFn:(i)=>{
                            layer.close(i);
                        }});
                    }else{
                        setTimeout(() => {
                            this.reConnSocket(++count);
                        }, 3000);
                    }
                }
                ws.onerror=()=>{
                }
                this.$root.onMessage();
            }
        },
        /**
         * 切换验证方式
         */
        changeVerify(){
            if(this.verify==this.verifyType.code){//验证码验证
                this.code=drawCode(document.getElementById("code"));
            }else if(this.verify==this.verifyType.slide){//滑动验证
                this.initSlideVerify();
            }
        },
        /**
         * 改变游戏模式
         */
        changeGameModel(model){
            this.$store.commit({
                type:MUTATION_TYPE.setGameModel,
                gameModel:model
            })
        },
        /**
         * 换肤
         */
        changeSkin(skin){
            this.$store.commit({
                type:MUTATION_TYPE.setSkin,
                skin:skin
            })
        },
        /**
         * 测试模式自动填充用户
         */
        fillDebugUser(){
            this.userName="a11";
            if(getDeviceType()=="h5_phone_4"){
                getSystem()=="ios"&&(this.userName="a12");
                getSystem()=="android"&&(this.userName="a13");
            }
            this.pwd="000000";
            this.slideVerify.slideFinishState=true;
        }
    },
    watch: {
        /**
         * 监听游戏模式变化
         */
        gameModel(val) {
            if(IS_DEBUG){
                if(val){//电话模式
                    this.userName="888888";
                    this.pwd="888888";
                }else{
                    this.fillDebugUser();
                }
            }
            if(val){//电话模式
                if(this.userName&&(this.userName.length<6||this.userName.length>8||!/^\d+$/.test(this.userName))){
                    useLayer({str:this.$t("login.noTelUser")});
                }
            }
        },
        /**
         * 监听快速登录信息变化
         */
        quickUserInfo:{
            handler(val){
                if(val){
                    val[0]&&(this.userName=val[0]);
                    val[1]&&(this.pwd=val[1]);
                    if(this.userName&&this.pwd){
                        this.$nextTick(()=>{
                            this.slideVerify.slideFinishState=true;
                            this.login(LOGIN_MODE.quick);
                        })
                    }
                }
            },
            immediate:true
        },
        /**
         * 监听语言改变
         */
        "$i18n.locale"(){
            this.verify==this.verifyType.slide&&this.initSlideVerify();//重置滑动避免语言不改变
        }
    },
}
</script>