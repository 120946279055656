<!-- 切换额度-->
<template>
    <div @click.stop v-if="isShowMoreAmount" class="position-absolute h-100 w-100 bg-dark d-flex flex-sm-column bg-opacity-75 flex-wrap justify-content-between align-items-center text-black fs-6 p-3 p-sm-3 index-1">
        <div v-for="i in AMOUTN_ARR" :key="i" @click="updateAmount(i)" :class="{'active':beAmountList.indexOf(i)!=-1}" class="position-relative ms-3 me-3 ms-sm-0 me-sm-1 chips rounded-circle" role="button">
            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+i+'.png')" alt="">
            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(i)}}</span>
        </div>
        <div class="position-absolute end-0 top-0">
            <i @click="isShowMoreAmount=false" class="d-flex bi bi-x-circle-fill text-light fs-5" role="button"></i>
        </div>
        <div class="position-absolute bottom-0 start-50 translate-middle-x-end-y bg-dark bg-opacity-75 px-2 py-1 rounded-bottom index-1">
            <button type="button" class="btn btn-success btn-sm me-2" @click="saveAmount">{{$t("room.confirm")}}</button>
            <button type="button" class="btn btn-secondary btn-sm" @click="isShowMoreAmount=false">{{$t("room.cancel")}}</button>
        </div>
    </div>
</template>
<script>
import { MUTATION_TYPE,AMOUTN_ARR } from '@/assets/js/config';
import { useLayer } from '@/assets/js/useLayer';
export default {
    props: {
        //是否显示
        isShow:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            AMOUTN_ARR,
            isShowMoreAmount:false,//是否显示更多额度
            beAmountList:[],//选中未保存的额度列表
        };
    },
    created() {
        this.isShowMoreAmount=this.isShow;
    },
    methods: {
        /**
         * 保存常用额度
         */
        saveAmount(){
            if(this.beAmountList.length<5){
                useLayer({str:this.$t("room.chipsLack")});
                return;
            }
            this.beAmountList.sort((a,b)=>a-b);
            this.$store.commit({
                type:MUTATION_TYPE.setUserAmountList,
                userAmountList:[this.beAmountList.slice(0,2),this.beAmountList.slice(2,4),this.beAmountList.slice(-1)]
            })
            this.$store.commit({
                type:MUTATION_TYPE.setCurrAmountList,
                currAmountList:[this.beAmountList.slice(0,2),this.beAmountList.slice(2,4),this.beAmountList.slice(-1)]
            })
            useLayer({str:this.$t("personal.updateSuccess")});
            this.isShowMoreAmount=false;
        },
        /**
         * 选中/取消选中常用额度
         */
        updateAmount(amount){
            let index=this.beAmountList.indexOf(amount);//获取当前额度在选中列表中的位置
            if(index!=-1){//当前点击额度已选中
                this.beAmountList.splice(index,1);//取消选中
            }else{//未选中
                if(this.beAmountList.length==5){//个数超出则提示
                    useLayer({str:this.$t("room.chipsFull")});
                    return;
                }
                this.beAmountList.push(amount);//加入点击项
            }
        },
    },
    watch: {
        /**
         * 监听外部显示开关
         */
        isShow() {
            this.isShowMoreAmount=!this.isShowMoreAmount;
        },
        /**
         * 监听内部显示开关
         */
        isShowMoreAmount(){
            document.onclick=(e)=>{
                e.stopPropagation();
                this.isShowMoreAmount=false;
            }
        }
    },
};
</script>