<style scoped>
    .chips-img-shadow>img{
        box-shadow: 0px 3px 2px 0px #000;
    }

    .swiper{
        --swiper-navigation-color: #fff;/* 单独设置按钮颜色 */
        --swiper-navigation-size: 1.8rem;/* 设置按钮大小 */
    }
    .text-red{
        color:#c90013;
    }
    .amount.active{
        animation: amount .5s ease infinite alternate;
    }
    .hover-bg-opacity-50:hover{
        --bs-bg-opacity: 0.5;
    }
    #fullPearl{
        transition: left .5s ease;
    }
    @keyframes amount {
        0% {
            box-shadow: rgb(249,252,0) 0 0 .2rem .2rem;
        }
        100% {
            box-shadow: rgba(249,252,255) 0 0 .2rem .2rem;
        }
    }
    .coruscate{
        animation: coruscate .5s ease infinite alternate;
    }
	@keyframes coruscate {
        0% {
            background: rgba(25,135,84,1);
        }
        100% {
            background: rgba(13,202,240);
        }
    }
    .scale-2{
        animation:scale2 .12s ease-out;
    }
    @keyframes scale2 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.5);
        }
    }
</style>
<template>
    <div class="d-flex h-100 flex-column position-relative">
        <!-- 传统 -->
        <div class="d-flex flex-column flex-sm-row">
            <!-- 视频区 -->
            <div class="col-12 flex-grow-1 position-relative flex-sm-grow-0 col-sm-7">
                <media-video :video-list="videoList" :countdown="countdown" :room-class="['top-xs-0 top-sm-0 m-2']" :time-class="['top-0 m-2']" :img-class="['position-absolute translate-middle top-50 start-50']"></media-video>
                <!-- 牌区 -->
                <!-- <div class="position-absolute top-26 ms-2">
                    <div v-for="(item,i) in showPokerArr" :key="item" class="d-flex rounded h-4_5rem">
                        <div class="text-white text-vertical text-center fw-bold border rounded h-100" :style="{'background':(i==0?color.red:color.blue)}">{{currentGame==gameIdList.bac?i==0?$t("common.z"):$t("common.x"):i==0?$t("common.l"):$t("common.hu")}}</div>
                        <div class="img-thumbnail w-3_5rem" :style="{'background':(i==0?color.red:color.blue)}" v-for="(val,j) in item" :key="val">
                            <img v-if="val" class="img-fluid w-100 h-100 d-block" :class="{'rotate':(i+1)==currPokerXY[0]&&(j+1)==currPokerXY[1]}" :src="require('@/assets/images/common/poker_'+val+'.png')" alt="">
                        </div>
                    </div>
                </div> -->
                <!-- 牌区 -->
                <div class="position-absolute top-26 ms-2">
                    <div v-for="(item,i) in showPokerArr" :key="item" class="d-flex rounded h-xs-3rem h-sm-4_5rem">
                        <div class="text-white text-vertical text-center fw-bold border rounded h-100" :style="{'background':(i==0?color.red:color.blue)}">{{currentGame==gameIdList.bac?i==0?$t("common.z"):$t("common.x"):i==0?$t("common.l"):$t("common.hu")}}</div>
                        <div class="img-thumbnail" :style="{'background':(i==0?color.red:color.blue)}" v-for="(val,j) in item" :key="val">
                            <div v-if="val" :class="[$root.getPokerByNum(val)['color'],((i+1)==currPokerXY[0]&&(j+1)==currPokerXY[1])?'rotate':'']" class="d-flex flex-column bg-light rounded p-sm-1 h-100">
                                <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(val)["name"] }}</span>
                                <div class="d-flex flex-grow-1 px-2 pb-2">
                                    <i :class="[$root.getPokerByNum(val)['shape']]" class="bi d-flex align-items-center justify-content-center fs-4 flex-grow-1"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 路单统计区 -->
        <div class="d-flex flex-wrap justify-content-between text-white bg-secondary pe-2 py-xl-1 small-xs">
            <div class="ps-2">{{$root.getTextByGame("common.",currentGame,0)}}：{{pearlCount.z}}</div>
            <div class="ps-2">{{$root.getTextByGame("common.",currentGame,1)}}：{{pearlCount.x}}</div>
            <div class="ps-2">{{$t("common.h")}}：{{pearlCount.h}}</div>
            <div class="ps-2" v-if="currentGame==gameIdList.bac">{{$t("common.zd")}}：{{pearlCount.zd}}</div>
            <div class="ps-2" v-if="currentGame==gameIdList.bac">{{$t("common.xd")}}：{{pearlCount.xd}}</div>
        </div>
        <div class="d-flex bg-secondary text-white pe-2">
            <div class="ps-2 col-6 col-sm-auto d-flex">
                <span>{{$root.getTextByGame("room.next",currentGame,0)}}：</span>
                <label class="d-flex align-items-center text-transparent">
                    <span class="rounded-circle w-1rem h-1rem border-2 border-solid me-2" :style="nextZHollowStyle"></span>
                    <span class="h-1rem border-1 border-top-0 border-left-0 border-bottom-0 rotate-30 border-solid me-2" :style="nextZBevelStyle"></span>
                    <span class="rounded-circle w-1rem h-1rem border-3" :style="nextZSolidStyle"></span>
                </label>
            </div>
            <div class="ps-2 col-6 col-sm-auto d-flex justify-content-end">
                <span>{{$root.getTextByGame("room.next",currentGame,1)}}：</span>
                <label class="d-flex align-items-center text-transparent">
                    <span class="rounded-circle w-1rem h-1rem border-2 border-solid me-2" :style="nextXHollowStyle"></span>
                    <span class="h-1rem border-1 border-top-0 border-left-0 border-bottom-0 rotate-30 border-solid me-2" :style="nextXBevelStyle"></span>
                    <span class="rounded-circle w-1rem h-1rem border-3" :style="nextXSolidStyle"></span>
                </label>
            </div>
        </div>
        <!-- 路单区 -->
        <div class="flex-grow-1 d-flex h-0 position-relative" :class="[roomInfo.isAr==1?'flex-sm-grow-0 h-sm-8rem':'overflow-hidden']">
            <!-- 问路 -->
            <div class="d-flex flex-column text-white text-center w-2rem text-nowrap">
                <div @click="nextPrediction(currentGame==gameIdList.bac?'A':'M')" :style="'background:'+color.red" class="d-flex justify-content-center align-items-center col border text-vertical small-xs small-md" role="button">{{$root.getTextByGame("room.will",currentGame,0)}}</div>
                <div @click="nextPrediction(currentGame==gameIdList.bac?'B':'N')" :style="'background:'+color.blue" class="d-flex justify-content-center align-items-center col border text-vertical small-xs small-md" role="button">{{$root.getTextByGame("room.will",currentGame,1)}}</div>
            </div>
            <!-- 路单 -->
            <div class="flex-grow-1 d-flex flex-column">
                <div class="position-relative h-25">
                    <canvas id="textBigPearl" class="bg-white d-block mx-auto min-w-100 min-h-100 mw-100 mh-100" v-set-size="6" data-canvas-room="6" width="0" height="0" @mousemove.stop="showPoker($event,1)"></canvas>
                    <div class="bg-dark bg-opacity-25 position-absolute" :style="pearlLayerStyle" @mouseout.stop="showPoker($event,0)"></div>
                </div>
                <div class="position-relative h-25">
                    <canvas id="bigPearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="6" data-canvas-room="6" width="0" height="0"></canvas>
                </div>
                <div class="d-flex flex-column h-50">
                    <div class="d-flex flex-column h-50">
                        <div class="col-12 h-50 position-relative">
                            <canvas id="smallHollowPearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                        </div>
                        <div class="col-12 h-50 position-relative">
                            <canvas id="smallSolidPearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                        </div>
                    </div>
                    <div class="d-flex flex-column h-50">
                        <div class="col-12 h-50 position-relative">
                            <canvas id="bevelPearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                        </div>
                        <div class="col-12 h-50 position-relative">
                            <canvas id="threePearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 音效 -->
        <audio-player v-show="false" ref="resultAudioPlayer" :isLoop="false" :isAutoPlayNext="false" :muted="!audio.resultAudio" :audio-list="resultAudioList.map(elm => elm.url)" />
    </div>
</template>
<script>
import {COMMAND,COLOR, GAME_ID,PEARL_TYPE,PEARL_CODE,REBATE_TYPE,IS_DEBUG,LAYER_TYPE,SUBMIT_RESULT,CANCEL_RESULT, IS_REBATE,RESULT,RATIO,AMOUTN_ARR,MUTATION_TYPE} from "@/assets/js/config.js"
import mediaVideo from "@/components/public/video.vue";
import { useLayer } from '@/assets/js/useLayer';
export default {
    components: { 
        mediaVideo,
    },
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                //设置画布大小
                _this.$root.setCanvasSize(elem);
                //绘制画布表格
                _this.$root.drawCanvasTable(elem,binding.value,false);
            }
        }
    },
    props: {
        audio:{
            type:Object,
            default:{
                bgAudio:false,//背景音效开关
                startAudio:true,//开始/结束音效开关
                resultAudio:true,//开注音效
                tenAudio:true,//最后10秒音效
            }
        },
        str:{
            type:String,
            default:""
        },//路由参数
    },
    data() {
        return {
            ws:null,
            //获取配置
            pearlType:PEARL_TYPE,//获取路单类型配置
            color:COLOR,//获取颜色配置
            gameIdList:GAME_ID,//获取游戏id列表配置
            rebate:REBATE_TYPE,//获取佣金方式配置
            pearlCount:{z:0,x:0,h:0,zd:0,xd:0},//统计
            pearlArr:[],//台面路单列表
            virtualPearlArr:[],//台面问路路单列表
            currentGame:0,//当前游戏
            roomInfo:{
                id:"",
                room:"",
                table:"",
                boot:"",
                innings:"",
                second:0,
            },//台面信息
            nextZHollowStyle:"",//下局预测Z空心样式
            nextZBevelStyle:"",//下局预测Z斜线样式
            nextZSolidStyle:"",//下局预测Z实心样式
            nextXHollowStyle:"",//下局预测X空心样式
            nextXBevelStyle:"",//下局预测X斜线样式
            nextXSolidStyle:"",//下局预测X实心样式
            countdown:0,//倒计时
            countdownTimer:null,//倒计时计时器
            countList:[],//路单统计列表
            isShowNextPrediction:false,//当前是否显示着问路
            resultAudioList:[
                {
                    url:require('@/assets/media/baccarat/win_draw.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_h.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_l.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_x.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_z.mp3')
                }
            ],//开结果音效
            pokerArr:[],//当前台面牌组
            showPokerArr:[],//要显示的牌组
            currPokerXY:[0,0],//当前要翻转的扑克
            pearlLayerStyle:{
                width:0,
                height:0,
                left:0,
                top:0,
                display:'none'
            },//鼠标移上路单的遮罩层
            point:[0,0],//鼠标在路单上的坐标点
            videoList:[],//视频线路地址，当前支持最多两个
            isShowPoker:true,//鼠标移入画布是否显示牌
            isModuleReady:false,//页面内组件是否加载完成
            userInfo:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:0,//真人币
				esportsCoin:0//竞技币
            },
            currVideoLine:"",//当前视频线路id
        };
    },
    mounted() {
        this.$root.isShowTeach=false;
        let lay = useLayer({str:this.$t("common.serverConn")});
        this.$root.ws=new WebSocket(window.longyuan.shareWs);
        this.$root.ws.onopen= ()=> {
            IS_DEBUG&&console.log("连接建立");
            layer.close(lay);
            this.$emit("ws-send",COMMAND.all_setsharepower,this.str);
        }
        this.$root.ws.onclose=(e)=>{
            if (e.code == 1006) {//用于期望收到状态码时连接非正常关闭 (也就是说, 没有发送关闭帧).
                layer.close(lay);
                useLayer({
                    type:LAYER_TYPE.confirm,
                    str:"服务器连接失败！",
                    btn:[this.$t("common.ok")],
                    yesFn:(i)=>{
                        layer.close(i);
                        this.$router.go(0);
                    }
                })
            }
            this.$root.ws=null;
            this.currVideoLine="";
        }
        this.$root.onMessage();
        this.setWsMessage();
        addEventListener("resize",this.setResize);
    },
    unmounted() {
        this.$root.ws.close();
    },
    methods: {
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",(data)=>{
                switch (data.command) {
                    case COMMAND.all_setsharepower:
                        if(data.result=="1"){
                            this.roomInfo.id=data.tableid;
                            this.roomInfo.room=data.room;
                            this.roomInfo.table=data.table;
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                            this.roomInfo.second=data.second;
                            this.roomInfo.isAr=data.isar;
                            this.userInfo.id=data.userid;
                            this.userInfo.guid=data.guid;
                            this.currentGame=data.game;
                            this.currVideoLine=data.line;
                            this.$store.commit({
                                type:MUTATION_TYPE.setUserInfo,
                                userInfo:{
                                    id:data.userid,
                                    guid:data.guid
                                }
                            })
                            //
                            this.$emit("ws-send",COMMAND.all_online, this.userInfo.id, this.userInfo.guid);
                            //获取倒计时
                            this.$emit("ws-send",COMMAND.all_getcountdown,this.currentGame,this.roomInfo.id,this.userInfo.id,this.userInfo.guid);
                            //获取台面路单
                            this.$emit("ws-send",COMMAND.all_getroompearl, this.currentGame,this.roomInfo.id,200,"table", this.userInfo.id, this.userInfo.guid);
                            //获取台面扑克
                            this.$emit("ws-send",COMMAND.bac_getroompoker, this.roomInfo.id,this.roomInfo.boot,200,this.userInfo.id, this.userInfo.guid);
                        }else{
                            useLayer({
                                type:LAYER_TYPE.confirm,
                                str:this.$t("common.unData"),
                                btn:[this.$t("common.ok")],
                                yesFn:(i)=>{
                                    layer.close(i);
                                    this.$router.go(0);
                                }
                            })
                        }
                        break;
                    case COMMAND.all_getline:
                        this.$store.commit({
                            type:MUTATION_TYPE.setVideoLineList,
                            videoLineList:data.list
                        })
                        //获取台面视频
                        //this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.id,this.userInfo.guid);
                        break;
                    case COMMAND.all_getroompearl:
                        if(data.id==this.roomInfo.id){//获取的是当前台面的数据并且非多台模式
                            this.pearlArr=data.pearl;
                        }
                        this.countList[data.id]=data.count;
                        break;
                    case COMMAND.all_getvideo:
                        let videoArr=data.video;
                        let list=[];//拼装好的视频地址数组
                        for (let i = 0; i < videoArr.length; i++) {
                            list.push(data.flvline+'/'+videoArr[i]+'.flv');
                        }
                        this.videoList=list;
                        break;
                    case COMMAND.all_getcountdown:
                        this.countdown=data.countdown;
                        break;
                    case COMMAND.all_startgame:
                        if(data.id==this.roomInfo.id){
                            this.countdown=data.countdown;
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                            this.$root.$refs.startAudioPlayer.playNext();
                        }
                        break;
                    case COMMAND.all_endgame:
                        if(data.id==this.roomInfo.id){
                            this.countdown=0;
                            this.$root.$refs.endAudioPlayer.playNext();
                        }
                        break;
                    case COMMAND.bac_gameresult:
                        if(data.id==this.roomInfo.id){
                            this.currPokerXY=[0,0];
                            this.updatePearl(this.pearlArr,data.pearl);
                            if(data.zpoker.length>0&&data.xpoker.length>0){//有牌
                                this.showPokerArr=[data.zpoker,data.xpoker];
                                this.pokerArr.push(this.showPokerArr);
                                setTimeout(() => {
                                    this.showPokerArr=[];
                                }, 5000);
                            }
                        }
                        break;
                    case COMMAND.all_getcurrpoker:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=false;
                            this.showPokerArr=data.poker;
                            this.currPokerXY=data.index;
                        }
                        break;
                    case COMMAND.all_settlement:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=true;
                        }
                        break;
                    case COMMAND.all_changeboot:
                        if(data.id==this.roomInfo.id){
                            this.pearlArr=[];
                            this.pokerArr=[];
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                        }
                        break;
                    case COMMAND.bac_getroompoker:
                        let arr=data.list;
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].zp.length>0&&arr[i].xp.length>0&&this.pokerArr.push([arr[i].zp,arr[i].xp]);
                        }
                        break;
                    case COMMAND.all_refresh:
                        data.id==this.roomInfo.id&&this.$emit("ws-send",COMMAND.all_getroompearl, data.game,data.id,200,"table", this.userInfo.id, this.userInfo.guid);
                        break;
                    case COMMAND.all_getout:
                    case COMMAND.all_otherlogin:
                    case COMMAND.all_logintop:
                    case COMMAND.all_logout:
                        this.currVideoLine="";
                        layer.closeAll();
                        useLayer({
                            type:LAYER_TYPE.confirm,
                            str:this.$t("common.pageInvalid"),
                            btn:[this.$t("common.ok")],
                            yesFn:(i)=>{
                                layer.close(i);
                            }
                        });
                        break;
                }
            })
        },
        /**
         * 绘制顺序路
         * @param {Object} elemObj 使用drawCanvasTable绘制画布表格返回的对象
         * @param {Array} pearlArr 路单数据
         * @param {PEARL_TYPE} type 路单类型
         */
        drawOrderPearl(elemObj,pearlArr,type){
            let arr=JSON.parse(JSON.stringify(pearlArr));//深克隆一份数组防止操作到原数组
            let ctx = elemObj.elem.getContext('2d');
            let radius=2.6;//半径比例
            let cellHeight = elemObj.cellHeight;
            let cellWidth = elemObj.cellWidth;
            let xCode=PEARL_CODE.X;
            let zCode=PEARL_CODE.Z;
            ctx.lineWidth = cellWidth * .12;//边框粗细
            ctx.textBaseline = 'middle';
            if(type==PEARL_TYPE.big_three){
                arr=arr.flat(Infinity).filter((val)=>{
                    return PEARL_CODE.H.indexOf(val.substring(0,1))==-1;//转一维数组后去掉和
                })
                arr=arr.slice(-(elemObj.column - 1) * 3 - arr.length % 3);//取倒数的数据
                for (var i = 0; i < arr.length; i++) {
                    //设置圆圈颜色
                    var letter = arr[i].substr(0, 1);//获取首字母
                    if (xCode.indexOf(letter)!=-1) {
                        ctx.fillStyle = COLOR.blue;//填充颜色
                        ctx.strokeStyle = COLOR.blue;
                    } else if (zCode.indexOf(letter)!=-1) {
                        ctx.fillStyle = COLOR.red;
                        ctx.strokeStyle = COLOR.red
                    }
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2, (i % 3) * cellHeight + cellHeight / 2, cellWidth / radius, 0, 2 * Math.PI);
                    ctx.stroke();
                }
            }else if(type==PEARL_TYPE.big_text){
                var text = '';//填充文本
                var countArr = [0, 0, 0, 0, 0];//庄，闲，和，庄对，闲对 的统计
                radius=2.3;
                ctx.font = 'bold '+cellWidth * .5 + 'px SimHei';
                arr=arr.flat(Infinity);
                arr=arr.slice(-(elemObj.column - 1) * 6 - arr.length % 6);//转一维数组后取倒数的数据
                for (let i = 0; i < arr.length; i++) {
                    // 创建渐变
                    let grd=ctx.createRadialGradient(
                        (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2,
                        (i % 6) * cellHeight + cellHeight / 2,
                        cellWidth / radius,
                        (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2-(cellWidth / radius/2),//x坐标减去二分之一半径
                        (i % 6) * cellHeight + cellHeight / 2-(cellWidth / radius/2),
                        0);
                    //填充圆圈
                    var letter = arr[i].substr(0, 1);//获取首字母
                    let color;
                    if (PEARL_CODE.X.indexOf(letter)!=-1) {
                        color = COLOR.blue;//填充颜色
                        text = letter == 'N' ? this.$t("common.hu") : this.$t("common.x");
                    } else if (PEARL_CODE.Z.indexOf(letter)!=-1) {
                        color = COLOR.red;
                        text = letter == 'M' ? this.$t("common.l") : this.$t("common.z");
                    } else if (PEARL_CODE.H.indexOf(letter)!=-1) {
                        color = COLOR.green;
                        text = this.$t("common.h");
                    }
                    grd.addColorStop(0,color);
                    grd.addColorStop(1,COLOR.ray);
                    ctx.fillStyle = grd;
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2, (i % 6) * cellHeight + cellHeight / 2, cellWidth / radius, 0, 2 * Math.PI);
                    ctx.fill();
                    //填充字
                    ctx.fillStyle="#fff";
                    ctx.fillText(text, (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, (i % 6) * cellHeight + cellHeight / 2);
                    //填充圆点
                    if (PEARL_CODE.ZD.indexOf(letter)!=-1) {//含有庄对
                        ctx.fillStyle = COLOR.red;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    if (PEARL_CODE.XD.indexOf(letter)!=-1) {//含有闲对
                        ctx.fillStyle = COLOR.blue;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 + cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    //数量统计
                    switch (letter) {
                        case 'D':
                            countArr[0]++; countArr[3]++; break;
                        case 'H':
                            countArr[1]++; countArr[4]++; break;
                        case 'L':
                            countArr[2]++; countArr[3]++; countArr[4]++; break;
                        case 'G':
                            countArr[1]++; countArr[3]++; break;
                        case 'I':
                            countArr[1]++; countArr[3]++; countArr[4]++; break;
                        case 'F':
                            countArr[0]++; countArr[3]++; countArr[4]++; break;
                        case 'J':
                            countArr[2]++; countArr[3]++; break;
                        case 'K':
                            countArr[2]++; countArr[4]++; break;
                        case 'E':
                            countArr[0]++; countArr[4]++; break;
                        case 'O':
                        case 'C':
                            countArr[2]++; break;
                        case 'A':
                        case 'M':
                            countArr[0]++; break;
                        case 'B':
                        case 'N':
                            countArr[1]++; break;
                    }
                }
                this.pearlCount.z=countArr[0];
                this.pearlCount.x=countArr[1];
                this.pearlCount.h=countArr[2];
                this.pearlCount.zd=countArr[3];
                this.pearlCount.xd=countArr[4];
            }
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            this.drawPearl(this.pearlArr);
        },
        /**
         * 问路
         */
        nextPrediction(pearl){
            if(!this.isShowNextPrediction){//当前没有在显示问路，防止多次点击
                this.isShowNextPrediction=true;
                let virtualArr=JSON.parse(JSON.stringify(this.pearlArr));//深克隆一个虚拟数组
                this.updatePearl(virtualArr,pearl);
                this.drawPearl(virtualArr);
                this.nextPredictionTimer = setTimeout(() => {
                    this.drawPearl(this.pearlArr);
                    this.isShowNextPrediction=false;
                }, 1000);
            }
        },
        /**
		 * 更新路单信息
		 * @param {Array} pearlArr 路单列表
		 * @param {String} pearl 路单数据
		 */
		updatePearl(pearlArr,pearl){
			if (pearlArr && pearlArr.length > 0 && (
				(PEARL_CODE.Z.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.Z.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1) ||
				(PEARL_CODE.X.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.X.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1) ||
				(PEARL_CODE.H.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.H.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1)
				)) {//如果当前的露珠类型与上一项同类，则加到上一项的队列中
				pearlArr[pearlArr.length - 1].push(pearl);
			} else {//否则新建自己的队列
				pearlArr.push([pearl]);
			}
		},
        /**
         * 倒计时
         * @param {Number} tableId 要计时的台面id
         * @param {Number} countdown 计时起始值
         */
        listCountdown(tableId,countdown){
            let room=this.roomList.find(v=>v.id==tableId);
            room.countdown=countdown;
            var timer = setInterval(()=>{
                if(room.countdown!=countdown){//两个值不一样说明台面计时被重新赋值了，应当停掉定时器
                    clearInterval(timer);
                    return;
                }
                if(room.countdown <= 0){//当计时小于等于0或者
                    room.countdown=0;
                    clearInterval(timer);
                    return;
                }
                room.countdown--;
                countdown--;
            }, 1000);
        },
        /**
         * 绘制路单
         */
        drawPearl(pearlArr){
            //画表格
            let elemList = document.querySelectorAll("[data-canvas-room]");
            for (let elem of elemList){
                let val=elem.dataset.canvasRoom;//获取data-canvas-room属性的值
                //设置画布大小
                this.$root.setCanvasSize(elem);
                //绘制画布表格
                let obj = this.$root.drawCanvasTable(elem,val,false);
                if(obj){//判断对象是否存在，防止视口被拉小到没有的时候找不到而报错
                    if(obj.elem.id=='textBigPearl'){
                        //设置移入带字大路区的遮罩层大小
                        this.pearlLayerStyle.width=obj.cellWidth/RATIO+'px';
                        this.pearlLayerStyle.height=obj.cellHeight/RATIO+'px';
                    }
                    //画路单
                    if(pearlArr.length>0){
                        var delTieArr=this.$root.setPearlData(this.currentGame,pearlArr,pearlArr.length,false);//第一次调用setPearlData是为了去掉和，此时并无法确定要保留多少列
                        if(obj.elem.id=="textBigPearl"){
                            this.drawOrderPearl(obj,pearlArr,PEARL_TYPE.big_text);
                        }else if(obj.elem.id=="threePearl"){
                            this.drawOrderPearl(obj,pearlArr,PEARL_TYPE.big_three);
                        }else if(obj.elem.id=="bigPearl"){
                            this.$root.drawPearl(obj,this.$root.setPearlData(this.currentGame,pearlArr,obj.column-1,false),PEARL_TYPE.big_hollow,GAME_ID.bac);
                        }else if(obj.elem.id=="smallHollowPearl"){
                            this.$root.drawPearl(obj,
                                this.$root.setPearlData(this.currentGame,this.$root.convertToSmallPearl(delTieArr,PEARL_TYPE.small_hollow),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                PEARL_TYPE.small_hollow,GAME_ID.bac);
                        }else if(obj.elem.id=="smallSolidPearl"){
                            this.$root.drawPearl(obj,
                                this.$root.setPearlData(this.currentGame,this.$root.convertToSmallPearl(delTieArr,PEARL_TYPE.small_solid),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                PEARL_TYPE.small_solid,GAME_ID.bac);
                        }else if(obj.elem.id=="bevelPearl"){
                            this.$root.drawPearl(obj,
                                this.$root.setPearlData(this.currentGame,this.$root.convertToSmallPearl(delTieArr,PEARL_TYPE.bevel),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                PEARL_TYPE.bevel,GAME_ID.bac);
                        }
                    }
                }
            }
            //更新下局预测
            if(pearlArr.length>0){
                if(delTieArr&&delTieArr.length>0){//判断去掉和之后的数组长度大于0，防止第一口为和时报错
                    let lastLetter=delTieArr[delTieArr.length-1][0].substring(0,1);//获取最后一列的第一项的首字母
                    if(PEARL_CODE.Z.indexOf(lastLetter)!=-1){//判断最后一列是Z类
                        //下局z
                        this.nextZHollowStyle="color:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-2,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.small_hollow
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);//取倒数第二列拼上追加了虚拟项的倒数第一列
                        this.nextZBevelStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-3,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.bevel
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        this.nextZSolidStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-4,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.small_solid
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        //下局x
                        this.nextXHollowStyle="color:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-2).concat([['B']]),
                                    PEARL_TYPE.small_hollow
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);//取倒数第二列拼上追加了虚拟项的倒数第一列
                        this.nextXBevelStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-3).concat([['B']]),
                                    PEARL_TYPE.bevel
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        this.nextXSolidStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-4).concat([['B']]),
                                    PEARL_TYPE.small_solid
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                    }else if(PEARL_CODE.X.indexOf(lastLetter)!=-1){//X类
                        //下局z
                        this.nextZHollowStyle="color:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-2).concat([['A']]),
                                    PEARL_TYPE.small_hollow
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);//取倒数第二列拼上追加了虚拟项的倒数第一列
                        this.nextZBevelStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-3).concat([['A']]),
                                    PEARL_TYPE.bevel
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        this.nextZSolidStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-4).concat([['A']]),
                                    PEARL_TYPE.small_solid
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        //下局x
                        this.nextXHollowStyle="color:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-2,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.small_hollow
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);//取倒数第二列拼上追加了虚拟项的倒数第一列
                        this.nextXBevelStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-3,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.bevel
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                        this.nextXSolidStyle="background:"+
                            (String(this.$root.convertToSmallPearl(
                                    delTieArr.slice(-4,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                    PEARL_TYPE.small_solid
                                ).flat().slice(-1))=='A'?COLOR.red:COLOR.blue);
                    }
                }
            }else{
                this.pearlCount={z:0,x:0,h:0,zd:0,xd:0};//清空统计
                this.nextZHollowStyle="";
                this.nextZBevelStyle="";
                this.nextZSolidStyle="";
                this.nextXHollowStyle="";
                this.nextXBevelStyle="";
                this.nextXSolidStyle="";
            }
        },
        /**
         * 显示牌
         * @param {Number} type 移入或移出
         */
        showPoker(e,type){
            if(type==1){//移入
                if(this.pearlLayerStyle.width!=0&&this.pearlLayerStyle.height!=0){
                    let cellWidth=this.pearlLayerStyle.width.replace('px','');
                    let cellHeight=this.pearlLayerStyle.height.replace('px','');
                    let column=(getComputedStyle(e.target).width.replace('px','')/cellWidth).toFixed();//获取总列数
                    let x = Math.ceil(e.offsetX / cellWidth);//列
                    let y = Math.ceil(e.offsetY / cellHeight);//行
                    let array=this.pearlArr.flat(Infinity);
                    let pokerArr=this.pokerArr.slice(-(column - 1) * 6 - array.length % 6);//取倒数的数据
                    let poker=pokerArr[(x-1)*6+(y-1)];//获取对应位置的扑克
                    
                    if((x!=this.point[0]||y!=this.point[1])&&x!=0&&y!=0&&poker&&this.isShowPoker){//坐标点相比于上次有不同并且大于0行0列并且对应位置有扑克数据
                        this.point=[x,y];
                        this.$nextTick(()=>{
                            this.pearlLayerStyle.display='block';
                            this.pearlLayerStyle.left=(x-1)*cellWidth+'px';
                            this.pearlLayerStyle.top=(y-1)*cellHeight+'px';
                            this.showPokerArr=poker;
                        })
                    }
                }
            }else{//移出
                this.showPokerArr=[];
                this.pearlLayerStyle.display='none';
                this.point=[0,0];
            }
        },
    },
    watch: {
        /**
         * 监听路单数据变化
         */
        pearlArr:{
            handler(newVal){
                this.drawPearl(newVal);
            },
            deep:true//深度监听
        },
        /**
         * 监听视频线路id改变
         */
        currVideoLine(newVal){
            if(newVal!=""){
                //获取台面视频
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,newVal,this.userInfo.id,this.userInfo.guid);
            }else{
                this.videoList=null;
            }
        },
        /**
         * 监听倒计时改变
         */
        countdown(newVal,oldVal){
            newVal>=0&&this.isScale==false&&(this.isScale=true);
            //一旦倒计时大于0，则每秒减1
            newVal==10&&this.$root.$refs.tenAudioPlayer.playNext();
            if(newVal>0){
                if(newVal!=oldVal-1){//如果本次的计时不比上一次少一秒，说明可能是服务端推送两次计时导致计时混乱，需要停掉上一次的计时器
                    this.countdownTimer&&clearTimeout(this.countdownTimer);
                }
                this.countdownTimer = setTimeout(()=>{
                    this.countdown--;
                    if(this.countdown<=0){
                        this.countdown=0;
                        this.$nextTick(()=>{
                            this.isScale=false;
                        })
                    }
                }, 1000);
            }
        },
    },
};
</script>