function extend(o,n,override) {
	for (var key in n) {
		if (n.hasOwnProperty(key) && (!o.hasOwnProperty(key) || override)) {
			o[key] = n[key];
		}
	}
	return o;
}
//单条滑动验证
(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
	typeof define === 'function' && define.amd ? define(factory) :
	(window.slideVerifyPlug = factory());
}(this, (function () { 'use strict';
    
    var SlideVerify = function (ele,opt) {
        this.$ele = document.getElementById(ele);
        //默认参数
        this.defaults = {
            wrapWidth:'100%',
            initText:'请按住滑块，拖动到最右边',
            sucessText:'验证通过',
            getSucessState:function(){
            	
            }
        }

        this.settings = extend(this.defaults, opt,true);
        this.touchX = 0 ;
        this.slideFinishState = false;
        this.init();
    }
    SlideVerify.prototype = {
    	constructor: SlideVerify,
        init:function () {
            var _this = this;
            _this.initDom();
            _this.initStyle();
            _this.initEle();
            _this._mousedown();
            _this._mouseup();
            _this._touchstart();
            _this._touchmove();
            _this._touchend();
        },
        initDom:function(){
        	var html = 
        		'<div class="progress-bar-striped progress-bar-animated drag-progress dragProgress position-absolute start-0 h-100 rounded top-0 text-light"></div>' + 
				'<span class="d-flex flex-column justify-content-center position-absolute drag-btn dragBtn rounded top-0 bottom-0 h-100 px-3 border border-secondary cursor-move bg-light index-1" style="background:linear-gradient(0deg, rgb(0 144 169) 60%, transparent 100%)">' + 
				'	<i class="bi bi-suit-spade-fill rotate-90"></i>'+
				'	<i class="bi bi-check-circle-fill text-light"></i>'+
				'</span>' + 
				'<span class="d-flex flex-column justify-content-center position-absolute fix-tips fixTips start-0 end-0 top-0 bottom-0 rounded text-center text-light">' +
                '	<span class="verify-msg-text lang-login d-flex justify-content-center fw-bold text-discolor" text="'+this.settings.initText+'">' +
						this.settings.initText +
                '	</span>' +
				'</span>' + 
				'<span class="progress-bar-striped progress-bar-animated verify-msg sucMsg text-light rounded position-absolute text-center start-0 end-0 top-0 bottom-0">' +
                '	<label class="verify-msg-text lang-login d-flex flex-column justify-content-center h-100">' +
						this.settings.sucessText +
                '	</label>'+
				'</span>';
			this.$ele.innerHTML=html;
        },
        initStyle:function(){
			this.$ele.style.width=this.settings.wrapWidth;
        },
        initEle:function(){
			this.slideBtn =this.$ele.querySelector(".dragBtn");
	        this.slideProEle = this.$ele.querySelector('.dragProgress');
	        this.slideSucMsgEle = this.$ele.querySelector('.sucMsg');
	        this.slideFixTipsEle = this.$ele.querySelector('.fixTips');
	        this.maxSlideWid = this.calSlideWidth();
        },
        _mousedown:function(){
        	var _this = this;
        	_this.slideBtn.onmousedown=function(e){
				var distenceX = e.pageX;
        		e.preventDefault();
        		if(_this.ifSlideRight() || _this.ifAnimated()){
					return false;
				}
				document.onmousemove=function(e){
					var curX = e.pageX - distenceX;
					if (curX >= _this.calSlideWidth()) {
						_this.slideBtn.style.right=0;
						_this.slideBtn.style.left="auto";
					    _this.setDragProgressSty("100%");
						document.onmousemove=null;
						_this.slideFinishState = true;
//				    	$("#verify-msg").text('验证通过');
						if (_this.settings.getSucessState) {
		                    _this.settings.getSucessState(_this.slideFinishState);
		                }
						_this.successSty();
					} else if (curX <= 0) {
						_this.setDragBtnSty('0');
						_this.setDragProgressSty('0');
					} else {
						_this.setDragBtnSty(curX);
						_this.setDragProgressSty(curX+"px");
					}
				}
			}
        },
        _mouseup:function(){
        	var _this = this;
			document.onmouseup=function(){
				document.onmousemove=null;
        		if(_this.ifSlideRight()){
					return false;
				}else{
					_this.failAnimate();
				}
			}
        },
        _touchstart:function(){
        	var _this = this;
			_this.slideBtn.ontouchstart=function (e) {
				_this.touchX = e.targetTouches[0].pageX;
				if(_this.ifSlideRight() || _this.ifAnimated()){
//					_this.cancelTouchmove();
					return false;
				}
			}
        },
        _touchmove:function(){
        	var _this = this;
			_this.slideBtn.ontouchmove=function(e){
				e.preventDefault();
        		var curX = e.targetTouches[0].pageX - _this.touchX;
        		if (curX >= _this.calSlideWidth()) {
        		    _this.slideBtn.style.right=0;
					_this.slideBtn.style.left="auto";
        		    _this.setDragProgressSty("100%");
					_this.slideBtn.ontouchmove=null;
					_this.successSty();
					_this.slideFinishState = true;
					if(_this.settings.getSucessState){
	                    _this.settings.getSucessState(_this.slideFinishState);
	                }
					_this.slideFinishState = true;
				}else if(curX <= 0){
					_this.setDragBtnSty('0');
					_this.setDragProgressSty('0');
				}else{
					_this.setDragBtnSty(curX);
					_this.setDragProgressSty(curX+"px");
				}
			}
        },
        _touchend:function(){
        	var _this = this;
			_this.slideBtn.ontouchend=function(){
				if(_this.ifSlideRight()){
					_this.slideBtn.ontouchmove=null;
					return false;
				}else{
					_this.failAnimate();
				}
			}
        },
        getDragBtnWid:function(){//获取滑块的宽度，
        	return getComputedStyle(this.slideBtn).width.replace("px","");
        },
        getDragWrapWid:function(){//获取  本容器的的宽度，以防万一
			let style=getComputedStyle(this.$ele);
        	return style.width.replace('px','')-style.borderLeftWidth.replace("px","")-style.borderRightWidth.replace("px","");
        },
        calSlideWidth:function(){
        	var _this = this;
        	return _this.getDragWrapWid() - _this.getDragBtnWid();
        },
        ifAnimated:function(){//判断 是否动画状态
			return false;
			//return this.slideBtn.matches(":animated");
        	//return $(this.slideBtn).is(":animated")
        },
        getDragBtnLeft:function(){ //判断当前 按钮 离左侧的距离
        	return getComputedStyle(this.slideBtn).left.replace("px","");
        },
        ifSlideRight:function(){
        	var _this = this;
        	if (Math.abs(_this.calSlideWidth()-_this.getDragBtnLeft())<1) {
        		return true;
        	} else {
        		return false;
        	}
        },
        setDragBtnSty:function(left){
			this.slideBtn.style.left=left+"px";
        },
        setDragProgressSty:function(wid){
			this.slideProEle.style.width=wid;
        },
        successSty:function(){
			this.slideSucMsgEle.style.display="block";
			this.slideBtn.classList.add("suc-drag-btn");
        },
        failAnimate:function(){
			this.slideBtn.style.transition="all 200";
			this.slideBtn.style.left=0;
			this.slideProEle.style.transition="all 200";
			this.slideProEle.style.width=0;
       },
       resetVerify:function(){
       		this.slideSucMsgEle.display="none";
			this.slideBtn.classList.remove('suc-drag-btn');
			this.slideFinishState = false;
			this.slideProEle.style.width=0;
			this.slideBtn.style.left=0;
			this._touchmove();
       },
    }

	var slideVerify = window.slideVerifyPlug || SlideVerify;

	return slideVerify;

})));