<!-- 功能模块 -->
<style scoped>
    @media (min-width: 576px){
        .list-item:last-child{
            border-bottom: 1px solid #dee2e6 !important;
        }
        .list-item:nth-of-type(odd) > * {
            background: rgba(0, 0, 0, 0.05);
        }
        .list-item:hover {
            background: rgba(0, 0, 0, 0.075);
        }
    }
</style>
<template>
    <div>
        <!-- 音效设置 -->
        <div v-if="currModal==$t('nav.sound')" class="d-flex flex-column flex-sm-row flex-wrap text-light">
            <div class="d-flex col-12 col-sm-6 mb-2">
                <div class="form-check form-switch ps-0 col">
                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                        <input class="form-check-input fs-4 m-0 me-sm-2 me-4" @change="changeAudioSwitch('bgAudio',$event.target.checked)" :checked="audioSwitch.bgAudio" type="checkbox" role="switch" id="bgAudio">
                        <label class="form-check-label" for="bgAudio">{{$t("nav.bgAudio")}}</label>
                    </div>
                </div>
            </div>
            <div class="d-flex col-12 col-sm-6 mb-2">
                <div class="form-check form-switch ps-0 col">
                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                        <input class="form-check-input fs-4 m-0 me-sm-2 me-4" @change="changeAudioSwitch('startAudio',$event.target.checked)" :checked="audioSwitch.startAudio" type="checkbox" role="switch" id="startAndEndAudio">
                        <label class="form-check-label" for="startAndEndAudio">{{$t("nav.startAudio")}}</label>
                    </div>
                </div>
            </div>
            <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                <div class="form-check form-switch ps-0 col">
                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                        <input class="form-check-input fs-4 m-0 me-sm-2 me-4" @change="changeAudioSwitch('resultAudio',$event.target.checked)" :checked="audioSwitch.resultAudio" type="checkbox" role="switch" id="resultAudio">
                        <label class="form-check-label" for="resultAudio">{{$t("nav.resultAudio")}}</label>
                    </div>
                </div>
            </div>
            <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                <div class="form-check form-switch ps-0 col">
                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                        <input class="form-check-input fs-4 m-0 me-sm-2 me-4" @change="changeAudioSwitch('tenAudio',$event.target.checked)" :checked="audioSwitch.tenAudio" type="checkbox" role="switch" id="tenAudio">
                        <label class="form-check-label" for="tenAudio">{{$t("nav.tenAudio")}}</label>
                    </div>
                </div>
            </div>
        </div>
        <!-- 线路选择 -->
        <div v-if="currModal==$t('nav.network')" class="d-flex flex-wrap text-light">
            <div class="row text-center text-light">
                <div class="col-6 mb-2" v-for="(item,i) in videoLineList" :key="(item,i)">
                    <div class="btn-navy rounded py-2" :class="(item.id==currVideoLine?'active text-info':'')" @click="$root.setCurrVideoLine(item.id)" role="button">{{item.name}}</div>
                </div>
            </div>
        </div>
        <!-- 充值 -->
        <div v-load v-if="currModal==$t('personal.recharge')" class="d-flex flex-column justify-content-center flex-wrap text-light">
            <!-- 表单填写 -->
            <form v-show="isShowRechargeForm">
                <div class="p-3 p-sm-4 border border-1 rounded">
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="rechargeAmount" class="col-form-label">{{$t("personal.rechargeAmount")}}：</label>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control bg-light" id="rechargeAmount" v-model="rechargeAmount" :placeholder="$t('personal.rechargeAmount')" name="number">
                            <div class="form-text">{{$t("personal.rechargeRange")}}{{rechargeLimit.min}}~{{rechargeLimit.max}}</div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label class="col-form-label">{{$t("personal.rechargeType")}}：</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-check-inline col-form-label">
                                <input class="form-check-input" type="radio" name="rechargeType" v-model="rechargeType" :value="GAME_TYPE.real" id="rechargeTypeReal">
                                <label class="form-check-label" for="rechargeTypeReal">{{$t("nav.realMoney")}}</label>
                            </div>
                            <div class="form-check form-check-inline col-form-label">
                                <input class="form-check-input" type="radio" name="rechargeType" v-model="rechargeType" :value="GAME_TYPE.esports" id="rechargeTypeEsport">
                                <label class="form-check-label" for="rechargeTypeEsport">{{$t("nav.esportsMoney")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label class="col-form-label">{{$t("personal.payment")}}：</label>
                        </div>
                        <div class="col d-flex">
                            <div v-for="i in rechargeFundsList" :key="i.id" class="form-check-inline px-2 rounded" :class="[payment==i.id?'border':'border-1 border-transparent']" @click="payment=i.id">
                                <input :id="'rechargePayment_'+i.id" class="btn-check" type="radio" autocomplete="off" name="payment" :value="i.id">
                                <label class="form-check-label fs-2" :for="'rechargePayment_'+i.id" role="button">
                                    <i v-if="i.id==FUNDS_RECHARGE_TYPE.alipay" class="bi bi-alipay text-primary"></i>
                                    <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.wechat" class="bi bi-wechat text-success"></i>
                                    <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.bank" class="bi bi-credit-card text-dark"></i>
                                    <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.virtual" class="bi bi-currency-bitcoin text-info"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label class="col-form-label">{{$t("personal.currency")}}：</label>
                        </div>
                        <div class="col">
                            <div v-for="item in rechargeCurrencyList" :key="item" class="form-check form-check-inline col-form-label">
                                <input :id="'rechargeCurrency_'+item.id" class="form-check-input" type="radio" name="rechargeCurrency" v-model="rechargeCurrency" :value="item.id">
                                <label class="form-check-label" :for="'rechargeCurrency_'+item.id">{{item.name}}</label>
                            </div>
                        </div>
                    </div>
                    <button type="button" @click="submitEvent('recharge')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
                </div>
            </form>
            <!-- 成功反馈 -->
            <div v-show="!isShowRechargeForm">
                <div class="d-flex flex-column align-items-center">
                    <div class="text-success display-3 mb-2">
                        <i class="bi bi-check-circle-fill"></i>
                    </div>
                    <span class="text-success fw-bold fs-4">{{$t("personal.rechargeSuccess")}}</span>
                    <div class="">
                        <span class="text-white">{{$t("personal.qrcodeIs")}}：</span>
                        <span class="text-danger fs-1">{{rechargeCode}}</span>
                    </div>
                    <div class="text-danger mb-2">{{$t("personal.codeToRemark")}}</div>
                    <div class="col" v-show="isShowQrcode">
                        <div class="d-flex justify-content-center">
                            <div class="col-10 col-sm-6 col-md-7 col-lg-8 col-xl-9">
                                <vue-qr class="img-thumbnail bg-transparent rounded" :margin="10" colorDark="#0dcaf0" :text="rechargeQrcode"></vue-qr>
                            </div>
                        </div>
                        <div class="mt-2 mb-3 text-center">
                            <span class="text-white">{{$t("personal.scanOrClick")}}</span>
                            <button class="btn btn-primary btn-sm mx-1">{{$t("personal.hear")}}</button>
                            <span class="text-white">{{$t("personal.toRecharge")}}</span>
                        </div>
                    </div>
                    <div class="card mb-3" v-show="isShowBankInfo">
                        <div class="card-body border border-1 rounded p-0">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item list-group-item-primary">
                                    <span class="fw-bold">{{$t("personal.name")}}：</span>
                                    <span>{{rechargeSuccBankName}}</span>
                                </li>
                                <li class="list-group-item list-group-item-primary">
                                    <span class="fw-bold">{{$t("personal.account")}}：</span>
                                    <span>{{rechargeSuccBankAccount}}</span>
                                </li>
                                <li class="list-group-item list-group-item-primary">
                                    <span class="fw-bold">{{$t("personal.bank")}}：</span>
                                    <span>{{rechargeSuccBankBank}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary" @click="isShowRechargeForm=true">{{$t("personal.back")}}</button>
                </div>
            </div>
        </div>
        <!-- 提现 -->
        <div v-load v-if="currModal==$t('personal.withdraw')" class="d-flex flex-column justify-content-center text-light">
            <form class="border border-1 rounded p-3 p-sm-4">
                <div class="mb-1 text-center text-danger">{{$t("personal.withdrawHandlingFee")}}</div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="withdrawAmount" class="col-form-label">{{$t("personal.withdrawAmount")}}：</label>
                    </div>
                    <div class="col">
                        <input type="number" class="form-control bg-light" v-model="withdrawAmount" id="withdrawAmount" :placeholder="$t('personal.withdrawAmount')" name="withdrawAmount">
                        <div class="form-text">{{$t("personal.withdrawRange")}}{{withdrawLimit.min}}~{{withdrawLimit.max}}</div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="pwd" class="col-form-label">{{$t("personal.withdrawal")}}：</label>
                    </div>
                    <div class="col">
                        <div v-for="i in withdrawFundsList" :key="i.id" class="form-check-inline px-2 rounded" :class="[withdrawal==i.id?'border':'border-1 border-transparent']" @click="withdrawal=i.id">
                            <input :id="'withdrawal_'+i.id" class="btn-check" type="radio" autocomplete="off" name="withdrawal" :value="i.id">
                            <label class="form-check-label fs-2" :for="'withdrawal_'+i.id" role="button">
                                <i v-if="i.id==FUNDS_WITHDRAW_TYPE.alipay" class="bi bi-alipay text-primary"></i>
                                <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.wechat" class="bi bi-wechat text-success"></i>
                                <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.bank" class="bi bi-credit-card text-dark"></i>
                                <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.virtual" class="bi bi-currency-bitcoin text-info"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="email" class="col-form-label">{{$t("personal.accepted")}}：</label>
                    </div>
                    <div class="col">
                        <select class="form-select bg-light" v-model="accepted">
                            <option v-for="i in acceptedList" :key="i.id" :value="i.id">{{getAcceptedTxt(i)}}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="pwd" class="col-form-label">{{$t("personal.withdrawType")}}：</label>
                    </div>
                    <div class="col">
                        <div class="form-check form-check-inline col-form-label">
                            <input class="form-check-input" type="radio" name="withdrawType" v-model="withdrawType" :value="GAME_TYPE.real" id="withdrawTypeReal">
                            <label class="form-check-label" for="withdrawTypeReal">{{$t("nav.realMoney")}}</label>
                        </div>
                        <div class="form-check form-check-inline col-form-label">
                            <input class="form-check-input" type="radio" name="withdrawType" v-model="withdrawType" :value="GAME_TYPE.esports" id="withdrawTypeEsports">
                            <label class="form-check-label" for="withdrawTypeEsports">{{$t("nav.esportsMoney")}}</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="withdrawPwd" class="col-form-label">{{$t("personal.withdrawPwd")}}：</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control bg-light" v-model="withdrawPwd" id="withdrawPwd" :placeholder="$t('personal.withdrawPwd')" name="withdrawPwd" autocomplete="false">
                    </div>
                </div>
                <button type="button" @click="submitEvent('withdraw')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
            </form>
        </div>
        <!-- 资金渠道 -->
        <div v-load v-if="currModal==$t('personal.updateSource')" class="d-flex flex-column flex-grow-1 w-50rem overflow-y-scroll">
            <div class="row">
                <!-- 支付宝 -->
                <div class="col-12 col-sm-4 mb-3 mb-md-0">
                    <div class="card border-primary overflow-hidden">
                        <div class="card-header d-flex justify-content-between text-primary bg-light">
                            <div>
                                <i class="bi bi-alipay me-1"></i>
                                <span>{{$t("personal.alipay")}}</span>
                            </div>
                            <i class="bi" :class="[isShowAddAlipay?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddAlipay=isShowAddAlipay?false:true"></i>
                        </div>
                        <div class="card-body p-0">
                            <ul v-if="fundChannel" class="list-group list-group-flush">
                                <li v-for="i in fundChannel.alipay" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-primary">
                                    <div class="flex-grow-1 w-0 pe-1">
                                        <div>
                                            <span class="fw-bold">{{$t("personal.name")}}：</span>
                                            <span>{{i.name}}</span>
                                        </div>
                                        <div>
                                            <span class="fw-bold">{{$t("personal.account")}}：</span>
                                            <span>{{i.user}}</span>
                                        </div>
                                    </div>
                                    <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.alipay)">
                                        <i class="bi bi-trash-fill"></i>
                                    </span>
                                </li>
                                <li v-show="isShowAddAlipay" class="list-group-item list-group-item-primary">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <form class="flex-grow-1 w-0 pe-3">
                                            <div class="d-flex mb-1">
                                                <div class="col-4 pe-0">
                                                    <label for="addAlipayName" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.name")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addAlipayName" v-model="addAlipayName" class="form-control form-control-sm" :placeholder="$t('personal.name')" name="addAlipayName">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="col-4 pe-0">
                                                    <label for="addAlipayAccount" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.account")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addAlipayAccount" v-model="addAlipayAccount" class="form-control form-control-sm" :placeholder="$t('personal.account')" name="addAlipayAccount">
                                                </div>
                                            </div>
                                        </form>
                                        <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.alipay)">
                                            <i class="bi bi-save"></i>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 银联 -->
                <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                    <div class="card border-dark overflow-hidden">
                        <div class="card-header d-flex justify-content-between text-dark bg-light">
                            <div>
                                <i class="bi bi-credit-card me-1"></i>
                                <span>{{$t("personal.unionPay")}}</span>
                            </div>
                            <i class="bi" :class="[isShowAddBank?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddBank=isShowAddBank?false:true"></i>
                        </div>
                        <div class="card-body p-0">
                            <ul v-if="fundChannel" class="list-group list-group-flush">
                                <li v-for="i in fundChannel.bank" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-dark">
                                    <div class="flex-grow-1 w-0 pe-1">
                                        <div>
                                            <span class="fw-bold">{{$t("personal.name")}}：</span>
                                            <span>{{i.name}}</span>
                                        </div>
                                        <div>
                                            <span class="fw-bold">{{$t("personal.account")}}：</span>
                                            <span>{{i.user}}</span>
                                        </div>
                                        <div>
                                            <span class="fw-bold">{{$t("personal.bank")}}：</span>
                                            <span>{{i.bankname}}</span>
                                        </div>
                                    </div>
                                    <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.bank)">
                                        <i class="bi bi-trash-fill"></i>
                                    </span>
                                </li>
                                <li v-show="isShowAddBank" class="list-group-item list-group-item-dark">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <form class="flex-grow-1 w-0 pe-3">
                                            <div class="d-flex mb-1">
                                                <div class="col-4 pe-0">
                                                    <label for="addBankName" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.name")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addBankName" v-model="addBankName" class="form-control form-control-sm" :placeholder="$t('personal.name')" name="addBankName">
                                                </div>
                                            </div>
                                            <div class="d-flex mb-1">
                                                <div class="col-4 pe-0">
                                                    <label for="addBankAccount" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.account")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addBankAccount" v-model="addBankAccount" class="form-control form-control-sm" :placeholder="$t('personal.account')" name="addBankAccount">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="col-4 pe-0">
                                                    <label for="addBankBank" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.bank")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addBankBank" v-model="addBankBank" class="form-control form-control-sm" :placeholder="$t('personal.bank')" name="addBankBank">
                                                </div>
                                            </div>
                                        </form>
                                        <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.bank)">
                                            <i class="bi bi-save"></i>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 区块链 -->
                <div class="col-12 col-sm-4">
                    <div class="card border-info overflow-hidden">
                        <div class="card-header d-flex justify-content-between text-info bg-light">
                            <div>
                                <i class="bi bi-currency-bitcoin me-1"></i>
                                <span>{{$t("personal.virtualCurrency")}}</span>
                            </div>
                            <i class="bi" :class="[isShowAddCoin?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddCoin=isShowAddCoin?false:true"></i>
                        </div>
                        <div class="card-body p-0">
                            <ul v-if="fundChannel" class="list-group list-group-flush">
                                <li v-for="i in fundChannel.coin" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-info">
                                    <div class="flex-grow-1 w-0 pe-1">
                                        <div>
                                            <span class="fw-bold">{{$t("personal.currency")}}：</span>
                                            <span>{{i.name}}</span>
                                        </div>
                                        <div>
                                            <span class="fw-bold">{{$t("personal.walletAddress")}}：</span>
                                            <span>{{i.url}}</span>
                                        </div>
                                    </div>
                                    <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.virtual)">
                                        <i class="bi bi-trash-fill"></i>
                                    </span>
                                </li>
                                <li v-show="isShowAddCoin" class="list-group-item list-group-item-primary">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <form class="flex-grow-1 w-0 pe-3">
                                            <div class="d-flex mb-1">
                                                <div class="col-4 pe-0">
                                                    <label for="addVirtualCurrency" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.currency")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addVirtualCurrency" v-model="addVirtualCurrency" class="form-control form-control-sm" :placeholder="$t('personal.currency')" name="addVirtualCurrency">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="col-4 pe-0">
                                                    <label for="addVirtualUrl" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.walletAddress")}}：</label>
                                                </div>
                                                <div class="col">
                                                    <input type="text" id="addVirtualUrl" v-model="addVirtualUrl" class="form-control form-control-sm" :placeholder="$t('personal.walletAddress')" name="addVirtualUrl">
                                                </div>
                                            </div>
                                        </form>
                                        <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.virtual)">
                                            <i class="bi bi-save"></i>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 修改密码 -->
        <form v-if="currModal==$t('common.safeSetting')" class="d-flex flex-column justify-content-center text-light text-nowrap">
            <div class="p-3 p-sm-4 border border-1 rounded">
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label class="col-form-label">{{$t("personal.pwdType")}}：</label>
                    </div>
                    <div class="col">
                        <div class="form-check form-check-inline col-form-label">
                            <input class="form-check-input" type="radio" v-model="currPwdType" name="pwdType" :value="PWD_TYPE.login" id="pwdLogin">
                            <label class="form-check-label" for="pwdLogin">{{$t("personal.loginPwd")}}</label>
                        </div>
                        <div class="form-check form-check-inline col-form-label">
                            <input class="form-check-input" type="radio" v-model="currPwdType" name="pwdType" :value="PWD_TYPE.withdraw" id="pwdWithdraw">
                            <label class="form-check-label" for="pwdWithdraw">{{$t("personal.withdrawPwd")}}</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="oldPwd" class="col-form-label">{{$t("personal.oldPwd")}}：</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control bg-light" v-model="oldPwd" id="oldPwd" :placeholder="$t('personal.oldPwd')" name="oldPwd" autocomplete="false">
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="newPwd" class="col-form-label">{{$t("personal.newPwd")}}：</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control bg-light" v-model="newPwd" id="newPwd" :placeholder="$t('personal.newPwd')" name="newPwd" autocomplete="false">
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row mb-3">
                    <div class="col-12 col-sm-3">
                        <label for="againPwd" class="col-form-label">{{$t("personal.againPwd")}}：</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control bg-light" v-model="againPwd" id="againPwd" :placeholder="$t('personal.againPwd')" name="againPwd" autocomplete="false">
                    </div>
                </div>
                <button type="button" @click="submitEvent('updatePwd')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
            </div>
        </form>
        <!-- 会员推广 -->
        <div v-load v-if="currModal==$t('common.vipShare')" class="d-flex flex-column justify-content-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <vue-qr class="img-thumbnail bg-transparent rounded" :margin="10" colorDark="#0dcaf0" :text="shareQrStr"></vue-qr>
                <button type="button" class="btn btn-link" @click="copy(shareQrStr)">{{$t("common.copy")}}</button>
            </div>
        </div>
        <!-- 问题反馈 -->
        <div v-load v-if="currModal==$t('common.questionFeedBack')" class="row flex-grow-1 px-0">
            <div class="d-flex flex-column flex-grow-1">
                <div class="overflow-y-scroll flex-grow-1 h-20rem" v-scroll-down>
                    <div v-for="i in problemList" :key="i.id">
                        <div class="d-flex justify-content-end mb-3">
                            <div class="col-10 col-sm-8">
                                <div class="list-group">
                                    <div class="list-group-item list-group-item-primary">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-2">{{i.title}}</h5>
                                            <small>{{i.qtime}}</small>
                                        </div>
                                        <div>{{i.question}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="fs-2 ms-2">
                                <i class="bi bi-person-fill"></i>
                            </div>
                        </div>
                        <div v-if="i.state==1" class="d-flex mb-3">
                            <div class="fs-2 me-2">
                                <i class="bi bi-person"></i>
                            </div>
                            <div class="col-10 col-sm-8">
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-2">{{i.title}}</h5>
                                            <small>{{i.atime}}</small>
                                        </div>
                                        <div>{{i.answer}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="d-flex flex-column rounded">
                    <div class="mb-1">
                        <input class="form-control bg-light" type="text" v-model="txtProblemTitle" :placeholder="$t('personal.enterTitle')">
                    </div>
                    <div class="bg-white rounded-top">
                        <textarea class="form-control form-control-nofocus border-0 textarea-noresize bg-light" v-model="txtProblemContent" rows="2" :placeholder="$t('personal.enterProblem')"></textarea>
                    </div>
                    <div class="d-flex justify-content-end bg-light rounded-bottom">
                        <button type="button" class="btn btn-sm btn-outline-success m-2" @click="submitProblem">{{$t("personal.send")}}</button>
                    </div>
                </form>
            </div>
        </div>
        <!-- 账户记录 -->
        <div v-load v-if="currModal==$t('common.userRecords')||currModal==$t('personal.rechargeLog')||currModal==$t('personal.withdrawLog')||currModal==$t('personal.submitLog')" class="text-light w-70rem flex-grow-1 d-flex flex-column">
            <div v-if="currModal==$t('common.userRecords')" class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link py-1 active" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button">{{$t("personal.submitLog")}}</button>
                <button class="nav-link py-1" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button">{{$t("personal.rechargeLog")}}</button>
                <button class="nav-link py-1" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button">{{$t("personal.withdrawLog")}}</button>
            </div>
            <div class="flex-grow-1 tab-content" id="nav-tabContent">
                <!-- 提交记录 -->
                <div class="tab-pane fade h-100" :class="{'show active':currModal==$t('personal.submitLog')||currModal==$t('common.userRecords')}" id="nav-contact" tabindex="0">
                    <div class="h-100 row flex-column px-0">
                        <form class="row mb-3 mx-0 px-0 align-items-end">
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="submitLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('submit')" v-model="submitLogStartDate" id="submitLogStartDate" :max="maxDate" :placeholder="$t('personal.startDate')" name="submitLogStartDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="submitLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('submit')" v-model="submitLogEndDate" id="submitLogEndDate" :max="maxDate" :placeholder="$t('personal.endDate')" name="submitLogEndDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label class="col-form-label-sm">{{$t("personal.gameType")}}：</label>
                                </div>
                                <div class="col">
                                    <select class="form-select form-select-sm bg-light" v-model="submitLogGameType" @change="getLog('submit')">
                                        <option :value="GAME_ID.bac">{{$t("common.bac")}}</option>
                                        <option :value="GAME_ID.cor">{{$t("common.cor")}}</option>
                                        <option :value="GAME_ID.dice">{{$t("common.dice")}}</option>
                                        <option :value="GAME_ID.rou">{{$t("common.rou")}}</option>
                                        <option :value="GAME_ID.longfong">{{$t("common.longfong")}}</option>
                                        <option :value="GAME_ID.coin">{{$t("common.coin")}}</option>
                                        <option :value="GAME_ID.texasauto">{{$t("common.texasauto")}}</option>
                                        <option :value="GAME_ID.slot">{{$t("common.slot")}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div class="fw-bold d-none d-sm-flex text-center">
                            <div class="col">{{$t("personal.date")}}</div>
                            <div class="col">{{$t("personal.room")}}</div>
                            <div class="col-auto">{{$t("personal.media")}}</div>
                            <div class="col">{{$t("personal.final")}}</div>
                            <div class="col">{{$t("personal.deposit")}}</div>
                            <div class="col">{{submitLogGameType==GAME_ID.texasauto?'投注':$t("personal.result")}}</div>
                            <div class="col-5">{{submitLogGameType==GAME_ID.texasauto?'最佳牌型':$t("personal.report")}}</div>
                        </div>
                        <div class="flex-grow-1 h-0 overflow-y-scroll pb-sm-1 small position-relative" :class="{'scroll-none':submitLog&&submitLog.length<=0}">
                            <div v-show="submitLog&&submitLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noSubmitLog")}}</div>
                            <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                <div class="spinner-border text-info mx-auto" role="status"></div>
                                <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                            </div>
                            <div v-if="!isSearch">
                                <div v-for="i in submitLog" :key="i" class="d-flex flex-column flex-sm-row">
                                    <!-- 标题栏 -->
                                    <div v-if="i.game==GAME_ID.texasauto" class="d-flex order-sm-1 justify-content-between align-items-center align-items-sm-stretch p-1 p-sm-0 border-xs border-top-sm border-start-sm border-end-sm rounded-xs">
                                        <div class="d-sm-none small">{{i.date.substring(0,i.date.indexOf(' '))}} {{i.date.substring(i.date.indexOf(' ')+1)}}</div>
                                        <div class="d-sm-none fw-bold">{{i.room}}{{i.table}}</div>
                                        <div class="bg-warning text-primary text-sm-vertical text-center fw-bold small px-1 px-sm-0 rounded-xs" @click="showMore(i)">查看详情</div>
                                    </div>
                                    <!-- 内容栏 -->
                                    <div class="flex-grow-1 list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                        <!-- 日期 -->
                                        <div :class="{'d-none d-sm-flex':i.game==GAME_ID.texasauto}" class="col-5 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{$t("personal.date")}}</div>
                                            <div class="d-sm-flex flex-sm-column flex-grow-1 flex-sm-grow-0 py-sm-1 text-end text-sm-center">
                                                <span class="pe-1 pe-sm-0">{{i.date.substring(0,i.date.indexOf(' '))}}</span>
                                                <span>{{i.date.substring(i.date.indexOf(' ')+1)}}</span>
                                            </div>
                                        </div>
                                        <!-- 房间 -->
                                        <div :class="{'d-none d-sm-flex':i.game==GAME_ID.texasauto}" class="col-5 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{$t("personal.room")}}</div>
                                            <div class="flex-grow-1 flex-sm-grow-0 d-flex justify-content-sm-center">
                                                <div class="d-flex justify-content-end justify-content-sm-center col-12" v-if="submitLogGameType==GAME_ID.slot">
                                                    {{ i.boot==0&&i.innings==0?$t("common.treasureSlot"):$t("common.fruitSlot") }}
                                                </div>
                                                <div v-else class="d-flex flex-sm-column justify-content-end col-12">
                                                    <div class="d-flex justify-content-end justify-content-sm-center">
                                                        <div>{{i.room}}</div>
                                                        <div class="pe-1 pe-sm-0">{{i.table}}</div>
                                                    </div>
                                                    <div v-if="submitLogGameType!=GAME_ID.texasauto" class="d-flex justify-content-end justify-content-sm-center">
                                                        <div>{{i.boot}}-</div>
                                                        <div>{{i.innings}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 设备 -->
                                        <div :class="{'col-3 col-sm-auto':i.game==GAME_ID.texasauto}" class="col-2 col-sm-auto px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{$t("personal.media")}}</div>
                                            <div class="flex-grow-1 flex-sm-grow-0 px-sm-1 py-sm-1 text-end text-sm-center">
                                                <i class="bi bi-display" v-if="i.source.indexOf('h5_web')!=-1"></i>
                                                <i class="bi bi-phone" v-else-if="i.source.indexOf('h5_phone')!=-1"></i>
                                                <i class="bi bi-question" v-else></i>
                                            </div>
                                        </div>
                                        <!-- 输赢 -->
                                        <div class="col-3 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{$t("personal.final")}}</div>
                                            <div :class="{'text-info':parseFloat(i.win)<0}" class="flex-grow-1 flex-sm-grow-0 py-sm-1 text-start text-sm-center" :style="{color:parseFloat(i.win)>0?COLOR.red:''}">{{parseFloat(i.win)}}</div>
                                        </div>
                                        <!-- 余额 -->
                                        <div class="col-3 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{$t("personal.deposit")}}</div>
                                            <div class="flex-grow-1 flex-sm-grow-0 py-sm-1 text-start text-sm-center">{{parseFloat(i.realmoney)}}</div>
                                        </div>
                                        <!-- 结果 -->
                                        <div :class="[i.game==GAME_ID.texasauto?'col-3':'col-6']" class="col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{i.game==GAME_ID.texasauto?'投注':$t("personal.result")}}</div>
                                            <div class="flex-grow-1 flex-sm-grow-0 text-start text-sm-center" v-html="setResultHtml(i.result,i)"></div>
                                        </div>
                                        <!-- 详情 -->
                                        <div class="col-12 col-sm-5 px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                            <div class="fw-bold d-sm-none pe-1 pe-sm-0 text-warning">{{i.game==GAME_ID.texasauto?'最佳牌型':$t("personal.report")}}</div>
                                            <div class="flex-grow-1 flex-sm-grow-0 d-flex flex-sm-column py-sm-1 text-sm-center justify-content-between" v-html="setReportHtml(i.detail,i.game,i)"></div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-evenly text-light text-opacity-50 small py-2">
                            <div>
                                <span>{{$t("personal.total")}}：</span>
                                <span>{{submitLogTotal}}</span>
                            </div>
                            <div>
                                <span>{{$t("personal.valid")}}：</span>
                                <span>{{submitLogValid}}</span>
                            </div>
                            <div>
                                <span>{{$t("personal.final")}}：</span>
                                <span>{{submitLogWin}}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <pagination :count-page="countPage" @change-page="changePage"></pagination>
                        </div>
                    </div>
                </div>
                <!-- 充值记录 -->
                <div class="tab-pane fade h-100" :class="{'show active':currModal==$t('personal.rechargeLog')}" id="nav-home" tabindex="0">
                    <div class="h-100 row flex-column px-0">
                        <form class="row mb-3 mx-0 px-0">
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="rechargeLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('recharge')" v-model="rechargeStartDate" :max="maxDate" id="rechargeLogStartDate" :placeholder="$t('personal.startDate')" name="rechargeLogStartDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="rechargeLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('recharge')" v-model="rechargeEndDate" :max="maxDate" id="rechargeLogEndDate" :placeholder="$t('personal.endDate')" name="rechargeLogEndDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label class="col-form-label-sm">{{$t("personal.payment")}}：</label>
                                </div>
                                <div class="col">
                                    <select class="form-select form-select-sm bg-light" @change="getLog('recharge')" v-model="rechargeLogPayment">
                                        <option value="0">{{$t("personal.all")}}</option>
                                        <option v-for="i in rechargeFundsList" :key="i" :value="i.id">{{getLogType("recharge",i.id)}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label class="col-form-label-sm">{{$t("personal.rechargeType")}}：</label>
                                </div>
                                <div class="col">
                                    <select class="form-select form-select-sm bg-light" @change="getLog('recharge')" v-model="rechargeLogType">
                                        <option value="0">{{$t("personal.all")}}</option>
                                        <option value="1">{{$t("nav.realMoney")}}</option>
                                        <option value="2">{{$t("nav.esportsMoney")}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div class="fw-bold d-none d-sm-flex text-center">
                            <div class="col-sm-2">{{$t("personal.date")}}</div>
                            <div class="col-sm-2">{{$t("personal.money")}}</div>
                            <div class="col-sm-2">{{$t("personal.currency")}}</div>
                            <div class="col-sm-2">{{$t("personal.payment")}}</div>
                            <div class="col-sm-2">{{$t("personal.rechargeType")}}</div>
                            <div class="col-sm-2">{{$t("personal.rechargeState")}}</div>
                        </div>
                        <div class="flex-grow-1 h-0 overflow-y-scroll pb-sm-1" :class="{'scroll-none':rechargeLog&&rechargeLog.length<=0}">
                            <div v-show="rechargeLog&&rechargeLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noRechargeLog")}}</div>
                            <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                <div class="spinner-border text-info mx-auto" role="status"></div>
                                <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                            </div>
                            <div v-if="!isSearch">
                                <div v-for="i in rechargeLog" :key="i" class="list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.date")}}</div>
                                        <div class="py-1 text-sm-center">{{i.date}}</div>
                                    </div>
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.money")}}</div>
                                        <div class="py-1 text-sm-center">{{i.money}}</div>
                                    </div>
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.currency")}}</div>
                                        <div class="py-1 text-sm-center">{{i.coin}}</div>
                                    </div>
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.payment")}}</div>
                                        <div class="py-1 text-sm-center">{{getLogType('recharge',i.way)}}</div>
                                    </div>
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.rechargeType")}}</div>
                                        <div class="py-1 text-sm-center">{{i.type==GAME_TYPE.real?$t("personal.real"):(i.type==GAME_TYPE.esports?$t("personal.esports"):"")}}</div>
                                    </div>
                                    <div class="col-4 col-sm-2 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.rechargeState")}}</div>
                                        <div class="py-1 text-sm-center">{{getLogState(i.state)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 提现记录 -->
                <div class="tab-pane fade h-100" :class="{'show active':currModal==$t('personal.withdrawLog')}" id="nav-profile" tabindex="0">
                    <div class="h-100 row flex-column px-0">
                        <form class="row mb-3 mx-0 px-0">
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="withdrawLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('withdraw')" v-model="withdrawStartDate" :max="maxDate" id="withdrawLogStartDate" :placeholder="$t('personal.startDate')" name="withdrawLogStartDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label for="withdrawLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control form-control-sm bg-light" @change="getLog('withdraw')" v-model="withdrawEndDate" :max="maxDate" id="withdrawLogEndDate" :placeholder="$t('personal.endDate')" name="withdrawLogEndDate">
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label class="col-form-label-sm">{{$t('personal.withdrawState')}}：</label>
                                </div>
                                <div class="col">
                                    <select class="form-select form-select-sm bg-light" v-model="withdrawLogState" @change="getLog('withdraw')">
                                        <option value="-1">{{$t("personal.all")}}</option>
                                        <option v-for="i in Object.values(LOG_STATE)" :key="i" :value="i">{{getLogState(i)}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-sm-3">
                                <div class="col">
                                    <label class="col-form-label-sm">{{$t('personal.withdrawType')}}：</label>
                                </div>
                                <div class="col">
                                    <select class="form-select form-select-sm bg-light" v-model="withdrawLogType" @change="getLog('withdraw')">
                                        <option value="0">{{$t("personal.all")}}</option>
                                        <option value="1">{{$t("personal.real")}}</option>
                                        <option value="2">{{$t("personal.esports")}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div class="fw-bold d-none d-sm-flex text-center">
                            <div class="col">{{$t("personal.date")}}</div>
                            <div class="col">{{$t("personal.money")}}</div>
                            <div class="col">{{$t("personal.accepted")}}</div>
                            <div class="col">{{$t("personal.withdrawType")}}</div>
                            <div class="col">{{$t("personal.withdrawState")}}</div>
                        </div>
                        <div class="flex-grow-1 h-0 overflow-y-scroll pb-sm-1" :class="{'scroll-none':withdrawLog&&withdrawLog.length<=0}">
                            <div v-show="withdrawLog&&withdrawLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noWithdrawLog")}}</div>
                            <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                <div class="spinner-border text-info mx-auto" role="status"></div>
                                <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                            </div>
                            <div v-if="!isSearch">
                                <div v-for="i in withdrawLog" :key="i" class="list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                    <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.date")}}</div>
                                        <div class="py-1 text-sm-center">{{i.date}}</div>
                                    </div>
                                    <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.money")}}</div>
                                        <div class="py-1 text-sm-center">{{i.money}}</div>
                                    </div>
                                    <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.accepted")}}</div>
                                        <div class="py-1 text-sm-center">{{getLogType('withdraw',i.way)}}</div>
                                    </div>
                                    <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.withdrawType")}}</div>
                                        <div class="py-1 text-sm-center">{{i.type==GAME_TYPE.real?$t("personal.real"):(i.type==GAME_TYPE.esports?$t("personal.esports"):"")}}</div>
                                    </div>
                                    <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                        <div class="fw-bold d-sm-none">{{$t("personal.withdrawState")}}</div>
                                        <div class="py-1 text-sm-center">{{getLogState(i.state)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 查看详情 -->
                <div :class="[JSON.stringify(currShowMore)!='{}'?'d-flex':'d-none']" class="justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
                    <div class="d-flex flex-column rounded-3 overflow-hidden shadow mx-2 mx-sm-0">
                        <div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
                            <span>{{currModal}}</span>
                            <i class="bi bi-x-octagon" @click="currShowMore={}"></i>
                        </div>
                        <div class="tab-pane fade h-100 show active">
                            <div class="d-flex flex-column p-2 p-sm-3 bg-blue table-responsive">
                                <table class="">
                                    <tr class="text-nowrap">
                                        <td class="px-2 px-sm-4 pb-1">玩家</td>
                                        <td class="px-2 px-sm-4 pb-1">总投入</td>
                                        <td class="px-2 px-sm-4 pb-1">总收益</td>
                                        <td class="px-2 px-sm-4 pb-1">净收益</td>
                                        <td class="px-2 px-sm-4 pb-1 text-center">最佳牌型</td>
                                    </tr>
                                    <tr v-for="player in currShowMore.player||[]" :key="player" :class="{'text-warning bg-primary-gradient bg-opacity-75':player.userid==userInfo.id,'opacity-50':player.state==1}">
                                        <td class="px-2 px-sm-4">{{ player.userid }}</td>
                                        <td class="px-2 px-sm-4 text-end">{{ player.sumbet }}</td>
                                        <td class="px-2 px-sm-4 text-end">{{ player.gain }}</td>
                                        <td class="px-2 px-sm-4 text-end">{{ player.gain-player.sumbet }}</td>
                                        <td class="px-2 px-sm-4 py-2 small text-end">
                                            <div class="d-flex justify-content-center position-relative">
                                                <div v-for="(card,i) in player.card.concat(currShowMore.pubcard)" class="position-relative me-1">
                                                    <div :class="[$root.getPokerByNum(card)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                                        <span class="fw-bold lh-1 small text-start">{{$root.getPokerByNum(card)["name"]}}</span>
                                                        <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                            <i :class="[$root.getPokerByNum(card)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                            <i :class="[$root.getPokerByNum(card)['shape']]" class="bi fs-5"></i>
                                                        </div>
                                                        <span :class="[i<2?'bg-warning text-primary':'bg-primary text-light']" class="fs-_6rem rounded-circle position-absolute bottom-0 end-0 fw-bold w-1rem h-1rem d-flex justify-content-center align-items-center">{{(i<2?'手':'公')}}</span>
                                                    </div>
                                                    <div :class="{'bg-dark bg-opacity-50':$root.getCardType(player.card.concat(currShowMore.pubcard)).card.indexOf(card)==-1}" class="position-absolute top-0 start-0 end-0 bottom-0 rounded"></div>
                                                </div>
                                                <span class="position-absolute top-50 start-50 translate-middle-x bg-dark bg-opacity-50 text-light px-2 rounded-pill small fw-bold">{{$root.getCardType(player.card.concat(currShowMore.pubcard)).type}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import {COMMAND,GAME_TYPE,FUNDS_RECHARGE_TYPE,RECHARGE_RESULT,FUNDS_WITHDRAW_TYPE,WITHDRAW_RESULT,PWD_TYPE,UPDATE_PWD_RESULT,LAYER_TYPE,SET_SOURCE_RESULT,GAME_ID,COLOR,LOG_STATE,MUTATION_TYPE,LOGIN_MODE} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,formatDate,copy } from '@/assets/js/common.js'
import pagination from "@/components/public/pagination.vue";
//使用二维码插件，安装：npm install vue-qr --save，官网：https://github.com/Binaryify/vue-qr#readme
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
    components: { vueQr,pagination },
    directives: {
        'scroll-down': {
            updated(elem){
                elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
            }
        },
        'load':{
            mounted(elem,binding) {
                let _this=binding.instance;
                switch (_this.currModal) {
                    case _this.$t('personal.recharge'):
                        if(!_this.rechargeFundsList){//未获取充值支付方式列表则获取
                            _this.$root.wsSend(COMMAND.all_getbankrollway,1,_this.userInfo.id,_this.userInfo.guid);
                        }else if(_this.rechargeFundsList&&_this.rechargeFundsList.length>0){//已获取并且列表有数据则默认选第一项
                            _this.payment=_this.rechargeFundsList[0].id;
                        }
                        break;
                    case _this.$t('personal.withdraw'):
                        if(!_this.withdrawFundsList){//未获取提现方式列表则获取
                            _this.$root.wsSend(COMMAND.all_getbankrollway,2,_this.userInfo.id,_this.userInfo.guid);
                        }else if(_this.withdrawFundsList&&_this.withdrawFundsList.length>0){//已获取并且列表有数据则默认选第一项
                            _this.withdrawal=_this.withdrawFundsList[0].id;
                        }
                        if(!_this.fundChannel){//未获取资金渠道列表则获取
                            _this.$root.wsSend(COMMAND.all_getuserchannel,"list",_this.userInfo.id,_this.userInfo.guid);
                        }
                        if(!_this.withdrawLimit.min&&!_this.withdrawLimit.max){//未获取提现限额
                            _this.$root.wsSend(COMMAND.all_getwithdrawlimit,_this.userInfo.id,_this.userInfo.guid);
                        }
                        break;
                    case _this.$t('common.vipShare'):
                        !_this.shareQrStr&&_this.$root.wsSend(COMMAND.all_getshareurl,_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('common.questionFeedBack'):
                        !_this.problemList&&_this.$root.wsSend(COMMAND.all_getquestion,1,100,_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('personal.updateSource'):
                        !_this.fundChannel&&_this.$root.wsSend(COMMAND.all_getuserchannel,"list",_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('common.userRecords'):
                    case _this.$t('personal.submitLog'):
                    case _this.$t('personal.rechargeLog'):
                    case _this.$t('personal.withdrawLog'):
                        let startDate=new Date();
                        let endDate=new Date();
                        if(new Date().getHours()<6){//当前时间在6点前
                            //开始时间设置为昨天6点
                            startDate.setDate(startDate.getDate()-1);
                            startDate.setHours(6);
                            startDate.setMinutes(0);
                            startDate.setSeconds(0);
                            //结束时间设置为今天6点
                            endDate.setHours(6);
                            endDate.setMinutes(0);
                            endDate.setSeconds(0);
                        }else{//当前时间在6点后
                            //开始时间设置为今天6点
                            startDate.setHours(6);
                            startDate.setMinutes(0);
                            startDate.setSeconds(0);
                            //结束时间设置为明天6点
                            endDate.setDate(endDate.getDate()+1);
                            endDate.setHours(6);
                            endDate.setMinutes(0);
                            endDate.setSeconds(0);
                        }
                        _this.maxDate=formatDate(endDate).split(' ')[0];//最大选择日期为当前日期
                        _this.rechargeStartDate=formatDate(startDate).split(' ')[0];
                        _this.rechargeEndDate=formatDate(endDate).split(' ')[0];
                        _this.withdrawStartDate=formatDate(startDate).split(' ')[0];
                        _this.withdrawEndDate=formatDate(endDate).split(' ')[0];
                        _this.submitLogStartDate= formatDate(startDate).split(' ')[0];
                        _this.submitLogEndDate=formatDate(endDate).split(' ')[0];
                    case _this.$t('common.userRecords'):
                        //获取提交记录
                        _this.$root.wsSend(COMMAND.all_getbetlog,formatDate(startDate),formatDate(endDate),_this.currPage,30,_this.submitLogGameType,_this.userInfo.id,_this.userInfo.guid);
                        //获取充值记录
                        _this.$root.wsSend(COMMAND.all_getrecharge,formatDate(startDate),formatDate(endDate),_this.rechargeLogPayment,_this.rechargeLogType,_this.userInfo.id,_this.userInfo.guid);
                        //获取提现记录
                        _this.$root.wsSend(COMMAND.all_getwithdraw,formatDate(startDate),formatDate(endDate),_this.withdrawLogType,_this.withdrawLogState,_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('personal.submitLog'):
                        //获取提交记录
                        _this.$root.wsSend(COMMAND.all_getbetlog,formatDate(startDate),formatDate(endDate),_this.currPage,30,_this.submitLogGameType,_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('personal.rechargeLog'):
                        //获取充值记录
                        _this.$root.wsSend(COMMAND.all_getrecharge,formatDate(startDate),formatDate(endDate),_this.rechargeLogPayment,_this.rechargeLogType,_this.userInfo.id,_this.userInfo.guid);
                        break;
                    case _this.$t('personal.withdrawLog'):
                        //获取提现记录
                        _this.$root.wsSend(COMMAND.all_getwithdraw,formatDate(startDate),formatDate(endDate),_this.withdrawLogType,_this.withdrawLogState,_this.userInfo.id,_this.userInfo.guid);
                        break;
                }
            },
        }
    },
    props: {
        currModal:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            FUNDS_RECHARGE_TYPE,
            GAME_TYPE,
            FUNDS_WITHDRAW_TYPE,
            PWD_TYPE,
            GAME_ID,
            COLOR,
            LOG_STATE,
            rechargeType:GAME_TYPE.real,//选中的充值类型
            isShowRechargeForm:true,//是否显示充值表单
            rechargeAmount:'',//充值金额
            payment:"",//选中的支付方式
            rechargeCurrency:"",//选中的充值币种
            rechargeCode:"",//充值验证码
            isShowQrcode:true,//是否显示充值二维码
            rechargeQrcode:"",//充值二维码
            isShowBankInfo:false,//是否显示充值成功的银行信息
            rechargeSuccBankName:"",//充值成功银行卡姓名
            rechargeSuccBankAccount:"",//充值成功银行卡账号
            rechargeSuccBankBank:"",//充值成功银行卡开户行
            withdrawal:"",//选中的提现方式
            withdrawAmount:"",//提现金额
            accepted:0,//选中的收款方
            withdrawType:GAME_TYPE.real,//选中的提现类型
            withdrawPwd:"",//提现密码
            currPwdType:"",//选中的密码类型
            oldPwd:"",//原密码
            newPwd:"",//新密码
            againPwd:"",//确认密码
            txtProblemTitle:"",//建议反馈标题
            txtProblemContent:"",//建议反馈内容
            isShowAddAlipay:false,//是否显示添加支付宝资金渠道
            isShowAddBank:false,//是否显示添加银联资金渠道
            isShowAddCoin:false,//是否显示添加区块链资金渠道
            addAlipayName:"",//要新增的支付宝姓名
            addAlipayAccount:"",//要新增的支付宝账号
            addBankName:"",//要新增的银联姓名
            addBankAccount:"",//要新增的银联账号
            addBankBank:"",//要新增的银联开户行
            addVirtualCurrency:"",//要新增的虚拟币币种
            addVirtualCurrencyName:"",//要新增的虚拟币币种名称
            addVirtualUrl:"",//要新增的虚拟币钱包地址
            setFundsType:"",//要删除或新增的资金类型
            //充值记录
            rechargeStartDate:"",//充值记录开始时间
            rechargeEndDate:"",//充值记录结束时间
            maxDate:"",//最大查询日期
            rechargeLogPayment:0,//充值记录支付方式
            rechargeLogType:0,//充值记录充值类型
            //提现记录
            withdrawStartDate:"",//提现记录开始时间
            withdrawEndDate:"",//提现记录结束时间
            withdrawLogState:-1,//提现记录提现状态(默认全部)
            withdrawLogType:0,//提现记录提现类型
            //提交记录
            submitLogTotal:0,//总额
            submitLogValid:0,//有效额
            submitLogWin:0,//输赢
            submitLogStartDate:"",//提交记录开始时间
            submitLogEndDate:"",//提交记录结束时间
            submitLogGameType:GAME_ID.bac,//提交记录游戏类型
            isSearch:false,//当前是否在搜索状态，是的话需要显示加载层
            countPage:1,//总页数
            currPage:1,//当前页码
            countPage:1,//总页数

            rechargeFundsList:null,//充值支付方式列表
            withdrawFundsList:null,//提现提现方式列表
            rechargeCurrencyList:null,//充值币种列表
            fundChannel:null,//用户资金渠道
            withdrawLimit:{
                min:null,
                max:null
            },//提现限额
            shareQrStr:"",//推广二维码字符串
            problemList:null,//问题反馈列表
            rechargeLog:null,//充值记录
            withdrawLog:null,//提现记录
            submitLog:null,//提交记录
            currShowMore:{},//当前查看详情的记录
        }
    },
    computed: {
        loginModel(){
            return this.$store.state.loginModel;
        },
        userInfo() {
            return this.$store.state.userInfo;
        },
        videoLineList(){
            return this.$store.state.videoLineList;
        },
        currVideoLine(){
            return this.$store.state.currVideoLine;
        },
        //音效开关
        audioSwitch(){
            return this.$store.state.audioSwitch;
        },
        //充值币种限额
        rechargeLimit(){
            let limit={
                min:0,
                max:0
            }
            if(this.rechargeCurrencyList&&this.rechargeCurrencyList.length>0){
                let rc=this.rechargeCurrencyList.find(v=>v.id==this.rechargeCurrency);
                if(rc){
                    limit.min=rc.min;
                    limit.max=rc.max;
                }
            }
            return limit;
        },
        //收款方列表
        acceptedList(){
            if(this.withdrawal==FUNDS_WITHDRAW_TYPE.alipay&&this.fundChannel&&this.fundChannel.alipay){
                return this.fundChannel.alipay;
            }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.bank&&this.fundChannel&&this.fundChannel.bank){
                return this.fundChannel.bank;
            }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.virtual&&this.fundChannel&&this.fundChannel.coin){
                return this.fundChannel.coin;
            }
            return [];
        },
    },
    unmounted(){
        delete this.$root.wsMsgObj["modules"];
    },
    mounted() {
        this.$root.wsMsgObj["modules"]=(data)=>{
            switch (data.command) {
                case COMMAND.all_recharge:
                    var r=data.result;//提交结果
                    if(r==RECHARGE_RESULT.error){
                        useLayer({str:this.$t("personal.rechargeErr")});
                    }else if(r==RECHARGE_RESULT.moneyErr){
                        useLayer({str:this.$t("personal.rechargeAmountErr")});
                    }else if(r==RECHARGE_RESULT.submitMuch){
                        useLayer({str:this.$t("personal.submitMuch")});
                    }else if(r==RECHARGE_RESULT.noVip){
                        useLayer({str:this.$t("personal.noVip")});
                    }else if(r==RECHARGE_RESULT.noUser){
                        useLayer({str:this.$t("personal.invalidUser")});
                    }else if(r==RECHARGE_RESULT.submitAgain){
                        useLayer({str:this.$t("personal.submitAgain")});
                    }else if(r==RECHARGE_RESULT.aisleErr){
                        useLayer({str:this.$t("personal.aisleErr")});
                    }else if(r==RECHARGE_RESULT.bossErr){
                        useLayer({str:this.$t("personal.bossErr")});
                    }else if(r==RECHARGE_RESULT.officialErr){
                        useLayer({str:this.$t("personal.officialErr")});
                    }else{
                        this.rechargeAmount="";
                        this.rechargeQrcode=data.qr.qraddress;
                        this.rechargeCode=data.code;
                        this.isShowRechargeForm=false;
                        if(data.show==true){
                            this.isShowBankInfo=false;
                            this.isShowQrcode=true;
                        }
                        if(data.way==FUNDS_RECHARGE_TYPE.bank){
                            this.isShowBankInfo=true;
                            this.isShowQrcode=false;
                            this.rechargeSuccBankName=data.qr.payname;
                            this.rechargeSuccBankAccount=data.qr.payaccount;
                            this.rechargeSuccBankBank=data.qr.paybank;
                        }
                    }
                    break;
                case COMMAND.all_getrecharge:
                    this.rechargeLog=data.list;
                    break;
                case COMMAND.all_getwithdraw:
                    this.withdrawLog=data.list;
                    this.isSearch=false;
                    break;
                case COMMAND.all_getbetlog:
                    this.currPage=data.pagecurrent;
                    this.countPage=data.pagelen;
                    this.submitLogTotal=data.allbet;
                    this.submitLogValid=data.validbet;
                    this.submitLogWin=data.win;
                    this.submitLog=data.list;
                    this.isSearch=false;
                    break;
                case COMMAND.all_getbankrollway:
                    if(data.type==1){//充值
                        this.rechargeFundsList=data.list;
                    }else if(data.type==2){//提现
                        this.withdrawFundsList=data.list;
                    }
                    break;
                case COMMAND.all_getcoin:
                    this.rechargeCurrencyList=data.list;
                    break;
                case COMMAND.all_getuserchannel:
                    this.fundChannel={
                        alipay:data.alipay,
                        bank:data.bank,
                        coin:data.coin
                    }
                    break;
                case COMMAND.all_getwithdrawlimit:
                    this.withdrawLimit={
                        min:data.min,
                        max:data.max
                    }
                    break;
                case COMMAND.all_getshareurl:
                    this.shareQrStr=data.url;
                    break;
                case COMMAND.all_getquestion:
                    this.problemList=data.list.reverse();
                    break;
                case COMMAND.all_addquestion:
                    if(data.result>0){
                        this.$root.wsSend(COMMAND.all_getquestion,1,100,this.userInfo.id,this.userInfo.guid);
                    }else{
                        useLayer({str:this.$t("personal.sendErr")});
                    }
                    break;
                case COMMAND.all_updatepwd:
                    var r=data.result;
                    if(r==UPDATE_PWD_RESULT.success){
                        useLayer({
                            type:LAYER_TYPE.confirm,
                            str:this.$t("personal.updLoginPwdSuccess"),
                            btn:[this.$t("common.ok")],
                            yesFn:(i)=>{
                                window.location.reload();
                            }
                        })
                    }else if(r==UPDATE_PWD_RESULT.pwdEmpty){
                        useLayer({str:this.$t("personal.pwdEmpty")});
                    }else if(r==UPDATE_PWD_RESULT.pwdErr){
                        useLayer({str:this.$t("personal.pwdErr")});
                    }else if(r==UPDATE_PWD_RESULT.userErr){
                        useLayer({str:this.$t("personal.invalidUser")});
                    }else if(r==UPDATE_PWD_RESULT.pwdEqual){
                        useLayer({str:this.$t("personal.pwdEqual")});
                    }
                    break;
                case COMMAND.all_setuserchannel:
                    var r=data.result;
                    if(r==SET_SOURCE_RESULT.error){
                        useLayer({str:this.$t("common.subErr")});
                    }else if(r==SET_SOURCE_RESULT.cannot){
                        useLayer({str:this.$t("personal.accountLimit")});
                    }else if(r==SET_SOURCE_RESULT.noCurrency){
                        useLayer({str:this.$t("personal.invalidCurrency")});
                    }else{
                        this.$root.wsSend(COMMAND.all_getuserchannel,"list",this.userInfo.id,this.userInfo.guid);
                        useLayer({str:this.$t("common.subSuccess")});
                    }
                    break;
                case COMMAND.all_deluserchannel:
                    var r=data.result;
                    if(r==SET_SOURCE_RESULT.error){
                        useLayer({str:this.$t("common.subErr")});
                    }else if(r==SET_SOURCE_RESULT.cannot){
                        useLayer({str:this.$t("personal.sourceHasBills")});
                    }else if(r==SET_SOURCE_RESULT.noCurrency){
                        useLayer({str:this.$t("personal.invalidCurrency")});
                    }else{
                        let key=Object.keys(this.fundChannel).find(v=>this.fundChannel[v].find(item=>item.id==r));
                        this.fundChannel={
                            [key]:this.fundChannel[key].filter(v=>v.id!=data.result)
                        }
                        useLayer({str:this.$t("common.subSuccess")});
                    }
                    break;
                case COMMAND.all_withdraw:
                    var r=data.result;//提交结果 
                    if(r==WITHDRAW_RESULT.noUser){
                        useLayer({str:this.$t("personal.invalidUser")});
                    }else if(r==WITHDRAW_RESULT.pwdErr){
                        useLayer({str:this.$t("personal.withdrawPwdErr")});
                    }else if(r==WITHDRAW_RESULT.moneyLack){
                        useLayer({str:this.$t("personal.withdrawAmountLack")});
                    }else if(r==WITHDRAW_RESULT.moneyErr){
                        useLayer({str:this.$t("personal.withdrawAmountErr")});
                    }else if(r==WITHDRAW_RESULT.reportErr){
                        useLayer({str:this.$t("personal.reportErr")});
                    }else if(r==WITHDRAW_RESULT.acceptedErr){
                        useLayer({str:this.$t("personal.acceptedErr")});
                    }else if(r==WITHDRAW_RESULT.systemErr){
                        useLayer({str:this.$t("common.service")});
                    }else if(!r||r==WITHDRAW_RESULT.error){
                        useLayer({str:this.$t("personal.withdrawErr")});
                    }else{
                        useLayer({str:this.$t("personal.withdrawSuccess")});
                        this.$root.setCurrModal();
                        this.$root.wsSend(COMMAND.all_getuserinfo,this.userInfo.id, this.userInfo.guid);//获取用户信息
                    }
                    break;
            }
        }
    },
    methods:{
        /**
         * 提交事件
         */
        submitEvent(event){
            if(event=="recharge"){//提交充值
                if(this.rechargeAmount==""||Number.isNaN(this.rechargeAmount)||this.rechargeAmount<=0){
                    useLayer({str:this.$t("personal.rechargeAmountErr")});
                }else if(this.rechargeAmount<this.rechargeLimit.min||this.rechargeAmount>this.rechargeLimit.max){
                    useLayer({str:this.$t("personal.rechargeRange")+this.rechargeLimit.min+"~"+this.rechargeLimit.max});
                }else if(this.rechargeType!=GAME_TYPE.real&&this.rechargeType!=GAME_TYPE.esports){
                    useLayer({str:this.$t("personal.rechargeTypeChoose")});
                }else if(this.payment==""){
                    useLayer({str:this.$t("personal.rechargePaymentChoose")});
                }else if(this.rechargeCurrency==""){
                    useLayer({str:this.$t("personal.rechargeCurrencyChoose")});
                }else{
                    this.$root.wsSend(COMMAND.all_recharge,this.rechargeAmount,this.payment,this.rechargeCurrency, this.rechargeCurrencyList.find(v=>v.id==this.rechargeCurrency).pid,this.rechargeType,this.$root.ip.cip,getDeviceType(),this.userInfo.id,this.userInfo.guid);
                }
            }else if(event=="withdraw"){//提交提现
                if(this.withdrawAmount==""||Number.isNaN(this.withdrawAmount)||this.withdrawAmount<=0){
                    useLayer({str:this.$t("personal.withdrawAmountErr")});
                }else if(Number(this.withdrawAmount)<Number(this.withdrawLimit.min)||Number(this.withdrawAmount)>Number(this.withdrawLimit.max)){
                    useLayer({str:this.$t("personal.withdrawRange")+this.withdrawLimit.min+"~"+this.withdrawLimit.max});
                }else if(this.withdrawal==""){
                    useLayer({str:this.$t("personal.withdrawWithdrawalChoose")});
                }else if(this.accepted==0){
                    useLayer({str:this.$t("personal.toAddAccepted")});
                }else if(this.withdrawType!=GAME_TYPE.real&&this.withdrawType!=GAME_TYPE.esports){
                    useLayer({str:this.$t("personal.withdrawTypeChoose")});
                }else if(this.withdrawPwd==""){
                    useLayer({str:this.$t("personal.withdrawPwdErr")});
                }else{
                    this.$root.wsSend(COMMAND.all_withdraw,this.withdrawAmount,this.withdrawal,this.accepted,this.withdrawPwd,this.withdrawType,this.$root.ip.cip,getDeviceType(),this.userInfo.id,this.userInfo.guid,Date.now());
                    this.withdrawAmount="";
                    this.withdrawPwd="";
                }
            }else if(event=="updatePwd"){//提交修改密码
                if(this.loginModel==LOGIN_MODE.try){
                    useLayer({str:"试玩账号无法修改密码！"});
                }else if(this.currPwdType==""){
                    useLayer({str:this.$t("personal.pwdTypeChoose")});
                }else if(this.oldPwd==""){
                    useLayer({str:this.$t("personal.oldPwdEmpty")});
                }else if(this.newPwd==""){
                    useLayer({str:this.$t("personal.newPwdEmpty")});
                }else if(this.newPwd.length<6||this.newPwd.length>12){
                    useLayer({str:this.$t("personal.pwdLength")});
                }else if(this.againPwd==""){
                    useLayer({str:this.$t("personal.againPwdEmpty")});
                }
                // else if(this.oldPwd==this.newPwd){
                //     useLayer({str:this.$t("personal.pwdEqual")});
                // }
                else if(this.againPwd!=this.newPwd){
                    useLayer({str:this.$t("personal.pwdUnequal")});
                }else{
                    //修改密码
                    this.$root.wsSend(COMMAND.all_updatepwd,this.currPwdType,this.oldPwd,this.newPwd,this.userInfo.id,this.userInfo.guid);
                }
            }
        },
        /**
         * 获取提现收款方显示文本
         */
        getAcceptedTxt(obj){
            if(this.withdrawal==FUNDS_WITHDRAW_TYPE.alipay){
                return obj.name+"："+obj.user;
            }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.bank){
                return obj.name+"："+obj.user;
            }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.virtual){
                return obj.name+"："+obj.url;
            }
        },
        /**
         * 提交问题反馈
         */
        submitProblem(){
            if(this.txtProblemTitle==""){
                useLayer({str:this.$t("personal.titleEmpty")});
            }else if(this.txtProblemContent==""){
                useLayer({str:this.$t("personal.contentEmpty")});
            }else{
                if(this.problemList.length>0){//有数据的话需要判断距离上一次发送的时间是否超过15分钟，避免频繁发送
                    let lastProblem=this.problemList[this.problemList.length-1];
                    let ms=new Date()- new Date(lastProblem.qtime);//相差毫秒数
                    let minutes=Math.floor((ms % (1000*60*60)) / (1000*60));
                    if(minutes<15){
                        useLayer({str:this.$t("personal.submitFast")});
                        return;
                    }
                }
                //新增问题反馈
                this.$root.wsSend(COMMAND.all_addquestion,this.txtProblemTitle,this.txtProblemContent,1,this.userInfo.id,this.userInfo.guid);
            }
        },
        /**
         * 资金渠道事件
         */
        sourceEvent(event,id,type){
            if(event=="del"){//删除
                useLayer({
                    type:LAYER_TYPE.confirm,
                    str:this.$t("common.isDel"),
                    btn:[this.$t("common.yes"),this.$t("common.no")],
                    yesFn:(i)=>{
                        layer.close(i);
                        this.setFundsType=type;
                        //删除用户资金账号
                        this.$root.wsSend(COMMAND.all_deluserchannel,id,this.userInfo.id,this.userInfo.guid);
                    }
                })
            }else if(event=="add"){//新增
                if(type==FUNDS_WITHDRAW_TYPE.alipay){
                    if(this.addAlipayName==""){
                        useLayer({str:this.$t("personal.nameEmpty")});
                    }else if(this.addAlipayAccount==""){
                        useLayer({str:this.$t("personal.accountEmpty")});
                    }else{
                        this.$root.wsSend(COMMAND.all_setuserchannel,type,this.addAlipayName,this.addAlipayAccount,"","","",this.userInfo.id,this.userInfo.guid);
                    }
                }else if(type==FUNDS_WITHDRAW_TYPE.bank){
                    if(this.addBankName==""){
                        useLayer({str:this.$t("personal.nameEmpty")});
                    }else if(this.addBankAccount==""){
                        useLayer({str:this.$t("personal.accountEmpty")});
                    }else if(this.addBankBank==""){
                        useLayer({str:this.$t("personal.bankEmpty")});
                    }else{
                        this.$root.wsSend(COMMAND.all_setuserchannel,type,this.addBankName,this.addBankAccount,this.addBankBank,"","",this.userInfo.id,this.userInfo.guid);
                    }
                }else if(type==FUNDS_WITHDRAW_TYPE.virtual){
                    if(this.addVirtualCurrency==""){
                        useLayer({str:this.$t("personal.rechargeCurrencyChoose")});
                    }else if(this.addVirtualUrl==""){
                        useLayer({str:this.$t("personal.walletAddressEmpty")});
                    }else{
                        this.$root.wsSend(COMMAND.all_setuserchannel,type,"","","",this.addVirtualCurrency,this.addVirtualUrl,this.userInfo.id,this.userInfo.guid);
                    }
                }
            }
        },
        /**
         * 获取记录
         * @param {String} type 记录类型
         */
        getLog(type){
            if(type=="submit"){//获取提交记录
                if(Math.abs((new Date(this.submitLogStartDate)-new Date(this.submitLogEndDate))/1000/60/60/24)>45){
                    useLayer({str:this.$t("personal.dateMax")});
                    return;
                }
                this.isSearch=false;
                this.$nextTick(()=>{
                    this.isSearch=true;
                    this.$root.wsSend(COMMAND.all_getbetlog,this.submitLogStartDate+" 06:00:00",this.submitLogEndDate+" 06:00:00",this.currPage,30,this.submitLogGameType,this.userInfo.id,this.userInfo.guid);
                })
            }else if(type=="recharge"){//获取充值记录
                if(Math.abs((new Date(this.rechargeStartDate)-new Date(this.rechargeEndDate))/1000/60/60/24)>45){
                    useLayer({str:this.$t("personal.dateMax")});
                    return;
                }
                this.isSearch=false;
                this.$nextTick(()=>{
                    this.isSearch=true;
                    this.$root.wsSend(COMMAND.all_getrecharge,this.rechargeStartDate+" 06:00:00",this.rechargeEndDate+" 06:00:00",this.rechargeLogPayment,this.rechargeLogType,this.userInfo.id,this.userInfo.guid);
                })
            }else if(type=="withdraw"){//获取提现记录
                if(Math.abs((new Date(this.withdrawStartDate)-new Date(this.withdrawEndDate))/1000/60/60/24)>45){
                    useLayer({str:this.$t("personal.dateMax")});
                    return;
                }
                this.isSearch=false;
                this.$nextTick(()=>{
                    this.isSearch=true;
                    this.$root.wsSend(COMMAND.all_getwithdraw,this.withdrawStartDate+" 06:00:00",this.withdrawEndDate+" 06:00:00",this.withdrawLogType,this.withdrawLogState,this.userInfo.id,this.userInfo.guid);
                })
            }
        },
        /**
         * 改变页码
         */
        changePage(page,type){
            this.$root.wsSend(COMMAND.all_getbetlog,this.submitLogStartDate+" 06:00:00",this.submitLogEndDate+" 06:00:00",page,30,this.submitLogGameType,this.userInfo.id,this.userInfo.guid);
        },
        /**
         * 设置提交记录注单文本
         */
        setReportHtml(text,game,info){
            let arr=[];
            let str="";
            if(this.submitLogGameType==GAME_ID.bac||this.submitLogGameType==GAME_ID.longfong||this.submitLogGameType==GAME_ID.coin){
                if(game==GAME_ID.bac||game==GAME_ID.longfong||game==GAME_ID.coin){
                    //处理数据
                    let resultArr;
                    let textInfo="#0dcaf0";
                    if(game==GAME_ID.bac){
                        resultArr=[[this.$t("common.z"),"",COLOR.redLight],[this.$t("common.x"),"",textInfo],[this.$t("common.h"),"","yellowgreen"],[this.$t("common.zd"),"",COLOR.redLight],[this.$t("common.xd"),"",textInfo]];
                    }else if(game==GAME_ID.lh){
                        resultArr=[[this.$t("common.l"),"",COLOR.redLight],[this.$t("common.hu"),"",textInfo],[this.$t("common.h"),"","yellowgreen"]];
                    }else if(game==GAME_ID.longfong){
                        resultArr=[[this.$t("common.l"),"",textInfo],[this.$t("common.fong"),"",COLOR.redLight],[this.$t("common.h"),"","yellowgreen"],[this.$t("common.lsg"),"",textInfo],[this.$t("common.fsg"),"",COLOR.redLight]];
                    }else if(game==GAME_ID.coin){
                        resultArr=[[this.$t("common.even"),"",COLOR.redLight],[this.$t("common.odd"),"",textInfo],[this.$t("common.twoFlower"),"",COLOR.redLight],[this.$t("common.twoWord"),"",textInfo]];
                    }
                    arr=text.split(',');
                    // arr[arr.length-1]==""&&arr.pop();//去掉最后的空元素
                    let oddArr=arr[arr.length-1];
                    if(oddArr){
                        oddArr=oddArr.substring(1,oddArr.length-1).split(':');
                        oddArr.push(oddArr[3]);
                    }else{
                        arr.pop();
                    }
                    if(game==GAME_ID.bac||game==GAME_ID.lh){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("庄:")!=-1&&(resultArr[0][1]=arr[i].replace("庄:",""));
                            arr[i].indexOf("闲:")!=-1&&(resultArr[1][1]=arr[i].replace("闲:",""));
                            arr[i].indexOf("和:")!=-1&&(resultArr[2][1]=arr[i].replace("和:",""));
                            arr[i].indexOf("庄对:")!=-1&&(resultArr[3][1]=arr[i].replace("庄对:",""));
                            arr[i].indexOf("闲对:")!=-1&&(resultArr[4][1]=arr[i].replace("闲对:",""));
                            arr[i].indexOf("龙:")!=-1&&(resultArr[0][1]=arr[i].replace("龙:",""));
                            arr[i].indexOf("虎:")!=-1&&(resultArr[1][1]=arr[i].replace("虎:",""));
                        }
                    }else if(game==GAME_ID.longfong){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("龙:")!=-1&&(resultArr[0][1]=arr[i].replace("龙:",""));
                            arr[i].indexOf("凤:")!=-1&&(resultArr[1][1]=arr[i].replace("凤:",""));
                            arr[i].indexOf("和:")!=-1&&(resultArr[2][1]=arr[i].replace("和:",""));
                            arr[i].indexOf("龙三公:")!=-1&&(resultArr[3][1]=arr[i].replace("龙三公:",""));
                            arr[i].indexOf("凤三公:")!=-1&&(resultArr[4][1]=arr[i].replace("凤三公:",""));
                        }
                    }else if(game==GAME_ID.coin){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("双:")!=-1&&(resultArr[0][1]=arr[i].replace("双:",""));
                            arr[i].indexOf("单:")!=-1&&(resultArr[1][1]=arr[i].replace("单:",""));
                            arr[i].indexOf("双花:")!=-1&&(resultArr[2][1]=arr[i].replace("双花:",""));
                            arr[i].indexOf("双字:")!=-1&&(resultArr[3][1]=arr[i].replace("双字:",""));
                        }
                    }
                    //拼接html
                    if(game==GAME_ID.bac&&arr.length==0){//bac并且注单为空推测为连中玩法记录
                        str=this.$t("personal.continuousReturn");
                    }else{
                        str+="<div class='d-flex border border-secondary rounded col-12 text-center'>";
                        for (let i = 0; i < resultArr.length; i++) {
                            str+="<div class='d-flex flex-column col'>";
                            str+="    <span class='fw-bold border-secondary border-bottom "+(i==resultArr.length-1?"":"border-end")+"' style='color:"+resultArr[i][2]+"'>"+resultArr[i][0]+(oddArr?'('+oddArr[i]+')':'')+"</span>";
                            str+="    <span class='col border-secondary "+(i==resultArr.length-1?"":"border-end")+"'>"+resultArr[i][1]+"</span>";
                            str+="</div>";
                        }
                        str+="</div>";
                    }
                }
            }else if(this.submitLogGameType==GAME_ID.cor){
                //处理数据
                let zArr=[["","",""],["","",""],["","",""]],xArr=[["","",""],["","",""],["","",""]];//[[平1,平2,平3],[翻1,翻2,翻3],[多1,多2,多3]]
                arr=(text+',').replace(/\.00/g,'').split('},');//P{} D{} F{}
                arr.pop();//去掉最后的空元素
                for (let k = 0; k < arr.length; k++) {
                    arr[k]=arr[k].replace("P{","").replace("F{","").replace("D{","").split(",");//取花括号中的内容根据逗号切割
                    arr[k].pop();//去掉最后的空元素
                    for (let l = 0; l < arr[k].length; l++) {
                        let index=arr[k][l].substring(1,2)-1;
                        if(arr[k][l].indexOf("z")!=-1){
                            zArr[k][index]=arr[k][l].substring(arr[k][l].indexOf(":")+1);
                        }else if(arr[k][l].indexOf("x")!=-1){
                            xArr[k][index]=arr[k][l].substring(arr[k][l].indexOf(":")+1);
                        }
                    }
                }
                //拼接html
                str+="<div class='d-flex justify-content-center col-12'>";
                str+="    <div class='d-flex flex-column text-nowrap justify-content-end border border-secondary rounded-start fw-bold'>";
                str+="        <span class='col'>&nbsp;</span>";
                str+="        <span class='border-top border-secondary border-2 px-1 col'>"+this.$t("room.singleCount")+"</span>";
                str+="        <span class='border-top border-secondary px-1 col'>"+this.$t("room.doubleCount")+"</span>";
                str+="        <span class='border-top border-secondary px-1 col'>"+this.$t("room.multiplesCount")+"</span>";
                str+="    </div>";
                str+="    <div class='d-flex flex-column text-center border border-secondary justify-content-between col'>";
                str+="        <div class='fw-bold border-bottom border-secondary w-100' style='color:"+COLOR.red+"'>"+this.$t("common.z")+"</div>";
                for (let m = 0; m < zArr.length; m++) {
                    str+="    <div class='d-flex border-top border-secondary col w-100'>";
                    for (let n = 0; n < zArr[m].length; n++) {
                        str+="    <span class='"+(n<zArr[m].length-1?"border-1 border-secondary border-end-style-dashed":"")+" col-4'>"+zArr[m][n]+"</span>";
                    }
                    str+="    </div>";
                }
                str+="    </div>";
                str+="    <div class='d-flex flex-column text-center border border-secondary rounded-end justify-content-between col'>";
                str+="        <div class='fw-bold border-bottom border-secondary w-100 text-info'>"+this.$t("common.x")+"</div>";
                for (let m = 0; m < xArr.length; m++) {
                    str+="    <div class='d-flex border-top border-secondary col w-100'>";
                    for (let n = 0; n < xArr[m].length; n++) {
                        str+="    <span class='"+(n<xArr[m].length-1?"border-1 border-secondary border-end-style-dashed":"")+" col-4'>"+xArr[m][n]+"</span>";
                    }
                    str+="    </div>";
                }
                str+="    </div>";
                str+="</div>";
            }else if(this.submitLogGameType==GAME_ID.slot){
                if(info.boot==0&&info.innings==0){//招财进宝
                    str+="<span>"+info.detail.split(':')[1]+"</span>";
                }else if(info.boot==1&&info.innings==1){//水果机
                    let goods=[
                        {code:"bar",rate:50},
                        {code:"seven",rate:40},
                        {code:"star",rate:30},
                        {code:"watermelon",rate:20},
                        {code:"bell",rate:15},
                        {code:"melon",rate:10},
                        {code:"orange",rate:10},
                        {code:"apple",rate:5},
                    ]
                    //处理数据
                    let newArr=[];
                    arr=text.split(',');
                    arr.forEach(v=>newArr[v.split(':')[0]-1]=v.split(':')[1]);
                    str+="<div class='d-flex border border-secondary rounded col-12 text-center'>";
                    for (let i = 0; i < goods.length; i++) {
                        str+="<div class='d-flex flex-column col'>";
                        str+="    <span class='d-flex flex-column align-items-center fw-bold border-secondary border-bottom pt-1 small "+(i==goods.length-1?"":"border-end")+"'>";
                        str+="        <img class='h-1_5rem' src='"+require("@/assets/images/room/slot/"+goods[i].code+".png")+"'/>";
                        str+="        <span class='small'>×"+goods[i].rate+"</span>";
                        str+="    </span>";
                        str+="    <span class='col border-secondary small "+(i==goods.length-1?"":"border-end")+"'>"+(newArr[goods.length-1-i]?newArr[goods.length-1-i]:'')+"</span>";
                        str+="</div>";
                    }
                    str+="</div>";
                }
            }else if(this.submitLogGameType==GAME_ID.texasauto){
                let allCardArr=info.detail.player.find(v=>v.userid==this.userInfo.id).card.concat(info.detail.pubcard);//所有牌
                let goodCardObj=this.$root.getCardType(allCardArr);//最佳牌型对象
                str+="<div class='d-flex justify-content-center position-relative'>";
                for (let i = 0; i < allCardArr.length; i++) {
                    str+="<div class='position-relative me-1'>";
                    str+="    <div class='d-flex flex-column bg-light rounded p-1 border "+this.$root.getPokerByNum(allCardArr[i])['color']+"'>";
                    str+="        <span class='fw-bold lh-1 small text-start'>"+this.$root.getPokerByNum(allCardArr[i])["name"]+"</span>";
                    str+="        <div class='d-flex flex-grow-1 position-relative px-2 pb-1 small'>";
                    str+="            <i class='d-flex bi small position-absolute start-0 "+this.$root.getPokerByNum(allCardArr[i])['shape']+"'></i>";
                    str+="            <i class='bi fs-5 "+this.$root.getPokerByNum(allCardArr[i])['shape']+"'></i>";
                    str+="        </div>";
                    str+="        <span class='fs-_6rem rounded-circle position-absolute bottom-0 end-0 fw-bold w-1rem h-1rem d-flex justify-content-center align-items-center "+(i<2?'bg-warning text-primary ':'bg-primary text-light ')+"'>"+(i<2?'手':'公')+"</span>"
                    str+="    </div>";
                    str+="    <div class='position-absolute top-0 start-0 end-0 bottom-0 rounded "+(goodCardObj.card.indexOf(allCardArr[i])==-1?'bg-dark bg-opacity-50':'')+"'></div>";
                    str+="</div>";
                }
                str+="    <span class='position-absolute top-50 start-50 translate-middle-x bg-dark bg-opacity-50 text-light px-2 rounded-pill small fw-bold'>"+goodCardObj.type+"</span>"
                str+="</div>";
            }
            return str;
        },
        /**
         * 设置提交记录结果文本
         */
        setResultHtml(text,info){
            let str="";
            if(this.submitLogGameType==GAME_ID.bac||this.submitLogGameType==GAME_ID.longfong||this.submitLogGameType==GAME_ID.coin){
                // text=="闲"&&(str=text.replace("闲",'<span style="color:'+this.color.blue+'">'+this.$t("common.x")+'</span>'));
                // text=="庄"&&(str=text.replace("庄",'<span style="color:'+this.color.red+'">'+this.$t("common.z")+'</span>'));
                // text=="和"&&(str=text.replace("和",'<span style="color:'+this.color.green+'">'+this.$t("common.h")+'</span>'));
                // text=="龙"&&(str=text.replace("龙",'<span style="color:'+this.color.red+'">'+this.$t("common.l")+'</span>'));
                // text=="虎"&&(str=text.replace("虎",'<span style="color:'+this.color.blue+'">'+this.$t("common.hu")+'</span>'));
                // str=text;
                str+="<div class='d-flex justify-content-evenly align-items-center'>";
                if(info.poker.zp.concat(info.poker.xp).length>0){
                    str+="<div class='d-flex flex-sm-column justify-content-between align-items-center'>";
                    str+="    <div class='d-flex align-items-center'>";
                    str+="        <span class='fw-bold me-1 me-sm-2' style='color:"+COLOR.redLight+"'>庄</span>";
                    for (let i = 0; i < info.poker.zp.length; i++) {
                        str+="    <div class='d-flex align-items-center justify-content-center mb-sm-1 me-1'>";
                        str+="        <div class='d-flex flex-sm-column bg-light rounded p-_125rem "+this.$root.getPokerByNum(info.poker.zp[i])['color']+"'>";
                        str+="            <span class='fw-bold lh-1 small text-start'>"+this.$root.getPokerByNum(info.poker.zp[i])["name"]+"</span>";
                        str+="            <div class='d-flex flex-grow-1 ps-sm-1 small-xs'>";
                        str+="                <i class='bi d-flex align-items-center justify-content-center flex-grow-1 "+this.$root.getPokerByNum(info.poker.zp[i])['shape']+"'></i>";
                        str+="            </div>";
                        str+="        </div>";
                        str+="    </div>";
                    }
                    str+="    </div>";
                    str+="    <div class='d-flex align-items-center'>";
                    str+="        <span class='fw-bold me-1 me-sm-2 text-info'>闲</span>";
                    for (let i = 0; i < info.poker.xp.length; i++) {
                        str+="    <div class='d-flex align-items-center justify-content-center me-1'>";
                        str+="        <div class='d-flex flex-sm-column bg-light rounded p-_125rem "+this.$root.getPokerByNum(info.poker.xp[i])['color']+"'>";
                        str+="            <span class='fw-bold lh-1 small text-start'>"+this.$root.getPokerByNum(info.poker.xp[i])["name"]+"</span>";
                        str+="            <div class='d-flex flex-grow-1 ps-sm-1 small-xs'>";
                        str+="                <i class='bi d-flex align-items-center justify-content-center flex-grow-1 "+this.$root.getPokerByNum(info.poker.xp[i])['shape']+"'></i>";
                        str+="            </div>";
                        str+="        </div>";
                        str+="    </div>";
                    }
                    str+="    </div>";
                    str+="</div>";
                }
                str+="<div>"+info.result+"</div>";
                str+="</div>";
            }else if(this.submitLogGameType==GAME_ID.cor){
                //处理数据
                let numArr=[this.$t("common.one"),this.$t("common.two"),this.$t("common.three"),this.$t("common.four"),this.$t("common.five"),this.$t("common.six"),this.$t("common.seven"),this.$t("common.eight"),this.$t("common.nine")];
                let arr=text.split(',');
                for (let i = 0; i < arr.length; i++) {
                    arr[i]=arr[i].replace("1:","").replace("2:","").replace("3:","").replace("4:","");
                    if(arr[i]==10){
                        arr[i]=this.$t("room.resultGreat");
                    }else if(arr[i]==20){
                        arr[i]=this.$t("room.resultSilver");
                    }else if(arr[i]==30){
                        arr[i]=this.$t("room.resultGold");
                    }else if(arr[i]==40){
                        arr[i]=this.$t("room.resultBomb");
                    }else if(arr[i]==0){
                        arr[i]=this.$t("room.resultNothing");
                    }else{
                        arr[i]=this.$t("app.great")+numArr[arr[i]-1];
                    }
                }
                //拼接html
                str+="<div class='d-flex border-sm border-secondary rounded text-center'>";
                str+="    <div class='d-flex flex-sm-column col-sm flex-fill'>";
                str+="        <span class='fw-bold border-bottom-sm border-secondary' style='color:"+COLOR.red+"'>"+this.$t('common.z')+"</span>";
                str+="        <span class='flex-grow-1 d-flex flex-column justify-content-center border-top-sm border-end-sm border-secondary'>"+arr[0]+"</span>";
                str+="    </div>";
                str+="    <div class='d-flex flex-sm-column col-sm flex-fill'>";
                str+="        <span class='fw-bold border-bottom-sm border-secondary text-info'>"+this.$t('common.x')+"</span>";
                str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[1]+"</span>";
                str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[2]+"</span>";
                str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[3]+"</span>";
                str+="    </div>";
                str+="</div>";
            }else if(this.submitLogGameType==GAME_ID.slot){
                if(info.boot==0&&info.innings==0){//招财进宝
                    let arr=JSON.parse(info.result.replace(/[^\x20-\x7E\u4E00-\u9FFF]/g,''));
                    let imgList=[
                        ['god_13','god_1','god_2','god_3','god_14','god_4','god_5','god_6','god_15','god_7','god_8','god_9','god_16','god_10','god_11','god_12','god_17'],
                        ['god_1','god_3','god_4','god_6','god_7','god_9','god_10','god_12','god_13','god_2','god_14','god_5','god_15','god_8','god_16','god_11','god_17'],
                        ['god_2','god_4','god_15','god_9','god_11','god_13','god_3','god_5','god_7','god_16','god_12','god_1','god_14','god_6','god_8','god_10','god_17'],
                        ['god_3','god_6','god_9','god_12','god_2','god_5','god_8','god_11','god_1','god_4','god_7','god_10','god_13','god_14','god_15','god_16','god_17'],
                        ['god_14','god_7','god_11','god_2','god_6','god_16','god_13','god_4','god_8','god_12','god_3','god_15','god_10','god_1','god_5','god_9','god_17'],
                    ]
                    str+="<div class='d-flex justify-content-center'>";
                    for (let i = 0; i < arr.length; i++) {
                        str+="<div class='d-flex flex-column align-items-center'>";
                        for (let j = arr[i].length-1; j >=0 ; j--) {
                            str+="<img class='h-1rem' src='"+require('@/assets/images/room/slot/'+imgList[i][arr[i][j]-1]+'.png')+"' >";
                        }
                        str+="</div>";
                    }
                    str+="    ";
                    str+="</div>";
                }else if(info.boot==1&&info.innings==1){//水果机
                    let luckySetting=[
                        {code:"orange"},
                        {code:"bell"},//铃铛
                        {code:"bar",rate:25},
                        {code:"bar"},
                        {code:"apple"},
                        {code:"apple",rate:2},
                        {code:"melon"},
                        {code:"watermelon"},
                        {code:"watermelon",rate:10},
                        {code:"lucky"},
                        {code:"apple"},
                        {code:"orange",rate:5},
                        {code:"orange"},
                        {code:"bell"},
                        {code:"seven",rate:20},
                        {code:"seven"},
                        {code:"apple"},
                        {code:"melon",rate:5},
                        {code:"melon"},
                        {code:"star"},
                        {code:"star",rate:15},
                        {code:"lucky"},
                        {code:"apple"},
                        {code:"bell",rate:7},
                    ]
                    str+="<img class='h-2rem' src='"+require("@/assets/images/room/slot/"+luckySetting[info.result-1].code+".png")+"' />";
                    luckySetting[info.result-1].rate&&(str+="<div class='small'>×"+luckySetting[info.result-1].rate+"</div>");
                }
            }else if(this.submitLogGameType==GAME_ID.texasauto){
                str+="<span>"+info.detail.player.find(v=>v.userid==this.userInfo.id).sumbet+"</span>";
            }
            return str;
        },
        /**
         * 获取记录查询的资金方式
         */
        getLogType(tabId,id){
            if(tabId=="recharge"){//充值
                if(id==FUNDS_RECHARGE_TYPE.alipay){
                    return this.$t("personal.alipay");
                }else if(id==FUNDS_RECHARGE_TYPE.bank){
                    return this.$t("personal.unionPay");
                }else if(id==FUNDS_RECHARGE_TYPE.wechat){
                    return this.$t("personal.wechat");
                }else if(id==FUNDS_RECHARGE_TYPE.virtual){
                    return this.$t("personal.virtualCurrency");
                }
            }else if(tabId=="withdraw"){//提现
                if(id==FUNDS_WITHDRAW_TYPE.alipay){
                    return this.$t("personal.alipay");
                }else if(id==FUNDS_WITHDRAW_TYPE.bank){
                    return this.$t("personal.unionPay");
                }else if(id==FUNDS_WITHDRAW_TYPE.wechat){
                    return this.$t("personal.wechat");
                }else if(id==FUNDS_WITHDRAW_TYPE.virtual){
                    return this.$t("personal.virtualCurrency");
                }
            }
        },
        /**
         * 获取记录的状态
         */
        getLogState(id){
            if(id==LOG_STATE.wait){
                return this.$t("personal.stateWait");
            }else if(id==LOG_STATE.doing){
                return this.$t("personal.stateDoing");
            }else if(id==LOG_STATE.ready){
                return this.$t("personal.stateReady");
            }else if(id==LOG_STATE.fail){
                return this.$t("personal.stateFail");
            }
        },
        /**
         * 复制媒体聊天地址
         */
        copy(text){
            copy(text).then((result)=>{
                if(result=="success"){
                    useLayer({str:this.$t("personal.copied")});
                }else{
                    useLayer({str:this.$t("personal.copyErr")});
                }
            });
        },
        /**
         * 改变音效开关
         */
        changeAudioSwitch(type,isOpen){
            this.$store.commit({
                type:MUTATION_TYPE.setAudioSwitch,
                audioSwitch:{
                    [type]:isOpen
                }
            })
        },
        /**
         * 显示详情
         */
        showMore(info){
            this.currShowMore=info.detail;
        }
    },
    watch:{
        //监听充值支付方式列表改变
        rechargeFundsList(val){
            (val&&val.length>0)&&(this.payment=val[0].id);//设置默认选中第一项
        },
        //监听改变选中的支付方式
        payment(newVal,oldVal){
            newVal!=oldVal&&this.$root.wsSend(COMMAND.all_getcoin,newVal,this.userInfo.id,this.userInfo.guid);
        },
        //监听币种列表改变
        rechargeCurrencyList(val){
            (val&&val.length>0)&&(this.rechargeCurrency=val[0].id);
        },
        //监听提现方式列表改变
        withdrawFundsList(val){
            (val&&val.length>0)&&(this.withdrawal=val[0].id);//设置默认选中第一项
        },
        //监听收款方列表改变
        acceptedList(val){
            (val&&val.length>0)&&(this.accepted=val[0].id);
        },
        //监听问题反馈列表改变
        problemList(){
            this.txtProblemTitle="";
            this.txtProblemContent="";
        },
        //监听资金渠道列表改变
        fundChannel:{
            handler(){
                this.addAlipayName="";
                this.addAlipayAccount="";
                this.isShowAddAlipay=false;
                this.addBankName="";
                this.addBankAccount="";
                this.addBankBank="";
                this.isShowAddBank=false;
                this.addVirtualCurrency="";
                this.addVirtualCurrencyName="";
                this.isShowAddCoin=false;
            },
            deep:true
        },
    }
}
</script>