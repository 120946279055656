<template>
    <div class="d-flex flex-column align-items-center">
        <div class="spinner-border text-primary" :class="spinnerClass" role="status"></div>
        <div v-if="text&&text!=''" class="text-white mt-2">{{ text }}</div>
    </div>
</template>
<script>
export default {
    props: {
        text:String,//加载显示文本
        spinnerClass:String,//旋转器的样式
    },
    data() {
        return {
            isClient:true,//是否客户登录模式
            isShowLoad:false,//是否显示加载
        };
    }
}
</script>